import type { CategoryDatum } from '@charts';
import {
    clearAllFacets,
    FacetSelections,
    handleMount,
    RangeSelections,
    replaceState
} from '@features/faceted-search/selectionStateReducer';
import { handleToggle, makeArray, updateArraySelections } from '@helpers/arrayUtils';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch } from '@store';
import type { CommonField, CommonFilter, FaersFilter } from '../../API';
import type { SearchResultsLocationState } from '../../pages/useLocationState';
import { tableStateForRow } from '../Chart/popupUtil';
import { BubbleDisplay, ChartType, ChartView, NumberType } from '../Chart/util';
import type { AutocompleteOption } from '../Search/autocompleteUtil';
import type { MultiSearchState } from '../Search/multiUtil';
import { FoundIn } from './sectionUtil';

export type IngredientType = 'ingredientActive' | 'ingredientInactive';

export type RecordType = 'faers' | 'clinicalTrial';

export type MultiMode = 'drug' | 'ingredient';

export interface SelectedDrug {
    brandName: string;
    drugIndex: number;
    formulationIndex: number;
    invalid?: boolean;
}

export interface OpenedTable {
    type: RecordType;
    filters: FaersFilter[];
    overrideRecord?: CommonFilter[];
    expectedCount: number;
    breadcrumbs: string[];
}

export interface BarChartState {
    chartType: ChartType;
    rawMeddraPath: string[];
    viewByIngredients: boolean;
    numberType: NumberType;
    bubbleDisplay: BubbleDisplay;
    currentView: ChartView;
}

export interface IngredientSuggestion {
    name: string;
    isActive: boolean;
    fdaUniiCode?: string;
}

export interface PharmaSearchState {
    entered: string;
    hidden?: string;
    multi?: MultiSearchState;
    ingredient?: IngredientSuggestion;
    multiIngredient?: IngredientSuggestion[];
    exactBrand?: string;
    datasetId?: string;
}

export interface PharmaDemoState {
    selectedDrug: SelectedDrug | null;
    checkboxes: {
        isDrugOnly: boolean;
        // Map id to label.
        ingredientActive: Record<string, string>;
        ingredientInactive: Record<string, string>;
    }
    multi: {
        isDrugOnly: boolean;
        drugBrand: string[];
        ingredientActive: string[];
        didSelectInitial: boolean;
    }
    overlay: RecordType | null;
    drugListOpen: boolean;
    search: PharmaSearchState;
    table: OpenedTable | null;
    barChart: BarChartState;
    // Makes more sense to store here vs in facet reducer due to non-empty initial value.
    trialFoundIn: FoundIn;
    timelineOpen: boolean;
    // This is a bit of a hack. Don't want to clear the facets while the new search is still loading.
    ignoreFilters: boolean;
    // Also hacky.  need this to be based on the results rather than the request.
    layout: 'single' | 'multi';
}

// In the future, will figure out a more minimal state.
export type PassedToTables = PharmaDemoState;

export interface TableLocationState {
    ranges: RangeSelections;
    facets: FacetSelections;
    pharma: PassedToTables;
}

// Should have ONE of these properties.
export interface CurrentSelectionCore {
    singleDrug?: string;
    multiDrugs?: string[];
    singleIngredient?: IngredientSuggestion;
    multiActives?: IngredientSuggestion[];
    singleDrugIngredients?: {
        // TODO
    }
}

const initialState: PharmaDemoState = {
    selectedDrug: null,
    search: {
        entered: ''
    },
    checkboxes: {
        isDrugOnly: true,
        ingredientActive: {},
        ingredientInactive: {}
    },
    multi: {
        isDrugOnly: true,
        drugBrand: [],
        ingredientActive: [],
        didSelectInitial: false
    },
    overlay: null,
    table: null,
    drugListOpen: false,
    ignoreFilters: false,
    barChart: {
        chartType: ChartType.Faers,
        viewByIngredients: true,
        rawMeddraPath: [],
        numberType: 'count',
        bubbleDisplay: 'bubbles',
        currentView: ChartView.HorizontalBar
    },
    trialFoundIn: FoundIn.interventions,
    timelineOpen: false,
    layout: 'single'
};

export interface ToggleIngredientPayload {
    field: IngredientType;
    value: string;
    label: string;
    isSelected: boolean;
}

export interface CellTablePayload {
    row: CategoryDatum;
    column: {
        field: CommonField;
        value: string;
        label: string;
    }
}

const resetOnSearchSubmit = (state: Draft<PharmaDemoState>) => {
    state.table = null;
    state.overlay = null;
    if (state.selectedDrug) {
        state.selectedDrug.invalid = true;
    }
    state.ignoreFilters = true;
}

// Use the top-level search properties as overrides to the analytics property.
// TODO: what if there is no .analytics?
const applyLocationState = (state: SearchResultsLocationState) => {
    const { analytics, multi, brands, actives, query } = state;

}

const pharmaDemoSlice = createSlice({
    name: 'pharma',
    initialState,
    reducers: {
        submitSearch: (state, action: PayloadAction<string>) => {
            state.search = {
                entered: action.payload
            };
            resetOnSearchSubmit(state);
        },
        submitSearchSuggestion: (state, action: PayloadAction<AutocompleteOption>) => {
            const { type, label } = action.payload;
            state.search.entered = label.toLowerCase();
            if (type === 'drugs') {
                state.search.exactBrand = label;
                // TODO: selectedDrug?
            } else {
                state.search.ingredient = {
                    name: label,
                    isActive: type === 'activeIngredients'
                };
            }
            resetOnSearchSubmit(state);
        },
        submitMultiSearch: (state, action: PayloadAction<{ multi: MultiSearchState; visible?: string; }>) => {
            state.search = {
                entered: action.payload.visible || '',
                multi: action.payload.multi
            };
            resetOnSearchSubmit(state);
        },
        submitMultiIngredient: (state, action: PayloadAction<IngredientSuggestion[]>) => {
            state.search = {
                entered: '',
                multiIngredient: action.payload
            }
            resetOnSearchSubmit(state);
        },
        convertToIngredient: (state, action) => {
            // TODO
        },
        changeDrugBrand: (state, action: PayloadAction<SelectedDrug | null>) => {
            state.selectedDrug = action.payload;
            state.multi = initialState.multi;
            state.checkboxes = initialState.checkboxes;
        },
        changeDrugFormulation: (state, action: PayloadAction<number>) => {
            state.selectedDrug.formulationIndex = action.payload;
            // Note: keep drug/ingredient mode but clear checks.
            state.checkboxes.ingredientActive = {};
            state.checkboxes.ingredientInactive = {};
        },
        // Can pass an explicit true/false, or can switch to the opposite of the current.
        toggleDrugOnly: (state, action: PayloadAction<boolean | undefined>) => {
            const nextState = action.payload ?? !state.checkboxes.isDrugOnly;
            // Switch percent mode on and off when changed
            if (nextState !== state.checkboxes.isDrugOnly) {
                state.barChart.numberType = nextState ? 'count' : 'percent';
            }
            state.checkboxes.isDrugOnly = nextState;
        },
        toggleIngredientCheckbox: (state, action: PayloadAction<ToggleIngredientPayload>) => {
            state.checkboxes.isDrugOnly = false;
            const { field, value, label, isSelected } = action.payload;
            if (isSelected) {
                state.checkboxes[field][value] = label;
            } else {
                delete state.checkboxes[field][value];
            }
        },
        openOverlay: (state, action: PayloadAction<RecordType>) => {
            state.overlay = action.payload;
        },
        toggleOverlay: (state, action: PayloadAction<RecordType>) => {
            if (state.overlay === action.payload) {
                state.overlay = null;
            } else {
                state.overlay = action.payload;
            }
        },
        closeOverlay: (state) => {
            state.overlay = null;
            state.table = null;
        },
        openTable: (state, action: PayloadAction<OpenedTable>) => {
            state.table = action.payload;
        },
        openTableForRow: (state, action: PayloadAction<CategoryDatum>) => {
            state.table = tableStateForRow(
                action.payload,
                state.barChart.rawMeddraPath,
                state.barChart.chartType
            );
        },
        openTableForCell: (state, action: PayloadAction<CellTablePayload>) => {
            const { row, column } = action.payload;
            state.table = tableStateForRow(
                row,
                state.barChart.rawMeddraPath,
                state.barChart.chartType
            );
            state.table.breadcrumbs.unshift(column.label);
            // Column is typically drug/ingredient, but is sometimes outcome
            if (state.barChart.chartType === ChartType.OutcomesByMeddra) {
                state.table.filters.push({
                    field: column.field,
                    values: [column.value]
                });
            } else {
                state.table.overrideRecord = [{
                    field: column.field,
                    values: [column.value]
                }];
            }
        },
        closeTable: (state) => {
            state.table = null;
        },
        toggleTimeline: (state) => {
            state.timelineOpen = !state.timelineOpen;
        },
        toggleDrugList: (state) => {
            state.drugListOpen = !state.drugListOpen;
        },
        closeDrugList: (state) => {
            state.drugListOpen = false;
        },
        setBarChartType: (state, action: PayloadAction<ChartType>) => {
            state.barChart.chartType = action.payload;
            // automatically switch over to matrix
            if (action.payload === ChartType.OutcomesByMeddra) {
                state.barChart.currentView = ChartView.Matrix;
            }
            // must sometimes revert to bars
            else if (state.layout === 'single' && state.checkboxes.isDrugOnly) {
                state.barChart.currentView = ChartView.HorizontalBar;
            }

            // Note: keeps the applied meddra path.
        },
        setBarChartNumberType: (state, action: PayloadAction<NumberType>) => {
            state.barChart.numberType = action.payload;
        },
        setBubbleDisplay: (state, action: PayloadAction<BubbleDisplay>) => {
            state.barChart.bubbleDisplay = action.payload;
        },
        setCurrentChartView: (state, action) => {
            state.barChart.currentView = action.payload;
            // Switch over on impossible type
            if (state.barChart.chartType === ChartType.OutcomesByMeddra && action.payload === ChartView.HorizontalBar) {
                state.barChart.chartType = ChartType.Outcomes;
            }
        },
        toggleBarsByIngredient: (state) => {
            state.barChart.viewByIngredients = !state.barChart.viewByIngredients;
        },
        clickBreadcrumb: (state, action: PayloadAction<{ depth: number }>) => {
            const { depth } = action.payload;
            state.barChart.rawMeddraPath.splice(depth + 1);
        },
        /**
         * Pass the entire meddra path to prevent issues with stale data
         * when clicking rapidly.
         */
        clickMeddraBar: (state, action: PayloadAction<string[]>) => {
            state.barChart.rawMeddraPath = action.payload;
        },
        resetMeddra: (state) => {
            state.barChart.rawMeddraPath = [];
        },
        receiveResults: (state, action: PayloadAction<{ layout: 'single' | 'multi' }>) => {
            state.layout = action.payload.layout;
            // Reset meddra path
            state.barChart.rawMeddraPath = [];
            // Reset chart view if required, but keep chart type etc.
            if (action.payload.layout === 'single' && state.barChart.chartType !== ChartType.OutcomesByMeddra) {
                state.barChart.currentView = ChartView.HorizontalBar;
            }
        },
        // Sets the layout and clears everything else.
        openPage: (state, action: PayloadAction<{ layout: 'single' | 'multi' }>) => {
            return {
                ...initialState,
                layout: action.payload.layout
            }
        },
        openFromDataset: (state, action: PayloadAction<string>) => {
            return {
                ...initialState,
                layout: 'multi',
                search: {
                    entered: '',
                    datasetId: action.payload
                }
            }
        },
        // Overrides the entire state.  Applies the search properties to the analytics property.
        openFromExisting: (state, action: PayloadAction<SearchResultsLocationState>) => {
            const { analytics, multi, brands, actives, query } = action.payload;
            if (analytics) {
                Object.assign(state, analytics);
            }
            if (query) {
                state.layout = 'single';
                state.search = {
                    entered: query
                };
                // resetOnSearchSubmit???
            }
            if (multi) {
                state.search = {
                    multi: { ...multi, mode: multi.mode ?? 'us' },
                    entered: '' // access from previous??
                };
                // Note: Cannot set deep properties directly.
                state.multi = {
                    ...state.multi,
                    didSelectInitial: (brands?.length > 0 || actives?.length > 0),
                    ingredientActive: actives ?? [],
                    drugBrand: brands ?? []
                }
            }
        },
        initialMultiSelections: (state, action: PayloadAction<{ actives: string[]; brands: string[] }>) => {
            state.layout = 'multi';
            state.multi.isDrugOnly = true;
            state.multi.drugBrand = action.payload.brands;
            state.multi.ingredientActive = action.payload.actives;
            state.multi.didSelectInitial = true;
        },
        toggleMultiSelectDrug: (state, action: PayloadAction<string>) => {
            state.multi.drugBrand = handleToggle(action.payload)(state.multi.drugBrand);
        },
        toggleMultiSelectDrugs: (state, action: PayloadAction<{ isSelected: boolean; value: string | string[] }>) => {
            state.multi.drugBrand = updateArraySelections(
                state.multi.drugBrand,
                makeArray(action.payload.value),
                action.payload.isSelected
            );
        },
        toggleMultiSelectIngredient: (state, action: PayloadAction<string>) => {
            state.multi.ingredientActive = handleToggle(action.payload)(state.multi.ingredientActive);
        },
        toggleMultiSelectIngredients: (state, action: PayloadAction<{ isSelected: boolean; value: string | string[] }>) => {
            state.multi.ingredientActive = updateArraySelections(
                state.multi.ingredientActive,
                makeArray(action.payload.value),
                action.payload.isSelected
            );
        },
        changeMultiMode: (state, action: PayloadAction<MultiMode>) => {
            state.multi.isDrugOnly = action.payload === 'drug';
        },
        loadTableState: (state, action: PayloadAction<TableLocationState>) => {
            return action.payload.pharma;
        },
        setFoundIn: (state, action: PayloadAction<FoundIn>) => {
            state.trialFoundIn = action.payload;
        }
    }, extraReducers: builder => builder
        .addCase(clearAllFacets, (state) => {
            // Handles the case where this was called after receiving new API data.
            state.ignoreFilters = false;
            // Handles the 'clearFilters' button.
            state.checkboxes = initialState.checkboxes;
            // TODO: does it reset the formulation? what about chart state?
        })
});

// Wrap the core action creator
export const clearAllPharmaFacets = () => clearAllFacets({
    clearDate: true,
    clearRanges: true
});

// Set facets with the same action as setting pharma via a thunk (could also use a listener)
export const loadTableState = (action: PayloadAction<TableLocationState>) =>
    (dispatch: AppDispatch) => {
        dispatch(pharmaDemoSlice.actions.loadTableState(action.payload));
        dispatch(replaceState({
            ranges: action.payload.ranges,
            facets: action.payload.facets
        }));
    }

// Wrap action creator into a thunk to handle setting facets. (could also use a listener)
export const openFromExisting = (payload: SearchResultsLocationState) =>
    (dispatch: AppDispatch) => {
        dispatch(pharmaDemoSlice.actions.openFromExisting(payload));
        if (payload.facets) {
            dispatch(handleMount(payload.facets));
        }
    }

export const {
    submitSearch,
    submitSearchSuggestion,
    submitMultiSearch,
    submitMultiIngredient,
    changeDrugBrand,
    changeDrugFormulation,
    toggleDrugOnly,
    toggleIngredientCheckbox,
    toggleOverlay,
    closeOverlay,
    openTable,
    openTableForRow,
    openTableForCell,
    toggleTimeline,
    toggleDrugList,
    closeDrugList,
    setBarChartType,
    setBarChartNumberType,
    setBubbleDisplay,
    setCurrentChartView,
    toggleBarsByIngredient,
    clickBreadcrumb,
    clickMeddraBar,
    resetMeddra,
    receiveResults,
    toggleMultiSelectDrug,
    toggleMultiSelectDrugs,
    toggleMultiSelectIngredient,
    toggleMultiSelectIngredients,
    initialMultiSelections,
    changeMultiMode,
    openPage,
    openFromDataset,
    setFoundIn
} = pharmaDemoSlice.actions;

export default pharmaDemoSlice.reducer;
