import { PmiDatasetType } from '@api/enums';
import { sentryWarn } from '@features/errors';
import type { PmiDataset } from '@features/saved-items';
import { createIsValidEnum } from '@helpers/filterEnums';
import { explodeSearch } from '@helpers/searchUtil';
import type { KeyAndName } from '@helpers/types';
import type {
    AllSelections,
    BrandSearchMode,
    CategorySearchType,
    DatasetBasic,
    FamilySelection,
    InDbOnlyProperties,
    SelectedTerms,
    ViewingDetails
} from './types';
import { DatasetObjectSelections } from './types';

const emptyTerms = () => ({
    ae: [],
    family: [],
    recall: []
});

/**
 * Can use a family from a list response to create an empty selection.
 */
const toSelections = (isSelectAll: boolean) => ({ key, name }: KeyAndName): FamilySelection => ({
    key,
    name,
    selectedTerms: emptyTerms(),
    isSelectAll
})
export const toEmptySelections = toSelections(false);
export const toSelectAll = toSelections(true);

export const hasTerms = (terms: SelectedTerms): boolean =>
    Object.values(terms).some(array => array?.length > 0);

/**
 * Convert a family or query from the saved dataset into its selections.
 */
const createSelections = (key: string, name: string, terms: SelectedTerms): FamilySelection => ({
    key,
    name,
    selectedTerms: { ...emptyTerms(), ...terms },
    isSelectAll: !hasTerms(terms)
});

export const extractFamilies = (dataset: DatasetObjectSelections): FamilySelection[] => {
    if (dataset.familyBrands?.length) {
        return dataset.familyBrands
            .map(({ key, name, brands = [] }) =>
                createSelections(key, name, { family: brands }));
    }
    if (dataset.familyCompanies?.length) {
        return dataset.familyCompanies
            .map(({ key, name, companies = [] }) =>
                createSelections(key, name, { family: companies }));
    }
    return [];
}

export const extractQueries = (dataset: DatasetObjectSelections): FamilySelection[] => {
    if (dataset.brands?.length) {
        return dataset.brands
            .map(({ query, adverseEventBrands = [], recallBrands = [] }) =>
                createSelections(query.toUpperCase(), query.toUpperCase(), {
                    ae: adverseEventBrands,
                    recall: recallBrands
                }));
    }
    return [];
}

export const isPmiDatasetType = createIsValidEnum(PmiDatasetType);

export const hasPmiDatasetType = <T extends { datasetType?: string }>(dataset: T): dataset is T & { datasetType: PmiDatasetType } =>
    isPmiDatasetType(dataset?.datasetType);

export const prepareForSavePmi = (
    selections: AllSelections,
    datasetType: string,
    name: string = ''
): PmiDataset => {
    const { productCodes, gmdns, families, queries } = selections;
    if (datasetType && !isPmiDatasetType(datasetType)) {
        sentryWarn(`Invalid dataset type ${datasetType}`);
    }
    return ({
        name,
        datasetType: datasetType as PmiDatasetType,
        ...(datasetType === 'CATEGORIES' && ({
            productCodes,
            gmdns,
        })),
        ...(datasetType === 'COMPANIES' && ({
            familyCompanies: Object.values(families).map(({ key, name, selectedTerms, isSelectAll }) => ({
                key,
                name,
                companies: isSelectAll ? [] : selectedTerms.family
            }))
        })),
        ...(datasetType === 'BRANDS' && ({
            familyBrands: Object.values(families).map(({ key, name, selectedTerms, isSelectAll }) => ({
                key,
                name,
                brands: isSelectAll ? [] : selectedTerms.family
            })),
            brands: Object.values(queries).map(({ key, selectedTerms, isSelectAll }) => ({
                query: explodeSearch(key),
                ...(isSelectAll ? {} : {
                    adverseEventBrands: selectedTerms.ae,
                    recallBrands: selectedTerms.recall
                })
            }))
        }))
    });
}

/**
 * Convert a key and name from the viewing details into a complete dataset object.
 */
export const prepareForCountPmi = (
    details: ViewingDetails,
    datasetType: string,
    searchType: CategorySearchType,
    searchMode: BrandSearchMode
): PmiDataset | undefined => {
    if (!details) return;
    const { key, name } = details;
    switch (datasetType) {
        case 'BRANDS':
            return searchMode === 'queries'
                ? { datasetType, name: '', brands: [{ query: explodeSearch(key) }] }
                : { datasetType, name: '', familyBrands: [{ key, name }] };
        case 'COMPANIES':
            return { datasetType, name: '', familyCompanies: [{ key, name }] };
        case 'CATEGORIES':
            return { datasetType, name: '', [searchType]: [key] };
        default:
            return;
    }
}

/**
 * Drop `terms` array when selecting all.
 */
export const withoutTerms = ({ key, name }: { key: string; name: string }) => ({ key, name });

/**
 * Type guard to check if a dataset object has been previously saved and has an id.
 */
export const isSavedDataset = <T extends DatasetBasic>(dataset: T): dataset is T & InDbOnlyProperties & { _id: string } =>
    '_id' in dataset;
