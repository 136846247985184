import { IS_TRIAL_SITE } from '@constants';
import type { User } from '@features/iam';
import { getUserRole, getUserTrial, isFirstLogin } from '@helpers/userRoles';
import type { AuthTokens, LoggedInUser } from './types';

/**
 * Helper to drop all excess properties.
 */
export const pickTokens = (
    { bearerToken, bearerTokenRefresh, tokenExpiration, tokenRefreshExpiration }: AuthTokens
): AuthTokens => {
    return {
        bearerToken,
        bearerTokenRefresh,
        tokenExpiration,
        tokenRefreshExpiration
    }
}

/**
 * Fill in all of the properties which are derived from the user object.
 */
export const stateForUser = (user: User, tokens: AuthTokens): LoggedInUser => {
    const userRole = getUserRole(user);
    const trial = IS_TRIAL_SITE ? getUserTrial(user) : undefined;
    return {
        isFirstLogin: isFirstLogin(user),
        original: user,
        tokens: pickTokens(tokens),
        userRole,
        trial
    }
}
