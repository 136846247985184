import API from '@api';
import lineage from '@content/Ecosystem/lineageReducer';
import auth from '@features/auth/state/reducer';
import pmiDashboard from '@features/dashboard/data/dashboardReducer';
import aeEventResults from '@features/faceted-search/eventResultsReducer';
import aeSearchSelections from '@features/faceted-search/selectionStateReducer';
import alert from '@features/feedback/reducer';
import multiSearch from '@features/matrix/state/multiSearchReducer';
import meterData from '@features/meter/meterSlice';
import insiderDashboard from '../MedTechInsider/Dashboard/data/insiderDashReducer';
import pharma from '../Pharma/Demo/data/pharmaDemoReducer';
import labelSearch from '../Pharma/LabelSearch/data/labelSearchReducer';
import editDataset from '../Postmarket/data/editDatasetReducer';

// Define reducers outside of configureStore in order to get the type for the preloaded state.
const reducers = {
    auth,
    alert,
    lineage,
    aeSearchSelections,
    aeEventResults,
    editDataset,
    pmiDashboard,
    insiderDashboard,
    multiSearch,
    meterData,
    pharma,
    labelSearch,
    [API.reducerPath]: API.reducer,
} as const;

export default reducers;
