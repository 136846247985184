import type { UserRoleId } from '@constants';
import type { User } from '@features/iam';
import { useSelector } from '@store';
import type { AuthState } from './state/reducer';
import type { TrialState } from './state/types';

interface RootState {
    auth: AuthState;
}

/**
 * Note: user is optional.
 * Will need to assert that the user always exists when called inside blocked content.
 */
export const selectUserState = (state: RootState) => state.auth.user;
const selectUser = (state: RootState): User | undefined => state.auth.user?.original;

export const useCurrentUser = (): User | undefined => useSelector(selectUser);

export const useCurrentUserId = (): string | undefined =>
    useSelector(state => state.auth.user?.original?.id);

export const useCurrentUserRole = (): UserRoleId => useSelector(state => state.auth.user?.userRole);

const selectIsLoggedIn = (state: RootState): boolean => state.auth.loggedIn;

export const useIsLoggedIn = (): boolean => useSelector(selectIsLoggedIn);

export const useTrialEndTime = (): number | undefined =>
    useSelector(state => state.auth.user?.trial?.endTime);

export const useIsExpiredTrial = (): boolean =>
    useSelector(state => state.auth.user?.trial?.isExpired ?? false);

export const useTrialState = (): TrialState => useSelector(state => state.auth.user?.trial);

export const selectIsFirstLogin = (state: RootState): boolean => state.auth.user?.isFirstLogin ?? false;

export const selectJwt = (state: RootState): string | undefined => state.auth.user?.tokens.bearerToken;
