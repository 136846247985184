import { sentryInit } from '@features/errors';
import { LicenseInfo } from '@mui/x-license-pro';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import App from './App';

// Set up Sentry error reporting.
sentryInit();

// Set MUI license key for DataGrid.
LicenseInfo.setLicenseKey(
    '42b91cbdb5252ed157b10626add9362fT1JERVI6NDMwNDgsRVhQSVJZPTE2ODMyMzU2NTYwMDAsS0VZVkVSU0lPTj0x',
);

// Launch App.
ReactDOM.render(
    <React.StrictMode><App/></React.StrictMode>,
    document.getElementById('main')
);
