import { useSelector } from '@store';
import { useCallback, useMemo } from 'react';
import { Capability, DenialReason, DeniedReason, ReasonLoggedOut } from './capabilities';
import { selectCapabilities } from './selectors';

export const useBlockedReasonCb = () => {
    const dict = useSelector(selectCapabilities);

    return useCallback(
        <T extends Capability>(capability: T): DenialReason<T> | ReasonLoggedOut | false => {
            if (!dict) {
                return { type: DeniedReason.LOGGED_OUT }
            }
            return dict[capability] as DenialReason<T>;
        },
        [dict]
    );
}

export const useBlockedReason = <T extends Capability>(capability: T): DenialReason<T> | ReasonLoggedOut | false => {
    const getReason = useBlockedReasonCb();
    return useMemo(() => getReason(capability), [capability, getReason]);
}
