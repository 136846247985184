import { nanoid } from '@reduxjs/toolkit';
import React, { FC, useCallback, useState } from 'react';
import { ApiExportData, ExportProcessContext } from './ExportContext';
import Exporter from './Exporter';

/**
 * Provides the ExportContext with its trigger function to begin the download process.
 */
export const ExportProcessProvider: FC = ({ children }) => {

    // Array of all requested files.
    const [exportDataArray, setExportDataArray] = useState<(ApiExportData & { id: string })[]>([]);

    // Store the current file export info, after it is confirmed.
    const setExportData = useCallback((data: ApiExportData) => {
        setExportDataArray(
            (prevArray) => prevArray.concat({
                ...data,
                id: nanoid()
            })
        );
    }, [setExportDataArray]);

    return (
        <ExportProcessContext.Provider value={setExportData}>
            {children}
            {exportDataArray.map((exportData) => (
                <Exporter key={exportData.id} {...exportData} />
            ))}
        </ExportProcessContext.Provider>
    )
}
