import { BasilModule } from '@constants';
import { Authenticated, AuthenticatedElement } from '@features/access';
import ErrorBoundary from '@features/errors/ErrorBoundary';
import { ExportConfigProvider, ExportProcessProvider } from '@features/export';
import Fingerprint from '@features/fingerprint';
import { GAInitialize } from '@features/ga4';
import { ModalProvider } from '@features/modal';
import { LoadPreferences } from '@features/preferences';
import VersionCheck from '@features/version-check';
import { styled, ThemeProvider } from '@mui/material';
import { theme } from '@theme/appThemeV5';
import { LoadingPage } from '@ui';
import 'bootstrap/scss/bootstrap.scss';
import React, { FC, lazy, Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import accountRoutes from './Accounts';
import CurrentModuleContext from './Module/CurrentModuleContext';
import initializeStore from './store';
import './Theme/BootstrapOverrides.scss';
import TrialExpiredChecker from './Trial/TrialExpiredChecker';

const ProfilePage = lazy(() => import('./Profile/ProfilePage'));
const AdminPage = lazy(() => import('./Admin/AdminPage'));
const RenderAlert = lazy(() => import('./features/feedback/RenderAlert'));
const PostmarketApp = lazy(() => import('./Postmarket'));
const RegulatoryApp = lazy(() => import('./Regulatory'));
const ExecutiveApp = lazy(() => import('./MedTechInsider'));
const PharmaApp = lazy(() => import('./Pharma'));
const Reports = lazy(() => import('./pages/Reports'));
const NotFound = lazy(() => import('./Misc/Errors/NotFound'));
const StartPage = lazy(() => import('./Module/StartPage'));

// Style for the outer page div
const RootDiv = styled('div')({
    backgroundColor: '#ffffff',
    fontFamily: 'Noto Sans',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
})

const store = initializeStore();

// Group together all global provider components.
// Note: could take arguments if we ever want a custom provider for testing.
export const AppProviders: FC = ({ children }) => (
    <Suspense fallback={<LoadingPage/>}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <HelmetProvider>
                        <ModalProvider>
                            <ExportProcessProvider>
                                <ExportConfigProvider>
                                    <GAInitialize/>
                                    {children}
                                </ExportConfigProvider>
                            </ExportProcessProvider>
                        </ModalProvider>
                    </HelmetProvider>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </Suspense>
)

const MetaTags = () => (
    <Helmet>
        <link rel="icon" sizes="16x16"
              href={require('./images/basil-logo-favicon-new.svg')}/>
        <link rel="icon" sizes="152x152"
              href={require('./images/basil-logo-favicon-ipad-touch-icon-new.svg')}/>
        <link rel="touch-icon" sizes="180x180"
              href={require('./images/basil-logo-favicon-iphone-retina-new.svg')}/>

        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="description"
              content="Basil Systems: Regulation made simple. Find the global regulatory insight that you have been looking for..."/>
    </Helmet>
)

const AppRouting = () => {
    return useRoutes([
        {
            index: true,
            element: (
                <Authenticated capability="logged-in">
                    <StartPage/>
                </Authenticated>
            )
        },
        ...accountRoutes,
        {
            path: 'profile/*',
            element: (
                <AuthenticatedElement
                    component={ProfilePage}
                    capability="logged-in"
                    ga4Title="Profile"
                />
            )
        }, {
            path: 'admin',
            element: (
                <AuthenticatedElement
                    component={AdminPage}
                    capability="edit-users"
                    ga4Title="Admin"
                />
            )
        }, {
            path: '/postmarket/*',
            element: (
                <Authenticated capability="view-postmarket">
                    <CurrentModuleContext.Provider value={BasilModule.Postmarket}>
                        <PostmarketApp/>
                    </CurrentModuleContext.Provider>
                </Authenticated>
            )
        }, {
            path: '/details/*',
            element: (
                <Authenticated capability="view-reports">
                    <Reports/>
                </Authenticated>
            )
        },
        /* Render the header and footer and use nested routes to control the content */
        {
            path: '/medical-device/*',
            // Note: authentication is handled on a per-page level.
            element: (
                <CurrentModuleContext.Provider value={BasilModule.Regulatory}>
                    <RegulatoryApp/>
                </CurrentModuleContext.Provider>
            )
        }, {
            path: '/executive/*',
            element: (
                <CurrentModuleContext.Provider value={BasilModule.Executive}>
                    <ExecutiveApp/>
                </CurrentModuleContext.Provider>
            )
        },
        {
            path: '/pharma/*',
            element: (
                <CurrentModuleContext.Provider value={BasilModule.PharmaCovigilance}>
                    <Authenticated capability="logged-in">
                        <PharmaApp/>
                    </Authenticated>
                </CurrentModuleContext.Provider>
            )
        },
        {
            path: '*',
            element: <NotFound/>
        }
    ])
}

export default function App() {
    return (
        <AppProviders>
            <MetaTags/>
            <RootDiv>
                <ErrorBoundary>
                    <AppRouting/>
                    <VersionCheck/>
                    <Fingerprint/>
                    <TrialExpiredChecker/>
                    <LoadPreferences/>
                </ErrorBoundary>
                <RenderAlert/>
            </RootDiv>
        </AppProviders>
    )
}
