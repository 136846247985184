import type { ReviewPanelRegulatoryTotalCombined } from '@api';
import { ReviewPanelRegulatoryTrend } from '@api';
import { sentryWarn } from '@features/errors';
import { titleCase } from '@helpers/textUtils';
import { KeyOfType } from '@helpers/types';
import { camelCase, keyBy, mapValues } from 'lodash';
import type {
    ClinicalTrial,
    DeNovo,
    FiveTenK,
    Guidance,
    HDE,
    HDESupplement,
    PMA,
    PMASupplement,
    ProductCode,
    RegulationNumber
} from './deviceDataApi';
import { DocType, isSupplementType, SuppType } from './docType';

/**
 * Link the product type names to their API response shape.
 */
export interface ResponseTypeMap {
    [DocType.FiveTenK]: FiveTenK;
    [DocType.DeNovo]: DeNovo;
    [DocType.HDE]: HDE;
    [DocType.PMA]: PMA;
    [DocType.ProductCode]: ProductCode;
    [DocType.RegNum]: RegulationNumber;
    [DocType.Guidance]: Guidance;
    [DocType.ClinicalTrial]: ClinicalTrial;
    [SuppType.HDESupplement]: HDESupplement;
    [SuppType.PMASupplement]: PMASupplement;
}

/**
 * Work backwards from response type to doc type.
 */
export type DocTypeForDoc<T> = {
    [K in keyof ResponseTypeMap]: ResponseTypeMap[K] extends T ? T extends ResponseTypeMap[K] ? K : never : never;
}[keyof ResponseTypeMap]

/**
 * Can create a union of possible response object types.
 */
export type DeviceResponse<T extends keyof ResponseTypeMap = DocType> = ResponseTypeMap[T];

/**
 * Union for app types excludes proCode and regNum.
 */
export type AppType = DocType.HDE | DocType.PMA | DocType.FiveTenK | DocType.DeNovo

/**
 * Union of app type responses.
 */
export type AppResponse = DeviceResponse<AppType>;

/**
 * A details page is defined by its type (fivetenk, hde, etc.) and its unique id.
 */
export interface DeviceIdentifier {
    itemId: string;
    docType: DocType;
}

interface ConfigOptions<T extends DocType> {
    /**
     * Display name for this device application type.
     */
    name: string;
    /**
     * May want to add `s` to the end in certain situations.
     * Defaults to `name` if not set.
     */
    namePlural?: string;
    /**
     * Abbreviated label shown in chips.
     * Defaults to `name` if not set.
     */
    nameShort?: string;
    /**
     * The name used for page titles in Google Analytics tracking.
     * Defaults to `name` if not set.
     */
    gaName?: string;
    /**
     * The property from an item which represents the unique id.
     */
    idField: keyof DeviceResponse<T>; // Note: creates downstream problems: KeyOfType<DeviceResponse<T>, string>;
    /**
     * The parameter to set when filtering the 'recall-event/list' endpoint.
     * Defaults to the `idField` with 's' at the end if not set.
     */
    idsFilter?: string;
    /**
     * The slug used for creating the Basil URL in the form `/medical-device/${urlSlug}/${id}`.
     */
    urlSlug: string;
    /**
     * The slug used for creating the API URL in the form `device-data/v1/${apiSlug}/`.
     * Defaults to the `urlSlug` if not set.
     */
    apiSlug?: string;
    /**
     * The slug used for creating the Basil device search URL with query param
     * `&application_type=${searchAppSlug}`.
     * Defaults to the `urlSlug` if not set.
     */
    searchAppSlug?: string;
    /**
     * The slug used for creating the Basil adverse event URL with query param
     * `&app_type=${aeAppSlug}`.
     * Defaults to the `urlSlug` if not set.
     * Note: does not support regnum or procode, only ['den', '510k', 'hde', 'pma']
     */
    aeAppSlug?: string;
    /**
     * Type used by Gudid API for `submissionType` and facets.
     * Defaults to the `name` if not set.
     */
    docTypeGudid?: string;
    /**
     * Used for setting the `docType` argument on search and for accessing the search
     * result type by count. Likely in SCREAMING_SNAKE_CASE.
     */
    docTypeSearch: string;
    /**
     * Used for setting the `docType` argument on savedItems. Likely in snake_case.
     * If not set then this docType will not be savable.
     */
    docTypeSaved?: string;
    /**
     * Property name for getting trends from the dashboard responses.
     * Defaults to `camelCase(docTypeSearch)`.
     */
    apiProperty?: keyof ReviewPanelRegulatoryTrend;
    /**
     * Property name for getting counts from the dashboard responses.
     * Defaults to `${urlSlug}Count`.
     */
    countProperty?: keyof ReviewPanelRegulatoryTotalCombined;
    /**
     * Can try finding matches for multiple types based on a regex match string.
     */
    regex: RegExp;
    /**
     * Get the title from an individual item record.
     */
    getDeviceName: (item: DeviceResponse<T>) => string;
    /**
     * If this type supports supplements, define the associated SuppType enum.
     */
    supplementType?: SuppType | false;
    /**
     * If this type supports submission types, define the possible values.
     */
    submissionTypes?: string[];
    /**
     * Useful when making the name lowercase.
     */
    isAcronym?: boolean;
    /**
     * Doesn't have to be the absolute minimum.
     * Defaults to 1976 if not set.
     */
    defaultMinYear?: number;
    /**
     * For sending `_view` and `_click` events to the API.
     */
    trackingKey: string;
}

/**
 * The config type after all optional fields have been filled in.
 */
export type Config<T extends DocType = DocType> = Required<ConfigOptions<T>> & {
    isSupplement: boolean;
    /**
     * Save the key as a property for easy access.
     */
    docType: T;
    /**
     * Helper function to create URL path.
     */
    getUrl: (itemId: string) => string;
    /**
     * Helper function to extract id.
     */
    getId: (item: DeviceResponse<T>) => string;
    /**
     * Boolean.
     */
    hasSupplements: boolean;
    hasSubmissionType: boolean;
    // TODO: combine with existing namePlural
    nameDefinitelyPlural: string;
};

/**
 * Helper validates types and also fills in missing optional settings.
 */
const createConfigs = (configs: { [K in DocType]: ConfigOptions<K> }) =>
    mapValues(configs, (partial, key) => ({
        // Defaults derived from other values.
        docType: key,
        docTypeSaved: '',
        apiSlug: partial.urlSlug,
        searchAppSlug: partial.urlSlug,
        aeAppSlug: partial.urlSlug,
        docTypeGudid: partial.name,
        namePlural: partial.name,
        nameDefinitelyPlural: partial.namePlural || `${partial.name}s`,
        nameShort: partial.name,
        gaName: partial.name,
        idsFilter: `${partial.idField}s`,
        apiProperty: camelCase(partial.docTypeSearch),
        countProperty: `${partial.urlSlug}Count`,
        supplementType: false,
        hasSupplements: !!partial.supplementType,
        hasSubmissionType: !!partial.submissionTypes,
        isAcronym: false,
        defaultMinYear: 1976,
        isSupplement: false,
        // Explicitly passed options take priority.
        ...partial,
        getUrl: (itemId: string) => `/medical-device/${partial.urlSlug}/${itemId}`,
        getId: (item: DeviceResponse) => item[partial.idField],
    })) as { [K in DocType]: Config<K> };
// Note: needs type assertion to maintain the relationship between key and value.

/**
 * Returns the device name, which is the `deviceName` property on most docs,
 * but comes from the `tradeNames` on a CBER doc.
 *
 * Don't require any properties which are not used so that the function can also be used
 * on WebDeNovo, which has deviceName, etc.
 */
export function getTitle510k(item: { deviceName?: string; tradeNames?: string[] }, fallback = 'Unknown'): string {
    return item.deviceName || item.tradeNames?.[0] || fallback;
}

/**
 * Do not allow `undefined` names for any type.
 */
function required(value: string | undefined): string {
    return value || 'Unknown';
}

// TODO: preserve string literal values?
export const configs = createConfigs({
    [DocType.FiveTenK]: {
        name: '510(k)',
        gaName: '510k',
        idField: 'kNumber',
        countProperty: 'fivetenkCount',
        urlSlug: '510k',
        apiSlug: 'fivetenk',
        docTypeSearch: 'FIVETENK',
        docTypeSaved: '510k',
        docTypeGudid: '510(K)',
        regex: /(5|five)(10|ten)\(?k\)?/i,
        submissionTypes: ['Traditional', 'Special', 'Abbreviated'],
        getDeviceName: getTitle510k,
        defaultMinYear: 1976,
        trackingKey: '510k_denovo'
    },
    [DocType.DeNovo]: {
        name: 'De Novo',
        nameShort: 'DeNovo',
        idField: 'deNovoNumber',
        countProperty: 'denovoCount',
        urlSlug: 'den',
        apiSlug: 'de-novo',
        docTypeSearch: 'DE_NOVO',
        docTypeSaved: 'de_novo',
        docTypeGudid: 'de Novo',
        regex: /de([-_])?n(ovo)?/i,
        getDeviceName: item => required(item.deviceName),
        defaultMinYear: 2004,
        trackingKey: '510k_denovo'
    },
    [DocType.HDE]: {
        name: 'HDE',
        isAcronym: true,
        idField: 'hdeNumber',
        urlSlug: 'hde',
        docTypeSearch: 'HDE',
        docTypeSaved: 'hde',
        regex: /hde/i,
        supplementType: SuppType.HDESupplement,
        getDeviceName: item => required(item.tradeName),
        defaultMinYear: 1997,
        trackingKey: 'pma_hde'
    },
    [DocType.PMA]: {
        name: 'PMA',
        isAcronym: true,
        idField: 'pmaNumber',
        urlSlug: 'pma',
        docTypeSearch: 'PMA',
        docTypeSaved: 'pma',
        regex: /pma/i,
        supplementType: SuppType.PMASupplement,
        getDeviceName: item => required(item.device),
        defaultMinYear: 1976, // Note: some as old as 1960
        trackingKey: 'pma_hde'
    },
    [DocType.ProductCode]: {
        name: 'Product Code',
        nameShort: 'Procode',
        namePlural: 'Product Codes',
        gaName: 'Pro Code',
        idField: 'productCode',
        urlSlug: 'product-code',
        searchAppSlug: 'product_code',
        docTypeSearch: 'PRODUCT_CODE',
        docTypeSaved: 'product_code',
        regex: /pro(duct)?([-_])?code/i,
        getDeviceName: item => required(titleCase(item.device)),
        trackingKey: 'procode'
    },
    [DocType.RegNum]: {
        name: 'Regulation',
        namePlural: 'Regulations',
        idField: 'regulationNumber',
        urlSlug: 'regulation-number',
        apiSlug: 'regulation',
        searchAppSlug: 'regulation_number',
        docTypeSearch: 'REGULATION',
        regex: /reg(ulation)?(([-_])?num(ber)?)?/i,
        getDeviceName: item => required(item.name),
        trackingKey: 'regulation'
    },
    [DocType.Guidance]: {
        name: 'Guidance',
        idField: 'primaryIdHash',
        urlSlug: 'guidance',
        docTypeSearch: 'GUIDANCE',
        regex: /guidance/i,
        getDeviceName: item => required(item.title),
        trackingKey: 'guidance'
    },
    [DocType.ClinicalTrial]: {
        name: 'Clinical Trial',
        idField: 'nctId',
        urlSlug: 'clinical-trial',
        docTypeSearch: 'CLINICAL_TRIAL',
        regex: /clinical.?trial/i,
        getDeviceName: item => {
            const ident = item.study?.protocolSection?.identificationModule;
            const main = required(ident?.briefTitle || ident?.officialTitle || item.basil?.overview?.officialTitle);
            const acronym = ident?.acronym;
            // TODO: avoid double acronym if in title.
            return acronym ? `${main} (${acronym})` : main;
        },
        defaultMinYear: 2005,
        trackingKey: 'clinical_trial'
    }
})

type LookupField = KeyOfType<Config, string>;

/**
 * Saves known keys for better performance vs. `.find` every time.
 * Uses regex to find non-exact matches and matches without explicit field.
 */
export class LookupCache<C extends { regex: RegExp }> {
    private knownKeys: Record<string, C> = {};

    constructor(
        private readonly configs: C[],
        private readonly label: string
    ) {}

    // Separate the lookup from the saving of key.
    private tryFind(value: string, field?: string & KeyOfType<C, string>): C | undefined {
        // Check the expected field for an exact match.
        if (field) {
            const fieldMatch = this.configs.find(config => (config[field] as unknown as string) === value);
            if (fieldMatch) return fieldMatch;
        }
        // Check for a regex match.
        const regexMatch = this.configs.find(config => config.regex.test(value));
        // Warn when a field was provided, but it didn't match.
        if (regexMatch && field && !isSupplementType(value)) {
            sentryWarn(`Looked up ${this.label} by field ${field} with incorrect value ${value}. Expected value ${regexMatch[field]}.`);
        }
        return regexMatch;
        // const fallback = Object.values(configs).find(config => Object.values(config).includes(value));
    }

    public findBy(value: string, field?: string & KeyOfType<C, string>): C {
        // If the key has already been seen, return that.
        if (value in this.knownKeys) {
            return this.knownKeys[value];
        }
        // Find a match and save as a known key before returning.
        const found = this.tryFind(value, field);
        if (!found) {
            throw new Error(`Cannot find ${this.label} matching value ${value} for field ${field}.`);
        }
        this.knownKeys[value] = found;
        return found;
    }
}

/**
 * Create a singular LookupCache instance.
 */
const cacheInstance = new LookupCache(Object.values(configs), 'app type');

/**
 * Export a function which interacts with the LookupCache behind the scenes.
 */
export const lookupAppType = (value: string, field?: LookupField): Config<any> => {
    return cacheInstance.findBy(value, field);
}

/**
 * Compare two config objects based on the docType.
 */
export const isSameDocType = (a: Config<any>, b: Config<any>): boolean => a.docType === b.docType;

/**
 * Create an object with the same keys as DocType.
 * And built-in helper functions.
 */
export const DocConfig = mapValues(DocType, d => configs[d]) as {
    [K in keyof typeof DocType]: Config<(typeof DocType)[K]>;
} & {
    from(value: string, field?: LookupField): Config<any>;
    convert<K extends keyof Config>(value: string, to: K, from?: LookupField): Config<any>[K];
};
DocConfig.from = lookupAppType;
DocConfig.convert = (value, to, from) => lookupAppType(value, from)[to];

/**
 * Can create a keyed lookup dictionary of configs to avoid using `.find()`
 */
export const configsBy = (keyField: LookupField) => keyBy(configs, keyField);
export const configsByUrlSlug = configsBy('urlSlug');
export const configsBySearchSlug = configsBy('searchAppSlug');

/**
 * Instead of having to go through a `find` operation multiple times, can create a map object
 * to go from slugs of one type to another type.
 * Use a generic so that the return has the correct type.
 * Allow inputs to be string regardless of field.
 */
export function createMap<T extends LookupField>(fromField: LookupField, toField: T) {
    return Object.fromEntries(Object.values(configs).map(config => [config[fromField], config[toField]])) as Record<string, Config[T]>;
}
