import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IconType } from 'react-icons';

/**
 * Higher-order component allows any icon from react-icons to be used as an MUI icon.
 * Supports: sx prop, color/fontSize from theme, targeted styling with `svgIconClasses`.
 *
 * Note: cannot forwardRef because react-icons doesn't support refs.
 * https://github.com/react-icons/react-icons/pull/367
 */
export default function asMuiIcon(Component: IconType): FunctionComponent<SvgIconProps> {
    return (props: SvgIconProps): JSX.Element => (
        <SvgIcon
            {...props}
            inheritViewBox
            component={Component}
        />
    );
}
