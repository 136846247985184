import { Tags } from '@api/tags';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

// initialize an empty api service
export default createApi({
    baseQuery,
    keepUnusedDataFor: 0,
    endpoints: () => ({}),
    tagTypes: Object.values(Tags)
});
