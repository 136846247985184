import shadows, { Shadows } from '@mui/material/styles/shadows';

/**
 * MUI shadows #1 and #2 are too sharp, so soften them.
 */

const customShadows: Shadows = [...shadows];

customShadows[1] = 'rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px'

customShadows[2] = '0px 3px 8px -3px rgb(0 0 0 / 16%), 0px 1px 4px 0px rgb(0 0 0 / 12%), 0px 1px 5px 0px rgb(0 0 0 / 10%)'

export default customShadows;
