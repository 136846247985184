import { BasilView, useCombinedAnalytics } from '@features/analytics';
import React, { useEffect } from 'react';
import { RouteObject } from 'react-router-dom';

export interface PageViewProps {
    ga4Title: string;
    /**
     * Event string to send to Basil analytics.
     * Combine multiple events into a comma-separated string.
     */
    v2View?: string;
}

// Wrapper component for page content. Generates the page view event for analytics.
export default function PageView({ ga4Title, v2View, children }: PageViewProps & { children: JSX.Element }) {
    const location = window.location.href;

    const analytics = useCombinedAnalytics();

    useEffect(() => {
        analytics.pageView(ga4Title);
    }, [location, analytics, ga4Title]);

    if (v2View) {
        return (
            <BasilView event={v2View.split(',')}>
                {children}
            </BasilView>
        );
    }

    return children;
}

// Parents should not have titles, but children should.
export type TitledRouteObject = Omit<RouteObject, 'children'> & { element?: JSX.Element } &
    ({ children: TitledRouteObject[]; ga4Title?: never; v2View?: never } |
        { children?: never; ga4Title: string; v2View?: string })

export const mapTitledRoute = (route: TitledRouteObject): RouteObject => {
    if ( route.children ) {
        return {
            ...route,
            children: route.children.map(mapTitledRoute)
        }
    } else {
        const { element, ga4Title, v2View, ...rest } = route;
        return {
            ...rest,
            element: <PageView ga4Title={ga4Title} v2View={v2View}>{element}</PageView>
        }
    }
}
