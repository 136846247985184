import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { FetchArgs } from '@reduxjs/toolkit/query';
import { BaseSchema, ValidationError } from 'yup';
import type { ValidateOptions } from 'yup/lib/types';
import { extractMessage } from './errors';
import type { ApiError, BaseQuery, BaseQueryError, InvalidArgsError, Mutation } from './rtkTypes';

// TODO: could bake this into the baseQuery with a `prevalidate` property or something.

/**
 * Helper for rejecting a query due to invalid args.
 */
export const invalidArgs = (message: string, data?: Record<string, string[]>): { error: InvalidArgsError } => ({
  error: {
      status: 'INVALID_ARGUMENTS',
      error: message,
      data
  }
})

/**
 * TypeScript type guard function.
 */
export const isInvalidArgsError = (error: ApiError | undefined): error is InvalidArgsError =>
    (error as BaseQueryError)?.status === 'INVALID_ARGUMENTS';

/**
 * Creates a function to set on the `queryFn` property of an endpoint.
 * Apply a yup validation schema to the arguments of a query.
 * Only execute the query if valid.
 */
export const validateArgs = <Args, Data = unknown>(
    query: (args: Args) => string | FetchArgs,
    validationSchema: BaseSchema<Args, unknown, Args>,
    options: ValidateOptions<unknown> = { strict: true }
) => async (args: Args, api: BaseQueryApi, extraOptions: {}, fetchWithBQ: BaseQuery) => {
    let valid: Args;
    try {
        valid = await validationSchema.validate(args, options);
    } catch (e: unknown) {
        if (ValidationError.isError(e)) {
            const message = e.errors[0];
            // An array of individual errors, in case `abortEarly` is false.
            const array = e.inner?.length ? e.inner : [e];
            // Key errors by path.
            const data = Object.fromEntries(array.map(err =>
                [err.path ?? '', err.errors]
            ));
            return invalidArgs(message, data);
        }
        return invalidArgs(extractMessage(e, 'Invalid Arguments.'));
    }
    const fetchArgs = query(valid);
    const result = await fetchWithBQ(fetchArgs, api, extraOptions);
    return result as { data: Data } | { error: BaseQueryError };
}

/**
 * Quick check to see if there is an error at a given path.
 */
export const hasArgError = (e: unknown, path: string): boolean => {
    return Boolean((e as InvalidArgsError)?.data?.[path]);
}

/**
 * Wrapper which modifies an existing endpoint definition.
 * TODO: can combine with logic in returnEmptyIf.
 */
export const validateArgsMutationEnhancer = <Args, Data = unknown>(
    validationSchema: BaseSchema<Args, unknown, Args>,
    options: ValidateOptions<unknown> = { strict: true }
) => (definition: Mutation<Args, Data>): void => {
    const { query } = definition;
    delete definition.query;
    definition.queryFn = validateArgs(query, validationSchema, options);
}

/**
 * Modifies an existing endpoint definition.
 * Replaces the `query` with a `queryFn` that includes the `validateArgs` call.
 */
export const inferableValidateArgsMutationEnhancer = <Args, Data = unknown>(
    definition: Mutation<Args, Data>,
    validationSchema: BaseSchema<Args, unknown, Args>,
    options: ValidateOptions<unknown> = { strict: true }
): void => {
    const { query } = definition;
    delete definition.query;
    definition.queryFn = validateArgs(query, validationSchema, options);
}
