import type { AEOrderField, AuMaudeTableQuery, CaMaudeTableQuery, MaudeSearchQuery } from '@api';
import {
    AuAdverseEventField,
    CaAdverseEventField,
    MaudeField,
    MaudeFieldName,
    MaudeHistogram,
    MaudeWithIn
} from '@api/enums';
import type { CountryCode } from '@content/Country';
import type { PaginationArgs } from '../../../Misc/DataTable/infinite/types';
import genericPrepare, { QuerySelections } from './genericPrepare';

export const AE_PAGE_SIZE = 150;
// TODO: complain to Greg about receivedDate vs dateReceived
export const AE_DEFAULT_ORDER: AEOrderField = MaudeFieldName.receivedDate;

/**
 * For tables and other queries which use batch date, must use a different date field for the batch.
 */
export const prepareAEQuery = genericPrepare<MaudeSearchQuery>({
    filter: MaudeField,
    dateField: MaudeField.dateReceived,
    within: MaudeWithIn,
    ignoreBatch: false
});

/**
 * With histogram and default pagination args.
 * Do not apply the batch date to the histogram.
 */
export function createHistogramParams(selections: QuerySelections): MaudeSearchQuery {

    const params: MaudeSearchQuery = {
        ...genericPrepare<MaudeSearchQuery>({
            filter: MaudeField,
            dateField: MaudeField.dateReceived,
            within: MaudeWithIn,
            ignoreBatch: true
        })(selections),
        pageNumber: 1,
        pageSize: AE_PAGE_SIZE
    }

    if (selections.histogram) {
        const histParam = MaudeHistogram[selections.histogram];
        if (histParam) {
            params.histogram = histParam;
        }
    }

    return params;
}

export const prepareAuAeQuery = genericPrepare<AuMaudeTableQuery>({
    filter: AuAdverseEventField,
    dateField: AuAdverseEventField.dateReceived
});

export const prepareCaAeQuery = genericPrepare<CaMaudeTableQuery>({
    filter: CaAdverseEventField,
    dateField: CaAdverseEventField.dateReceived
});

const prepareMap = {
    us: prepareAEQuery,
    au: prepareAuAeQuery,
    ca: prepareCaAeQuery
}

export const prepareGlobalAeQuery = (selections: QuerySelections) =>
    prepareMap[selections.country || 'us'](selections);

/**
 * With pagination and sort order from arguments.
 */
export const createTableParams = (
    params: QuerySelections,
    { pageNumber = 1, pageSize = AE_PAGE_SIZE, orderBy = [] }: PaginationArgs,
    country: CountryCode = 'us'
) =>
    ({
        ...prepareMap[country](params),
        orderBy,
        pageNumber,
        pageSize
    })
