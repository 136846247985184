export type Either<A, B> = (A | B) & Partial<A & B>;

export type DeepEither<A, B> = (A | B) & {
    [K in (keyof A | keyof B)]?:
    K extends keyof A
        ? K extends keyof B ? DeepEither<A[K], B[K]> : A[K]
        : K extends keyof B ? B[K] : never;
}

export type KeyOfType<ObjectType, ValueType> = {
    [K in keyof ObjectType]?: ObjectType[K] extends ValueType ? K : never;
}[keyof ObjectType];

export type MinMax<T = number> = {
    min: T;
    max: T;
}

export type StartEnd<T = number> = {
    start: T;
    end: T;
}

export interface KeyAndName {
    key: string;
    name: string;
}

export type PossibleArray<T> = T | T[];

export type Unwrap<T> = T extends Array<infer U> ? U : T;

// Use ArrayEl<T> instead of T[number] when T might be undefined.
export type ArrayEl<T extends (any[] | undefined)> = NonNullable<T>[number];

export type Values<T> = T[keyof T];

export type Entries<T> = {
    [K in keyof T]: [K, T[K]]
}[keyof T]

export const typedKeys = <T extends object>(obj: T) =>
    Object.keys(obj) as Array<keyof T>;

export const typedValues = <T extends object>(obj: T) =>
    Object.values(obj) as Array<Values<T>>;

export const typedEntries = <T extends object>(obj: T) =>
    Object.entries(obj) as Array<Entries<T>>;

export type Nested<T> = T & { children: T[] }

/**
 * When assigning {...props} to an MUI component, must use Omit
 * if there are conflicts in the type definitions.
 */
export type WithDefaults<Defaults, Custom> = Custom & Omit<Defaults, keyof Custom>;
