import { createAlert, useMutationWithFeedback } from '@features/feedback';
import { useDispatch } from '@store';
import React, { useEffect, useRef, useState } from 'react';
import DownloadForm from './DownloadForm';
import { errorAlertProps, pendingAlertProps, successAlertProps } from './exportAlerts';
import { useCheckExportReadyQuery, useInitializeExportMutation } from './exportApi';
import { ApiExportData } from './ExportContext';

const CHECK_INTERVAL = 1000;

type ExporterProps = ApiExportData & {
    id: string;
}

/**
 * Handles the sequential execution of 3 API calls:
 * 1. Sends the export query params to the server and returns the server's file id.
 * 2. Polls repeatedly to see if the file is ready to be downloaded.
 * 3. Submits a form to force the file to download the prepared file from the server to the user's computer.
 */
export default function Exporter({ params, url, filename, id }: ExporterProps) {
    // The filename generated by the server, returned by endpoint #1.
    const [serverFileId, setServerFileId] = useState('');

    // Indicates that endpoint #3 should start and #2 should stop polling.
    const [isFileReady, setIsFileReady] = useState(false);

    // Indicates that #2 should stop polling due to an error.
    const [isAborted, setIsAborted] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const dispatch = useDispatch();

    // Endpoint #1: request the file.
    const [initialize] = useMutationWithFeedback({
        useHook: useInitializeExportMutation,
        // Show "Preparing" message when the first request starts.
        pendingProps: pendingAlertProps,
        errorProps: errorAlertProps,
        onSuccess: (_, { filename }) => {
            // Get the file name/id from the server.
            // Set it to begin polling the "ready" endpoint.
            setServerFileId(filename);
        },
        onError: () => {
            setIsAborted(true);
        }
    });

    // Start the request with the arguments from props.
    useEffect(() => {
        if (params && url) {
            initialize({ url, body: params, baseFileName: filename });
        }
    }, [params, url, filename]);

    // Endpoint #2: check if file is ready.
    const { data, isError } = useCheckExportReadyQuery(serverFileId, {
        // Begin when serverFileId gets set.
        // Stop when isFileReady gets set.
        skip: !serverFileId || isFileReady || isAborted,
        pollingInterval: CHECK_INTERVAL
    });

    // Handle errors from #2.
    useEffect(() => {
        if (isError) {
            dispatch(createAlert(errorAlertProps()));
            setIsAborted(true);
        }
    }, [isError, setIsAborted]);

    // Response from #2 triggers #3.
    useEffect(() => {
        if (data?.result) {
            setIsFileReady(true);
        }
    }, [data]);

    // Endpoint #3: send a form to the url.
    useEffect(() => {
        const form = formRef.current;
        if (form && serverFileId && isFileReady) {
            form.submit();
            dispatch(createAlert(successAlertProps()));
        }
    }, [isFileReady, serverFileId]);

    // Render the hidden form which will be submitted by #3.
    return (
        <DownloadForm
            uniqueId={id}
            filename={serverFileId}
            apiPath="/search/v2/common/export/get"
            ref={formRef}
            style={{ width: 0, height: 0 }}
        />
    );
}
