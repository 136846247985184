import api, { DateFacet, HistogramResponse, TotalResults } from '@api';
import type { AnyFieldKey } from '@content/Facet/formatLabels';
import type { ApiFacet } from '@content/Facet/types';
import type { GroupedFacets } from '@content/FacetGroup/FacetGroup';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { correctGroupNames } from './correctGroupNames';
import EventsAPI from './EventsAPI';
import type { AEResults } from './mapResults';
import { handleUnmount } from './selectionStateReducer';

// Note: want to refactor out most of this. Pmi usage is mainly for back compat.

// Includes the query that this count applies to, for the AEStats
export interface StoredTotal extends TotalResults {
    query: string | undefined;
}

// Facets which return min and max instead of buckets.
type DateMinMaxKey = 'devicePublishDate' | 'dateReceived';

export type ResponseFacetKey = Exclude<AnyFieldKey, DateMinMaxKey>;

export type ResponseFacets = {
    [key in ResponseFacetKey]?: ApiFacet[];
} & {
    [key in DateMinMaxKey]?: DateFacet;
}

export interface FacetsPayload {
    facets?: ResponseFacets;
    status: QueryStatus;
    requestId?: string; // TODO: figure out how to use this!
}

interface EventResultsState {
    count: {
        tableMounted?: boolean;
        total?: StoredTotal;
    }
    facets?: ResponseFacets;
    facetStatus: QueryStatus;
    isReloadingFacets: boolean; // TODO: refactor out
    manuGroups: {
        // a copy of the manufacturer groups as originally parsed (for entire pro code or app num)
        original?: GroupedFacets;
        current?: GroupedFacets;
    }
    chartData?: HistogramResponse;
}

const initialState: EventResultsState = {
    count: {
        tableMounted: false
    },
    facets: {},
    facetStatus: QueryStatus.uninitialized,
    manuGroups: {},
    isReloadingFacets: false
}

const slice = createSlice({
    name: 'eventResults',
    initialState,
    reducers: {
        // dispatch event rather than responding to the fulfilled action
        // to allow the same page to be loaded multiple times.
        receiveSearchPage: (state, action: PayloadAction<AEResults>) => {
            state.isReloadingFacets = false;
            const { data, manuGroups } = action.payload;
            // save monthly histogram data
            state.chartData = data;
            // modify groups is original groupings exist
            if (state.manuGroups.original) {
                state.manuGroups.current = correctGroupNames(manuGroups, state.manuGroups.original);
            }
            // or set this as the original grouping
            else {
                state.manuGroups.current = manuGroups;
                state.manuGroups.original = manuGroups;
            }
            state.facets = action.payload.data.facets;
            state.count.total = {
                ...action.payload.totalResults,
                query: action.payload.data.appliedSearch.query
            }
        },
        // Set the count from a table query, since this (probably) comes back first.
        receiveResultCount: (state, action: PayloadAction<StoredTotal>) => {
            state.count.total = action.payload;
        },
        tableMounted: (state, action: PayloadAction<boolean>) => {
            state.count.tableMounted = action.payload;
        },
        syncFacets: (state, action: PayloadAction<FacetsPayload>) => {
            const { facets, status } = action.payload;
            state.facets = facets; // TODO: don't clear old once I get a reloading UI.
            state.facetStatus = status;
            state.isReloadingFacets = status === QueryStatus.pending;
        },
        clearFacets: (state) => {
            state.facets = {};
            state.facetStatus = QueryStatus.uninitialized;
            state.isReloadingFacets = false;
        }
    },
    extraReducers: builder => builder
        // Get facets from either an events search or a PMI search.
        .addMatcher(
            isAnyOf(
                EventsAPI.endpoints.getEventsSearchResults.matchPending,
                api.endpoints.searchPmi.matchPending
            ),
            (state) => {
                state.isReloadingFacets = true;
            }
        )
        .addMatcher(
            isAnyOf(
                EventsAPI.endpoints.getEventsSearchResults.matchRejected,
                api.endpoints.searchPmi.matchRejected
            ),
            (state) => {
                state.isReloadingFacets = false;
            }
        )
        .addMatcher(
            isAnyOf(
                api.endpoints.searchPmi.matchFulfilled
            ),
            (state, action) => {
                state.isReloadingFacets = false;
                state.facets = action.payload.facets;
            }
        )
        .addMatcher(
            handleUnmount.match,
            // Note: clearing the table results causes the table to load after the charts on revisit.
            () => initialState
        )
});

export const { receiveSearchPage, receiveResultCount, syncFacets, clearFacets, tableMounted } = slice.actions;

export default slice.reducer;
