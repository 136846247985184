import type {
    AuAdverseEventField,
    CaAdverseEventField,
    ClinicalTrialField,
    DeviceField,
    FaersField,
    MaudeField,
    PmiField,
    ReviewPanelField
} from '@api/enums';
import { createMap, DocConfig } from '@features/device-data';
import { sentryWarn } from '@features/errors';
import { isPlaceholder } from '@helpers/apiUtils';
import { countryName } from '@helpers/countryUtil';
import type { DateKey } from '@helpers/filterEnums';
import {
    booleanFormatter,
    decodeHtmlEntities,
    joinOptional,
    sentenceCase,
    suppReasonCase,
    TextFormatter,
    titleCase
} from '@helpers/textUtils';
import { identity, mapValues, startCase, upperFirst } from 'lodash';
import type { ApiFacet } from './types';
import { getFacetCount, getFacetKey } from './util';

export const formatEventType = (raw: string | null | undefined, fallback = '-') => {
    if (raw === 'NO_ANSWER_PROVIDED') return 'No Answer';
    return titleCase(raw) || fallback;
}

export const formatMeddraTerm = (label: string) =>
    upperFirst(label?.toLowerCase())
        .replace(/ n(ec|os)$/, (match) => match.toUpperCase())

const applicationTypeMap = {
    ...createMap('docTypeSearch', 'name'),
    'HDE_SUPPLEMENT': 'HDE Supplement',
    'PMA_SUPPLEMENT': 'PMA Supplement',
    'NONE': 'None'
}

const getCount2 = (facet: ApiFacet) => facet.count2 || facet.count || 0;

// These fields are used behind the scenes, but rolled up into "seriousness".
export type SeriousBooleanField =
    'serious' |
    'seriousnessCongenitalAnomali' |
    'seriousnessDeath' |
    'seriousnessDisabling' |
    'seriousnessHospitalization' |
    'seriousnessLifeThreatening' |
    'seriousnessOther'

export type FaersFieldKey = Exclude<keyof typeof FaersField, SeriousBooleanField>

export type AnyFieldKey =
    keyof typeof ReviewPanelField |
    keyof typeof MaudeField |
    keyof typeof PmiField |
    keyof typeof DeviceField |
    keyof typeof ClinicalTrialField |
    keyof typeof AuAdverseEventField |
    keyof typeof CaAdverseEventField |
    FaersFieldKey |
    // TODO: different settings (overrides) for Au/Ca fields.
    // Note: right now just adding in fields necessary for matrix.
    'recallAction' | 'recallReason' | 'recallingFirm' |
    // allow setting the regulatory docType as if it were a facet.
    'docType' |
    // TODO: add the rest of pharma
    'drugCategory'

export type AnyFacetKey = Exclude<AnyFieldKey, DateKey>

interface FieldSetting {
    title: string;
    filterBoxTitle?: string;
    formatLabel?: TextFormatter;
    formatFacetLabel?: (key: string, facet: ApiFacet) => string;
    facetSearchableText?: (facet: ApiFacet) => string;
    chipPrefix?: string;
    chartType?: 'bar' | 'line';
    getFacetCount?: (facet: ApiFacet) => number;
}

const getFallbacks = (defined: FieldSetting): Required<FieldSetting> => ({
    title: defined.title,
    filterBoxTitle: defined.title,
    formatLabel: identity,
    formatFacetLabel: defined.formatLabel || identity,
    facetSearchableText: getFacetKey,
    chipPrefix: '',
    chartType: 'bar',
    getFacetCount: getFacetCount
});

const brandName: FieldSetting = {
    title: 'Brand Name',
    chartType: 'line'
};

const FIELD_SETTINGS: Record<AnyFacetKey, FieldSetting> = {
    eventType: {
        title: 'Event Type',
        filterBoxTitle: 'Manufacturer Event Type',
        formatLabel: (key) => formatEventType(key) // don't pass second arg
    },
    reportType: {
        title: 'Initial Report Type',
        formatLabel: titleCase
    },
    reportSourceCode: {
        title: 'Report Source',
        formatLabel: titleCase
    },
    reportedOutcome: {
        title: 'Reported Outcome',
        formatLabel: titleCase
    },
    reportedEventOutcome: {
        title: 'Reported Event Outcome',
        formatLabel: titleCase
    },
    deviceProblem: {
        title: 'Device Problem Category',
        formatLabel: titleCase
    },
    patientProblem: {
        title: 'Patient Problem Category',
        formatLabel: titleCase
    },
    singleUseLabeled: {
        title: 'Labeled for Single Use',
        formatLabel: booleanFormatter,
        chipPrefix: 'Labeled Single Use: '
    },
    singleUseReprocessed: {
        title: 'Single Use and Reprocessed',
        formatLabel: booleanFormatter,
        chipPrefix: 'Reprocessed: '
    },
    applicationNumber: {
        title: 'Device Application' //'Application Number',
    },
    productCode: {
        title: 'Product Code',
        formatLabel: identity,
        formatFacetLabel: (key, facet) => {
            return facet.device ? `${key} - ${upperFirst(facet.device)}` : key;
        },
        facetSearchableText: (facet) => joinOptional(' ', facet.key, facet.device)
    },
    manufacturer: {
        title: 'Company',
        chartType: 'line'
    },
    familyCompany: {
        title: 'Owner/Operator',
        chartType: 'line'
    },
    familyBrand: {
        title: 'Brand Group',
        chartType: 'line'
    },
    gmdn: {
        title: 'GMDN',
        formatLabel: sentenceCase,
        chartType: 'line'
    },
    fdaDeterminedCause: {
        title: 'FDA Determined Cause',
        formatLabel: titleCase,
        getFacetCount: getCount2
    },
    recallReason: {
        title: 'Reason for Recall',
        formatLabel: titleCase,
        getFacetCount: getCount2
    },
    companyName: {
        title: 'Company',
        chartType: 'line'
    },
    brandName,
    adverseEventBrandName: brandName,
    recallBrandName: brandName,
    recallClass: {
        title: 'Recall Class',
        formatLabel: (key) => `Class ${key}`,
        getFacetCount: getCount2
    },
    recallStatus: {
        title: 'Recall Status',
        formatLabel: titleCase,
        getFacetCount: getCount2
    },
    recallAction: {
        title: 'Recall Action',
        formatLabel: titleCase,
        getFacetCount: getCount2
    },
    recallingFirm: {
        title: 'Recalling Firm'
    },
    gudid: {
        title: 'Device',
        formatLabel: decodeHtmlEntities
    },
    reviewPanel: {
        title: 'Review Panel',
        // Apply capitalization, including after "/".
        formatLabel: (key: string) => suppReasonCase(key?.toLowerCase(), false)
    },
    establishmentName: {
        title: 'Establishment',
        chartType: 'line'
    },
    deviceProblemFdaCode: {
        title: 'Device Problem Code'
    },
    patientProblemFdaCode: {
        title: 'Patient Problem Code'
    },
    patientDevice: { // for all CA problem types
        title: 'Problem'
    },
    applicant: {
        title: 'Applicant'
    },
    recallCount: {
        title: 'Recall Count',
        chipPrefix: 'Recalls: '
    },
    committee: {
        title: 'Review Panel',
        formatLabel: (key: string) => suppReasonCase(key?.toLowerCase(), false)
    },
    submissionType: {
        title: 'Submission Type',
        formatLabel: titleCase
    },
    docType: {
        title: 'Application Type',
        formatLabel: (key) => DocConfig.convert(key, 'name', 'docType')
    },
    relatedAppType: {
        title: 'Associated Applications',
        formatLabel: (key) => applicationTypeMap[key] ?? titleCase(key)
    },
    interventionType: {
        title: 'Intervention Type',
        formatLabel: titleCase
    },
    studyType: {
        title: 'Study Type',
        formatLabel: titleCase
    },
    status: {
        title: 'Status',
        formatLabel: titleCase
    },
    resultsPosted: {
        title: 'Results Posted',
        formatLabel: (key: string | number) => key === 1 || key === '1' ? 'Yes' : 'No',
        chipPrefix: 'Results Posted: '
    },
    phase: {
        title: 'Phase',
        formatLabel: titleCase
    },
    participantCount: {
        title: 'Participant Count'
    },
    condition: {
        title: 'Conditions',
        formatLabel: titleCase
    },
    conditionFilter: {
        title: 'Conditions',
        formatLabel: (key) => key.toLowerCase(),
        chipPrefix: 'Condition: '
    },
    sponsorCollaborator: {
        title: 'Sponsor/Collaborator',
        formatLabel: (key) => key.toLowerCase(),
        chipPrefix: 'Sponsor/Collaborator: '
    },
    sponsorFilter: {
        title: 'Sponsor',
        formatLabel: (key) => key.toLowerCase(),
        chipPrefix: 'Sponsor: '
    },
    collaboratorFilter: {
        title: 'Collaborator',
        formatLabel: (key) => key.toLowerCase(),
        chipPrefix: 'Collaborator: '
    },
    postApprovalStudy: {
        title: 'Post-Approval Study'
    },
    clinicalTrial: {
        title: 'Clinical Trial'
    },
    ingredientActiveCode: {
        title: 'Active Ingredient'
    },
    ingredientActiveName: {
        title: 'Active Ingredient'
    },
    ingredientInactiveCode: {
        title: 'Inactive Ingredient'
    },
    ingredientInactiveName: {
        title: 'Inactive Ingredient'
    },
    drugBrand: brandName,
    medicinalProduct: brandName,
    drugNdc: {
        title: 'NDC'
    },
    reactionOutcome: {
        title: 'Outcome'
    },
    ageGroup: {
        title: 'Age Group',
        formatLabel: titleCase
    },
    ageYear: {
        title: 'Age'
    },
    sex: {
        title: 'Sex',
        formatLabel: titleCase
    },
    administrationRoute: {
        title: 'Administration Route',
        formatLabel: titleCase
    },
    occurCountry: {
        title: 'Occur Country',
        formatLabel: countryName
    },
    drugCharacterization: {
        title: 'Drug Characterization',
        formatLabel: titleCase
    },
    qualification: {
        title: 'Reporter',
        formatLabel: titleCase
    },
    drugCategory: {
        title: 'Drug Category',
        formatLabel: (key) => key === 'OTC' ? key : titleCase(key)
    },
    meddraHighLevelGroupTerm: {
        title: 'MedDRA High-Level Group Term',
        formatLabel: formatMeddraTerm
    },
    meddraHighLevelTerm: {
        title: 'MedDRA High-Level Term',
        formatLabel: formatMeddraTerm
    },
    meddraPreferredTerm: {
        title: 'MedDRA Preferred Term',
        formatLabel: formatMeddraTerm
    },
    meddraSystemOrganClass: {
        title: 'MedDRA System Organ Class',
        formatLabel: formatMeddraTerm
    },
    section: {
        title: 'Section'
    },
    fulfillExpediteCriteria: {
        title: 'Case Expedited',
        // TODO: need to check this if using.
        formatLabel: (key) => key === '1' ? 'Yes' : 'No'
    },
    seriousness: {
        title: 'Seriousness'
    },
}

const fillSettings = (defined: FieldSetting): Required<FieldSetting> => {
    const combined = { ...getFallbacks(defined), ...defined };
    // Apply "NULL" => "N/A" replacement to all facets.
    return {
        ...combined,
        formatLabel: (key) =>
            isPlaceholder(key) ? 'N/A' : combined.formatLabel(key),
        formatFacetLabel: (key, facet) =>
            isPlaceholder(key) ? 'N/A' : combined.formatFacetLabel(key, facet)
    }
}

const COMPLETE_FIELD_SETTINGS: Record<AnyFacetKey, Required<FieldSetting>> = mapValues(
    FIELD_SETTINGS,
    fillSettings
);

export const getSettings = (type: AnyFacetKey): Required<FieldSetting> => {
    if (!(type in COMPLETE_FIELD_SETTINGS)) {
        sentryWarn(`invalid facet key ${type}`);
    }
    return COMPLETE_FIELD_SETTINGS[type] ?? getFallbacks({ title: titleCase(startCase(type)) });
}

export type ChipTextMapper = (type: AnyFacetKey, str: string) => string;

const makeGetChipText = (prefix: boolean): ChipTextMapper =>
    (type, str) => {
        // Note: previous code applied sentence case to all, including manufacturers.
        const { formatLabel = titleCase, chipPrefix = '' } = FIELD_SETTINGS[type] || {};
        const base = isPlaceholder(str) ? 'N/A' : formatLabel(str) || 'N/A';
        return prefix ? chipPrefix + base : base;
    };

export const getChipText: ChipTextMapper = makeGetChipText(true);

export const getUnprefixedChipText: ChipTextMapper = makeGetChipText(false);

export const getFacetTitle = (type: AnyFacetKey): string => getSettings(type).title;

export const getFilterBoxTitle = (type: AnyFacetKey): string => getSettings(type).filterBoxTitle;

export const getChartType = (type: AnyFacetKey) => getSettings(type).chartType;

export const formatKey = (type: AnyFacetKey) => getSettings(type).formatLabel;

export const formatFacetLabel = (type: AnyFacetKey) =>
    getSettings(type).formatFacetLabel;

export const getFacetSearchText = (type: AnyFacetKey, facet: ApiFacet): string =>
    getSettings(type).facetSearchableText(facet);

export const getControlProps = (type: AnyFacetKey) => {
    const { filterBoxTitle, formatFacetLabel, getFacetCount } = getSettings(type);
    return {
        title: filterBoxTitle,
        formatLabel: formatFacetLabel,
        getCount: getFacetCount,
    }
}
