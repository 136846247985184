/**
 * Standardize the identifiers for the device application types.
 * Note: the string value here matches the `urlSlug` property.
 */
export enum DocType {
    FiveTenK = '510k',
    DeNovo = 'den',
    HDE = 'hde',
    PMA = 'pma',
    ProductCode = 'product-code',
    RegNum = 'regulation-number',
    Guidance = 'guidance',
    ClinicalTrial = 'clinical-trial'
}

export enum SuppType {
    HDESupplement = 'hde/supplement',
    PMASupplement = 'pma/supplement'
}

// See: https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944 re enum merging
export type DocOrSuppType = DocType | SuppType;
export const DocOrSuppType = {
    ...DocType,
    ...SuppType
}

export const isSupplementType = <T extends string>(type: T): type is T & SuppType =>
    type.endsWith('/supplement');
