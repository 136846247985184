import { useAddStatsMutation } from '@api';
import { useIsLoggedIn } from '@features/auth';
import { getFingerprint } from '@helpers/fingerprint';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Send device fingerprint to '/stats' endpoint on every pageview.
 */
const Fingerprint = (): null => {

    // Store the device fingerprint.
    const [fingerprint, setFingerprint] = useState<string>();

    // Fingerprint is generated once per session.
    useEffect(
        () => {
            let isSubscribed = true;
            (async () => {
                const fp = await getFingerprint();
                if (isSubscribed) {
                    setFingerprint(fp);
                }
            })();
            // Prevent setState calls on unmounted components.
            return () => {
                isSubscribed = false
            }
        },
        []
    );

    // Send back to the server on every pageview.
    const { search, pathname } = useLocation();

    const [send] = useAddStatsMutation();

    // But don't send if the user isn't logged-in yet.
    const isLoggedIn = useIsLoggedIn();

    useEffect(
        () => {
            if (fingerprint && isLoggedIn) {
                // Note: not actually skipped if fingerprint is false,
                // because the effect will be called again once the fingerprint changes.
                send({ fingerprint });
            }
        },
        [pathname, search, fingerprint, isLoggedIn]
    )

    // Render-less component.
    return null;
}

export default Fingerprint;
