import React, { createContext, ReactNode, useContext } from 'react';

export interface SupportedExportFormats {
    [fmt: string]: boolean;
}

// TODO: probably better handled elsewhere, but I need to switch between text and icon buttons.
export interface ExportButtonProps {
    iconOnly?: boolean;
    tooltip?: string;
}

export interface ExportConfig {
    formats: SupportedExportFormats;
    handler: (format: string) => void;
    disabled: boolean;
    buttonProps?: ExportButtonProps;
}

export interface ApiExportData<Body = Record<string, any>> {
    params: Body;
    url: string;
    filename?: string;
}

export interface DialogSettings {
    modalTitle: string;
    modalText: ReactNode;
}

export type ExportHandler = (format: string) => void;

export interface IExportContext {
    setExportConfig: (config: ExportConfig) => void; // declares support for exporting
    callHandler: ExportHandler; // execute the handler function from the config
    canExport: boolean; // true if exports are enabled
    buttonProps: ExportButtonProps;
}

export const ExportContext = React.createContext<IExportContext>({
    setExportConfig: () => null,
    callHandler: () => null,
    canExport: false,
    buttonProps: {}
});

export const useExportContext = () => useContext(ExportContext);

type SetExportCallback<Body = Record<string, any>> = (data: ApiExportData<Body>) => void;

export const ExportProcessContext = createContext<SetExportCallback>(() => undefined);

// Generic type cannot be inferred, but can be set on the hook in order to check the `params`.
export const useExportProcessor = <Body extends unknown>(): SetExportCallback<Body> => useContext(ExportProcessContext);
