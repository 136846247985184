export enum UserRoleId {
    SystemAdmin = 'yryyyyyyyyyyy',
    OrgAdmin = 'yeyyyyyyyyyyy',
    User = 'yoyyyyyyyyyyy'
}

export enum BasilModule {
    Regulatory = 'DEVICE_KNOWLEDGE_BASE',
    Postmarket = 'POST_MARKET_INTELLIGENCE',
    BasicRegulatory = 'BASIC_SEARCH',
    Executive = 'EXECUTIVE_REGULATORY',
    PharmaCovigilance = 'PHARMA_COVIGILANCE'
}

/**
 * `href` for all 'Contact Sales' buttons and links.
 */
export const SALES_CONTACT_URI: string = 'mailto:sales@basilsys.com?subject=Basil%20Subscription%20Inquiry';

/**
 * Duration of the trial period.
 */
export const TRIAL_DAYS = 14;

/**
 * Number of days to use when looking for alert flags/icons on a dataset.
 */
export const ALERT_DAYS = 30;

/**
 * Check every 15 minutes.
 * Used by trial expired and version check.
 */
export const POLLING_INTERVAL = 1000 * 60 * 15;

/**
 * Access data attributes from the html document.
 * Apply default values before exporting.
 */
const dataset = document.documentElement.dataset || {};

/**
 * Base URL for all API calls depends on the tenant.
 */
export const API_BASE_URL = dataset.monacoApiBaseUrl || 'https://api.dev.basil.dev';

/**
 * Sentry URL is probably just a constant. Seems to be empty in document.
 */
export const SENTRY_URL = dataset.monacoSentryUrl || 'https://103bbe3a50b941daaea4a78e3f2636c7@o159497.ingest.sentry.io/1221973';

/**
 * Convert string value to boolean.
 * Note: will not be present at all when running on localhost.
 */
export const IS_TRIAL_SITE = dataset.isTrialSite === 'true';

/**
 * Forgot password is only enabled on certain tenants.
 */
export const FORGOT_PASSWORD_ENABLED = dataset.forgotPasswordEnabled !== 'false';

/**
 * The name of the tenant, to be shown on the login screen.
 */
export const ORGANIZATION_NAME = dataset.tenantLabel || '';

/**
 * True if users can create their own accounts.
 */
export const IS_SELF_SIGNUP = dataset.userSignupAllowed === 'true';

/**
 * MODE is a global variable which is set by the webpack define plugin.
 */
declare const MODE: string;
export const IS_DEV_MODE = MODE === 'dev';

/**
 * Check if tenant uses SSO and get the login redirect path.
 */
export const IS_SSO_ENABLED = dataset.ssoEnabled === 'true';
export const SSO_LOGIN_URL = dataset.ssoLoginUrl;

export const IS_FREEMIUM = dataset.basicSignupEnabled === 'true';

export const RECAPTCHA_SITE_KEY = dataset.basicRecaptchaSiteKey;
