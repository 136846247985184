import type { DatasetCombined, ReviewPanelDataResponse } from '@api';
import type { ReviewPanelField } from '@api/enums';
import type { AnyFacetKey } from '@content/Facet/formatLabels';
import type { ModalEntityType } from '@features/table-modal';
import type { TimeInterval } from '@helpers/dateUtils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DASHBOARD_DEFAULT_INTERVAL } from './constants';

export type FacetSort = 'adverseEvent' | 'recall';

export type DashFacetKey = Extract<keyof typeof ReviewPanelField, keyof ReviewPanelDataResponse>;

export interface ActiveFacet {
    field: DashFacetKey;
    key: string;
    label: string;
}

export interface ModalFacet {
    field: AnyFacetKey;
    values: string[];
    label?: string;
}

export interface ModalDate {
    label?: string;
    start: string;
    end: string;
}

export interface ModalPayload {
    type: ModalEntityType;
    date?: ModalDate;
    filters?: ModalFacet[];
}

export interface ModalState extends ModalPayload {
    isOpen: boolean;
    filters: ModalFacet[];
}

export interface PmiDashboardState {
    date: TimeInterval;
    facetSort: FacetSort;
    reviewPanel?: string;
    userDataset?: DatasetCombined;
    activeFacet?: ActiveFacet;
    modal: ModalState;
    preferencesLoaded: boolean;
}

const initialState: PmiDashboardState = {
    date: DASHBOARD_DEFAULT_INTERVAL,
    facetSort: 'adverseEvent',
    modal: { isOpen: false, type: 'adverseEvent', filters: [] },
    preferencesLoaded: false
}

const dashboardSlice = createSlice({
    name: 'pmiDashboard',
    initialState,
    reducers: {
        setActiveFacet: (state, action: PayloadAction<ActiveFacet>) => {
            state.activeFacet = action.payload;
        },
        clearActiveFacet: (state) => {
            delete state.activeFacet;
        },
        setReviewPanel: (state, action: PayloadAction<string | undefined>) => {
            state.reviewPanel = action.payload;
            // Clear facet when changing panel.
            delete state.activeFacet;
            delete state.userDataset;
            state.preferencesLoaded = true;
        },
        setDataset: (state, action: PayloadAction<DatasetCombined>) => {
            state.userDataset = action.payload;
            delete state.activeFacet;
            delete state.reviewPanel;
        },
        setFacetSort: (state, action: PayloadAction<FacetSort>) => {
            state.facetSort = action.payload;
        },
        setDateInterval: (state, action: PayloadAction<TimeInterval>) => {
            state.date = action.payload;
        },
        openTableModal: (state, action: PayloadAction<ModalPayload>) => {
            const { date, type, filters = [] } = action.payload;
            state.modal = {
                isOpen: true,
                date,
                type,
                filters
            };
        },
        closeTableModal: (state) => {
            state.modal.isOpen = false;
        },
        clearTableModal: (state) => {
            state.modal = initialState.modal;
        }
    }
})

export default dashboardSlice.reducer;

export const {
    setDataset,
    setActiveFacet,
    clearActiveFacet,
    setReviewPanel,
    setFacetSort,
    setDateInterval,
    openTableModal,
    closeTableModal,
    clearTableModal
} = dashboardSlice.actions;
