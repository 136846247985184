import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { To } from 'react-router-dom';
import { getUserFromLocalStorage, storedLogoutPath } from './localStorage';
import type { AuthPayload, LoggedInUser, LogoutPayload } from './types';
import { stateForUser } from './utilities';

export interface AuthState {
    loggedIn: boolean;
    user?: LoggedInUser;
    /**
     * Location that the user was logged out or redirected from,
     * and will return to once authenticated.
     */
    logoutPath?: To;
}

/**
 * The initial state is not a constant because it depends on values from localStorage
 * and the HTML document. Avoid issues by creating with a function and passing to
 * the preloadedState argument when creating the store.
 */
export const initializeAuthState = (): AuthState => {
    const stored = getUserFromLocalStorage();
    if (stored) {
        return {
            loggedIn: true,
            user: {
                ...stateForUser(stored.user, stored.tokens),
                // always false when accessing a stored user.
                isFirstLogin: false
            }
        }
    }
    return {
        loggedIn: false,
        logoutPath: storedLogoutPath.get()
    }
}

/**
 * However we must also define a constant initialState to be used
 * when no preloadedState is provided.
 */
const initialState: AuthState = {
    loggedIn: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        expireTrial: (state) => {
            if (!state.user || !state.user.trial) return; // just in case
            state.user.trial.isExpired = true;
        },
        tokenReceived: (state, action: PayloadAction<AuthPayload>) => {
            const { user, ...tokens } = action.payload;
            // Note: it is good to reset the entire user on every 15-minute refresh
            // in order to respond to changes.
            state.user = stateForUser(user, tokens);
            state.loggedIn = true;
        },
        userLoggedOut: (state, action: PayloadAction<LogoutPayload>) => {
            // Note: do not overwrite the stored logoutPath if the user is already logged out.
            if (state.loggedIn) {
                state.logoutPath = action.payload.from;
                state.loggedIn = false;
                delete state.user;
            }
        }
    }
})

export default authSlice.reducer;

export const { expireTrial, userLoggedOut, tokenReceived } = authSlice.actions;
