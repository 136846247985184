import { TimeInterval } from '@helpers/dateUtils';

export const DASHBOARD_DATE_OPTIONS: TimeInterval[] =
    [30, 60, 90, 180]
        .map<TimeInterval>(count => ({ timeUnit: 'day', count }))
        .concat({ timeUnit: 'year', count: 1 });

export const DASHBOARD_DEFAULT_INTERVAL = DASHBOARD_DATE_OPTIONS[0];

export const DASHBOARD_TOP_COUNT = 10;
