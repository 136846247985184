import { DrugField } from '@api/enums';
import type { KeyOfType } from '@helpers/types';
import type { DrugText, TitleTextDiff } from '../../../API';
import createCountryFieldUtil from './countryFieldUtil';

/**
 * Controls the order of options in the add a section list.
 */
export const FIELD_CHOICES = [
    DrugField.label,
    DrugField.indicationsAndUsage,
    DrugField.mechanismOfAction,
    DrugField.descriptionSection,
    DrugField.representedOrganization,
    DrugField.activeIngredient,
    DrugField.inactiveIngredient,
    DrugField.brandName,
    DrugField.dosageAndAdministration,
    DrugField.boxedWarning,
    DrugField.warningsAndPrecautions,
    DrugField.adverseReactions,
    DrugField.contraindications,
    DrugField.drugInteractions,
    DrugField.useInSpecificPopulations,
    DrugField.clinicalStudiesSection,
    DrugField.clinicalPharmacology,
    DrugField.patientCounselingInformation
];

/**
 * Can optionally override the text. Defaults to titleCase.
 */
export const NAME_OVERRIDES = {
    [DrugField.label]: 'Search in all sections',
    [DrugField.dosageAndAdministration]: 'Dosage / Administration',
    [DrugField.warningsAndPrecautions]: 'Warnings / Precautions',
    [DrugField.representedOrganization]: 'Company / Packager',
    [DrugField.descriptionSection]: 'Description',
    [DrugField.clinicalStudiesSection]: 'Clinical Studies'
};

export type SplSectionProperty = KeyOfType<DrugText, TitleTextDiff[]>;

/**
 * Controls the order and labels of the history chart.
 */
const HISTORY_SECTIONS: [string, SplSectionProperty][] = [
    ['Boxed Warning', 'boxedWarning'],
    ['Indications and Usage', 'indicationsAndUsage'],
    ['Dosage / Administration', 'dosageAndAdministration'],
    ['Dosage Forms / Strengths', 'dosageFormsAndStrengths'],
    ['Contraindications', 'contraindications'],
    ['Warnings / Precautions', 'warningsAndPrecautions'],
    ['Adverse Reactions', 'adverseReactions'],
    ['Drug Interactions', 'drugInteractions'],
    ['Specific Populations', 'useInSpecificPopulations'],
    ['Drug Abuse / Dependence', 'drugAbuseAndDependence'],
    ['Overdosage', 'overdosageSection'],
    ['Description', 'descriptionSection'],
    ['Clinical Pharmacology', 'clinicalPharmacology'],
    ['Nonclinical Toxicology', 'nonclinicalToxicology'],
    ['Clinical Studies', 'clinicalStudiesSection'],
    ['References', 'referencesSection'],
    ['How Supplied/Storage and Handling', 'howSuppliedStorageAndHandling'],
    ['Patient Counseling Information', 'patientCounselingInformation'],
]

export const spl = createCountryFieldUtil<DrugField>(
    DrugField,
    FIELD_CHOICES,
    NAME_OVERRIDES,
    HISTORY_SECTIONS
);

// TODO: refactor out usages, and go through the global instead.
export const {
    formatField,
    formatFieldForSubtitle
} = spl;
