import type { Selector as ReduxSelector, StateFromReducersMapObject } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { AnyAction, MiddlewareAPI } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type rootReducer from './rootReducer';

// Infer the type of the top-level state object
export type RootState = StateFromReducersMapObject<typeof rootReducer>;

// Dispatch type which is aware of thunk middleware
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

// Export hooks with the correct typings
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

// Modified version of the reselect Selector type
export type Selector<Result = unknown, Params extends never | readonly any[] = never> = ReduxSelector<RootState, Result, Params>

// Type for custom middleware.
export type AppMiddleware = (api: MiddlewareAPI<AppDispatch, RootState>) =>
        (next: AppDispatch) => AppDispatch
