import { createSerializedStorage } from '@helpers/localStorage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppMiddleware } from '@store';

/**
 * Store the pages which have already been counted for the meter,
 * so as not to "charge" for the same one twice.
 */

type IdsForKey = {
    [id in string]?: true;
}
type MeterDataState = {
    [meterKey in string]?: IdsForKey;
}

export interface MeterIdentifier {
    meterKey: string;
    uniqueId: string;
}

// Note: will have weird issues if switching between user accounts, as data is not tied to the user.
const meterDataStorage = createSerializedStorage<MeterDataState>('meterData');

const initialState: MeterDataState = meterDataStorage.get() ?? {};

const meterSlice = createSlice({
    initialState,
    name: 'meterData',
    reducers: {
        countMeter: (state, action: PayloadAction<MeterIdentifier>) => {
            const { meterKey, uniqueId } = action.payload;
            const keyData = state[meterKey];
            if (keyData) {
                keyData[uniqueId] = true;
            } else {
                state[meterKey] = {
                    [uniqueId]: true
                };
            }
        }
    }
});

export default meterSlice.reducer;

export const { countMeter } = meterSlice.actions;

export const selectIsAlreadyCounted = (
    state: { meterData: MeterDataState },
    { meterKey, uniqueId }: MeterIdentifier
) => !!state.meterData[meterKey]?.[uniqueId];

export const meterMiddleware: AppMiddleware = ({ getState }) => (next) => (action) => {
    // allow the reducer to handle the action BEFORE performing side effects.
    const result = next(action);

    if (countMeter.match(action)) {
        meterDataStorage.set(getState().meterData);
    }

    return result;
}
