import API from '@api';
import errorMiddleware from '@features/errors/errorMiddleware';
import authMiddleware from '@features/login/authMiddleware';
import { meterMiddleware } from '@features/meter/meterSlice';
import { configureStore } from '@reduxjs/toolkit';
import { PreloadedState } from 'redux';
import rootReducer from './rootReducer';
import initializeState from './temporary__intialState';
import { RootState } from './types';

// Can create a store with a partial initial state. This is useful for testing,
// Or for initializing a store instance from cached data.
export function makeStore(preloadedState: Partial<PreloadedState<RootState>> = {}) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaults) =>
            getDefaults({
                thunk: true,
                // TODO: enable once bad code is fixed
                serializableCheck: false,
                immutableCheck: false
            }).concat([
                API.middleware,
                authMiddleware,
                errorMiddleware,
                meterMiddleware
            ])
    })
}

// Export a function to create the store with a preloaded user from localStorage
export default function initializeStore() {
    return makeStore(initializeState());
};
