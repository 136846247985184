import api, { Tags } from '@api';
import { AuthPayload, saveUserToLocalStorage, storedLogoutPath, tokenReceived, userLoggedOut } from '@features/auth';
import type { LogoutPayload } from '@features/auth/state/types';
import { GA4 } from '@features/ga4';
import { bySingleUseToken, login } from '@features/iam';
import { typedValues } from '@helpers/types';
import { isFirstLogin } from '@helpers/userRoles';
import type { AppMiddleware } from '@store';

/**
 * Use middleware to sync with localStorage values.
 * And to set auth state in response to API login mutation. (or could handle on component)
 */
const authMiddleware: AppMiddleware = ({ dispatch }) => (next) => (action) => {
    // allow the reducer to handle the action BEFORE performing side effects.
    const nextState = next(action);

    // Handle login
    if (login.matchFulfilled(action) || bySingleUseToken.matchFulfilled(action)) {
        // Note: must assert due to optional properties in API types.
        const payload = action.payload as AuthPayload;
        // pass payload to auth reducer
        dispatch(tokenReceived(payload));

        // track login event in analytics
        const { user } = payload;
        GA4.login(user.id, isFirstLogin(user));

        // TODO: want to call getPreferences here, but that needs to happen AFTER the action is handled.
    }

    // Handle token refresh, or second step of login.
    if (tokenReceived.match(action)) {

        // save user auth to localstorage
        saveUserToLocalStorage(action.payload);
    }

    // Handle logout
    if (userLoggedOut.match(action)) {

        // clear the saved search results link
        sessionStorage.clear();

        // clear the user state
        localStorage.clear();

        // invalidate all tags
        api.util.invalidateTags(typedValues(Tags));

        // set the redirect path
        // TODO: WHY DOESN'T THE ACTION MATCH TYPEGUARD WORK?!?
        const payload = action.payload as LogoutPayload;
        if (payload?.from) {
            storedLogoutPath.set(payload.from);
        }
    }

    return nextState;
}

export default authMiddleware;
