import { default as api } from '@api/emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPreference: build.query<GetPreferenceApiResponse, GetPreferenceApiArg>({
      query: () => ({ url: `/user-data/v1/common/preference` }),
    }),
    updatePreference: build.mutation<
      UpdatePreferenceApiResponse,
      UpdatePreferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/common/preference`,
        method: "PUT",
        body: queryArg,
      }),
    }),
    patchPreference: build.mutation<
      PatchPreferenceApiResponse,
      PatchPreferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/common/preference`,
        method: "PATCH",
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetPreferenceApiResponse =
  /** status 200 Successful Response */ PreferenceResponse;
export type GetPreferenceApiArg = void;
export type UpdatePreferenceApiResponse =
  /** status 200 Successful Response */ PreferenceResponse;
export type UpdatePreferenceApiArg = PreferenceUpdate;
export type PatchPreferenceApiResponse =
  /** status 200 Successful Response */ PreferenceResponse;
export type PatchPreferenceApiArg = object;
export type ExecutiveRegulatoryAlertFrequencyEnum =
  | "DAILY"
  | "WEEKLY"
  | "MONTHLY";
export type Preference = {
  enableEmails?: boolean;
  enableExecutiveRegulatoryAlert?: boolean;
  executiveRegulatoryAlertFrequency?: ExecutiveRegulatoryAlertFrequencyEnum;
  preferenceBlob?: object | null;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
};
export type PreferenceResponse = {
  preferences: Preference;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type PreferenceUpdate = {
  enableEmails?: boolean | null;
  enableExecutiveRegulatoryAlert?: boolean | null;
  executiveRegulatoryAlertFrequency?: ExecutiveRegulatoryAlertFrequencyEnum | null;
  preferenceBlob?: object | null;
};
export const {
  useGetPreferenceQuery,
  useUpdatePreferenceMutation,
  usePatchPreferenceMutation,
} = injectedRtkApi;
