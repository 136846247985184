import { makeArray } from '@helpers/arrayUtils';
import { isCallback } from '@helpers/state';
import queryString, { ParsedQuery } from 'query-string';
import { SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const OPTIONS = {
    arrayFormat: 'comma',
    // Note: could use `parseNumbers` to parse years and pageSize
    encode: true,
    skipNull: true
} as const;

/**
 * Binds the default options for comma-separated arrays, etc.
 */
export const stringifyQuery = (object: {[key: string]: any}) =>
    queryString.stringify(object, OPTIONS);

/**
 * Binds the default options for comma-separated arrays, etc.
 */
export const parseQuery = <T extends ParsedQuery>(search: string) =>
    queryString.parse(search, OPTIONS) as T;

/**
 * Add URL params to a base URL.
 */
export const withQueryVars = (href: string, queryVars: Record<string, any> = {}): string => {
    const search = queryString.stringify(queryVars, OPTIONS);
    return search ? `${href}?${search}` : href;
}

/**
 * Access the parsed query object for the current URL.
 */
export function useQueryObject<T extends ParsedQuery = ParsedQuery>(): T {
    const { search } = useLocation();

    return useMemo(
        () => parseQuery<T>(search),
        [search]
    )
}

/**
 * Use only the first value, if more than one.
 */
export const asString = (value?: string | string[]): string | undefined =>
    Array.isArray(value) ? value[0] : value;

/**
 * Subset of methods compatible with URLSearchParams.
 * Not including `keys` here due to the incompatible types.
 */
export interface QueryGetCore {
    get: (arg: string) => string | undefined;
    getAll: (arg: string) => string[];
    has: (arg: string) => boolean;
}
export interface QueryGet extends QueryGetCore {
    keys: () => string[];
}

/**
 * Returns an object with `get` and `getAll` functions, akin to URLSearchParams.
 * But uses `queryString` for parsing in order to support comma-separated values.
 */
export function useQueryGet(): QueryGet {
    const query = useQueryObject();

    return useMemo(
        () => ({
            get: (arg: string): string | undefined => asString(query[arg]),
            getAll: (arg: string): string[] => makeArray(query[arg], false),
            keys: (): string[] => Object.keys(query),
            has: (arg: string): boolean => arg in query
        }),
        [query]
    );
}

/**
 * Create a memoized handler that uses a prevState callback.
 * Note: does not preserve location state.
 */
export const useModifyUrlQuery = <T extends ParsedQuery>() => {
    const { search, pathname } = useLocation();

    const navigate = useNavigate();

    const searchRef = useRef(search);

    useEffect(() => {
        searchRef.current = search;
    }, [search]);

    return useCallback((updater: SetStateAction<T>, addToHistory = false) => {
        const nextObject = isCallback(updater)
            ? updater(parseQuery<T>(searchRef.current))
            : updater;
        const nextStr = stringifyQuery(nextObject);
        navigate({
            pathname,
            search: '?' + nextStr
        }, {
            replace: !addToHistory
        });
    }, [pathname]);
}
