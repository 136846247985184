import { Alert as MuiAlert, alertClasses, AlertTitle, Button, Snackbar } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import React, {useState} from "react";
import {useIsOutdated} from "./useIsOutdated";

/**
 * Component shows a dismissible snackbar if the version is outdated,
 * renders nothing otherwise.
 */
const OutdatedSnackbar = () => {
    const [didDismiss, setDidDismiss] = useState(false);

    const isOutdated = useIsOutdated();

    return (
        <Snackbar
            open={isOutdated && !didDismiss}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            autoHideDuration={null} // don't hide
            onClose={() => setDidDismiss(true)}
        >
            <MuiAlert
                elevation={3}
                severity="info"
                variant="standard"
                action={
                    <Button
                        color="inherit"
                        size="small"
                        sx={{marginLeft: 2.5, fontSize: 13}}
                        endIcon={<RefreshIcon fontSize="small"/>}
                        onClick={() => window.location.reload()}
                    >
                        Reload
                    </Button>
                }
                sx={{[`& .${alertClasses.action}`]: { py: 0, alignItems: 'center' }}}
            >
                <>
                    <AlertTitle sx={{fontWeight: 700, fontSize: 16}}>Update Available</AlertTitle>
                    <span>Please refresh the page to enable the latest features.</span>
                </>
            </MuiAlert>
        </Snackbar>
    )
}

export default OutdatedSnackbar;
