import { DrugsFdaField } from '@api/enums';
import type { KeyOfType } from '@helpers/types';
import type { DrugsFdaText2, TitleTextDiff } from '../../../API';
import createCountryFieldUtil from './countryFieldUtil';
import { NAME_OVERRIDES } from './fieldsSPL';

/**
 * Controls the order of options in the add a section list.
 */
export const FIELD_CHOICES = [
    DrugsFdaField.label,
    DrugsFdaField.indicationsAndUsage,
    // DrugsFdaField.mechanismOfAction,
    DrugsFdaField.descriptionSection,
    // DrugsFdaField.representedOrganization,
    // DrugsFdaField.activeIngredient,
    // DrugsFdaField.inactiveIngredient,
    DrugsFdaField.brandName,
    DrugsFdaField.dosageAndAdministration,
    DrugsFdaField.boxedWarning,
    DrugsFdaField.warningsAndPrecautions,
    DrugsFdaField.adverseReactions,
    DrugsFdaField.contraindications,
    DrugsFdaField.drugInteractions,
    DrugsFdaField.useInSpecificPopulations,
    DrugsFdaField.clinicalStudiesSection,
    DrugsFdaField.clinicalPharmacology,
    DrugsFdaField.patientCounselingInformation,
    DrugsFdaField.patientInformation,
    DrugsFdaField.medicationGuide,
    DrugsFdaField.therapeuticEquivalents
];


export type FdaSectionProperty = KeyOfType<DrugsFdaText2, TitleTextDiff[]>;

/**
 * Controls the order and labels of the history chart.
 */
const HISTORY_SECTIONS: [string, FdaSectionProperty][] = [
    ['Boxed Warning', 'boxedWarning'],
    ['Indications and Usage', 'indicationsAndUsage'],
    ['Dosage / Administration', 'dosageAndAdministration'],
    ['Dosage Forms / Strengths', 'dosageFormsAndStrengths'],
    ['Contraindications', 'contraindications'],
    ['Warnings / Precautions', 'warningsAndPrecautions'],
    ['Adverse Reactions', 'adverseReactions'],
    ['Drug Interactions', 'drugInteractions'],
    ['Specific Populations', 'useInSpecificPopulations'],
    ['Drug Abuse / Dependence', 'drugAbuseAndDependence'],
    ['Overdosage', 'overdosageSection'],
    ['Description', 'descriptionSection'],
    ['Clinical Pharmacology', 'clinicalPharmacology'],
    ['Nonclinical Toxicology', 'nonclinicalToxicology'],
    ['Clinical Studies', 'clinicalStudiesSection'],
    ['References', 'referencesSection'],
    ['How Supplied/Storage and Handling', 'howSuppliedStorageAndHandling'],
    ['Patient Counseling Information', 'patientCounselingInformation'],
]

export const fda = createCountryFieldUtil<DrugsFdaField>(
    DrugsFdaField,
    FIELD_CHOICES,
    NAME_OVERRIDES,
    HISTORY_SECTIONS
);
