enum Font {
    NotoSans = '"Noto Sans", "Arial", sans-serif',
    NunitoSans = '"Nunito Sans", "Arial", sans-serif',
    OpenSans = '"Open Sans", "Arial", sans-serif',
    Varela = '"Varela Round", sans-serif',
    Arial = '"Arial", sans-serif',
    Lato = '"Lato", "Arial", sans-serif',
    Roboto = '"Roboto", "Arial", sans-serif'
}

export default Font;
