import { keyToEnum } from '@helpers/filterEnums';
import { startCase, upperFirst } from 'lodash';
import type { CommonFilter } from '../../API';

export enum ChartType {
    Faers = 'faers',
    ClinicalTrial = 'clinicalTrial',
    Both = 'both',
    Seriousness = 'seriousness',
    Outcomes = 'outcomes',
    OutcomesByMeddra = 'meddra-outcomes'
}

export type NumberType = 'count' | 'percent';

export type BubbleDisplay = 'numbers' | 'bubbles' | 'both';

export enum ChartView {
    StackedBar = 'stacked',
    HorizontalBar = 'horizontal',
    Matrix = 'matrix'
}

export const meddraOrder = [
    'meddraSystemOrganClass',
    'meddraHighLevelGroupTerm',
    'meddraHighLevelTerm',
    'meddraPreferredTerm'
] as const;

export type MeddraKey = (typeof meddraOrder)[number];

export const maxPathDepth = meddraOrder.length - 1;

export const maxFilterDepth = (type: ChartType): number => {
    if (type === ChartType.Seriousness) return 0; // None
    if (type === ChartType.Outcomes) return 1; // SOC only
    return maxPathDepth;
}

export const isMeddraType = (type: ChartType): boolean => {
    return type !== ChartType.Seriousness && type !== ChartType.Outcomes
}

export const isInSeverityMenu = (type: ChartType): boolean => {
    return type === ChartType.Seriousness || type === ChartType.Outcomes || type === ChartType.OutcomesByMeddra;
}

export const isFaers = (type: ChartType): boolean => {
    return type === ChartType.Seriousness || type === ChartType.Outcomes || type === ChartType.Faers || type === ChartType.OutcomesByMeddra;
}

export const formatMeddraLabel = (label: string): string =>
    upperFirst(label?.toLowerCase())
        .replace(/ n(ec|os)$/, (match) => match.toUpperCase())
        .replace(/ disorders?$/, '');

export const seriousKeyToLabel = (key: string): string =>
    upperFirst(startCase(key.replace('seriousness', '')));

export const meddraPathToFilters = (path: string[]): CommonFilter[] =>
    path.map((value, depth) => ({
        field: keyToEnum(meddraOrder[depth]),
        values: [value]
    }));

