import { storedLogoutPath } from '@features/auth';
import React, { lazy, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Capability, DeniedReason } from './capabilities';
import { useBlockedReason } from './useBlockedReason';

const ExpiredPage = lazy(() => import('../../Trial/ExpiredPage'));
const TrialBlockedPage = lazy(() => import('../../Trial/TrialBlockedPage'));
const ModuleBlockedPage = lazy(() => import('../../Module/ModuleBlockedPage'));
const ErrorPage = lazy(() => import('../../Misc/Errors/ErrorSplashPage'));

/**
 * Redirect to the login page and pass the current location to localStorage
 * in order to redirect back to the current location after login.
 */
const AuthRedirect = () => {
    const location = useLocation();

    useEffect(() => {
        storedLogoutPath.set(location);
    }, [location]);

    return (
        <Navigate to="/auth" replace/>
    );
}

interface AuthenticatedProps {
    /**
     * The capability required to view the content.
     */
    capability: Capability;
    /**
     * The content to display if the user is authenticated.
     */
    children: JSX.Element;
    /**
     * Can pass additional information to the blocked page.
     * Currently used by TrialBlockedPage only.
     */
    blockedContent?: ReactNode;
}

/**
 * Checks for permission before rendering the child content.
 * May show an error on the current URL or redirect to a new location.
 */
const Authenticated = ({ children, capability, blockedContent }: AuthenticatedProps) => {
    const blockReason = useBlockedReason(capability);
    if (!blockReason) {
        return children;
    }
    switch (blockReason.type) {
        case DeniedReason.LOGGED_OUT:
            return <AuthRedirect/>;
        case DeniedReason.EXPIRED_TRIAL:
            return <ExpiredPage/>;
        case DeniedReason.INSUFFICIENT_PRIVILEGES:
            // TODO: error message?
            return <Navigate to="/"/>;
        case DeniedReason.REQUIRES_MODULE:
            return <ModuleBlockedPage module={blockReason.module}/>;
        case DeniedReason.TRIAL_DISABLED_FEATURE:
            return <TrialBlockedPage featureDesc={blockReason.featureDesc} content={blockedContent}/>;
        default:
            return <ErrorPage title="Error"/>;
    }
}

export default Authenticated;
