import type { AnyFacetKey } from '@content/Facet/formatLabels';
import { createDateFilter } from '@helpers/dateUtils';
import { ApiFilter, isValidEnum } from '@helpers/filterEnums';
import type { AESelections } from '../selectionStateReducer';
import { pickValidFilters, pickValidRanges } from './pickValidFilters';

/**
 * Support a few additional keys which can be passed as filters,
 * but which are not included in any response facets.
 */
export type QueryFacetSelections = Partial<Record<AnyFacetKey, string[]>>;

/**
 * Support passing any filters to be used with searchType 'QUERY'
 * on the `queries` property.
 */
export type QuerySelections = Omit<Partial<AESelections>, 'facets'> & {
    facets: QueryFacetSelections;
    queries?: QueryFacetSelections;
}

/**
 * Base interface to extend, where all enums are just string.
 */
export interface CommonQuery {
    query?: string;
    filters?: ApiFilter<string>[];
    datasetId?: string;
    dataset?: object;
    within?: string;
    limitTo?: string;
}

// Note: doesn't include orderBy because it's not part of the selections.
// Note: date can be passed either with a `date` property and `dateField` OR via `ranges`.
// TODO: move the batch date stuff somewhere else.

const genericPrepare = <Q extends CommonQuery>(enums: {
    filter: Record<string, NonNullable<Q['filters']>[number]['field']>;
    within?: Record<string, NonNullable<Q['within']>>;
    limitTo?: Record<string, NonNullable<Q['limitTo']>>;
    dateField?: NonNullable<Q['filters']>[number]['field'];
    /**
     * If true, ignore batch dates and always use the base query dates.
     */
    ignoreBatch?: boolean;
    /**
     * Can use a different date field if the dates come from a batch.
     */
    batchDateField?: NonNullable<Q['filters']>[number]['field'];
}) => (selections: QuerySelections): Pick<Q, keyof CommonQuery> => {
    const { within, limitTo, query, dataset, datasetId, date = { start: null, end: null }, facets = {}, queries, ranges } = selections;

    const filters: Q['filters'] = pickValidFilters(enums.filter)(facets);

    if (enums.dateField) {
        if (!enums.ignoreBatch && date.batch && (date.batch.start || date.batch.end)) {
            filters.push(createDateFilter(enums.batchDateField || enums.dateField, date.batch));
        }
        else if (date.start || date.end) {
            filters.push(createDateFilter(enums.dateField, date));
        }
    }

    if (queries) {
        const queryFilters = pickValidFilters(enums.filter)(queries, { searchType: 'QUERY'});
        filters.push(...queryFilters);
    }

    if (ranges) {
        const rangeFilters = pickValidRanges(enums.filter)(ranges);
        filters.push(...rangeFilters);
    }

    const params: Pick<Q, keyof CommonQuery> = {
        query: (query || '').trim(),
        filters,
        dataset,
        datasetId
    };

    if (within && enums.within && isValidEnum(enums.within, within)) {
        params.within = within;
    }

    if (limitTo && enums.limitTo && isValidEnum(enums.limitTo, limitTo)) {
        params.limitTo = limitTo;
    }

    return params;
}

export default genericPrepare;

