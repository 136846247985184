import { mapValues } from 'lodash';
import type { ApiFacet, Facet } from './types';

export const getFacetKey = (facet: { key: string }): string => facet.key;

export const getFacetCount = (facet: { count?: number }): number => facet.count || 0;

/**
 * Function which can handle either strings or facets and return facets.
 */
export const asFacet = (value: Facet | string): Facet =>
    typeof value === 'string' ? { key: value } : value;

/**
 * Some endpoints return facets as objects with { buckets: [] } instead of arrays
 * TODO: what about min/max date facets?
 */
type EitherResponse = { buckets: ApiFacet[] } | ApiFacet[];
const hasBuckets = (facetData: EitherResponse): facetData is { buckets: ApiFacet[] } =>
    'buckets' in facetData;
export const getFacetsArray = (original: EitherResponse): ApiFacet[] =>
    hasBuckets(original) ? original.buckets : original;

type Flattenable = {
    [K in string]?: { buckets: ApiFacet[] } | ApiFacet[];
}
type FlattenedFacets<Original extends Flattenable> = {
    [K in keyof Original]?: ApiFacet[];
};
export const flattenFacets = <T extends Flattenable>(original: T): FlattenedFacets<T> => {
    return mapValues(original, (facets, key) => {
        if ('buckets' in facets) return facets.buckets;
        else return facets;
    });
}
