import React, { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';

const AuthPage = lazy(() => import('./AuthPage'));
const SetupPage = lazy(() => import('./SetupPage'));
const UpgradePage = lazy(() => import('./UpgradePage'));
const UpgradeSuccess = lazy(() => import('./UpgradePage/UpgradeSuccess'));
const SsoReceiver = lazy(() => import('./AuthPage/SsoReceiver'));
const GetStarted = lazy(() => import('./Onboarding'));
const HelpVideos = lazy(() => import('./Onboarding/HelpVideos'));

/**
 * Export the route objects for login and setup pages.
 * Not a nested route as these are all top-level URLs.
 * AuthPage handles its sub-routes internally.
 */

const accountRoutes: RouteObject[] = [
    {
        path: '/auth/*',
        element: <AuthPage/>
    }, {
        path: '/sso-redirect',
        element: <SsoReceiver/>
    }, {
        path: '/setup-account',
        element: <SetupPage isSetup={true} pageTitle="Setup Account"/>
    }, {
        path: '/reset-password',
        element: <SetupPage isSetup={false} pageTitle="Reset Password"/>
    }, {
        path: '/upgrade',
        element: <UpgradePage/>
    }, {
        path: '/cancel',
        element: <UpgradePage/>
    }, {
        path: '/success',
        element: <UpgradeSuccess/>
    }, {
        path: '/get-started',
        element: <GetStarted/>
    }, {
        path: '/help-videos',
        element: <HelpVideos/>
    }
];

export default accountRoutes;
