import { default as api } from '@api/emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveAnalytics: build.mutation<
      SaveAnalyticsApiResponse,
      SaveAnalyticsApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/common/analytics`,
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type SaveAnalyticsApiResponse =
  /** status 200 Successful Response */ any;
export type SaveAnalyticsApiArg = AnalyticsData;
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AnalyticsData = {
  events: string[];
  module: string;
  page?: string | null;
  parameters?: object | null;
  tag?: string | null;
  version: string;
};
export const { useSaveAnalyticsMutation } = injectedRtkApi;
