import { IsOpenHandlers, useIsOpen } from '@hooks/useIsOpen';
import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

/**
 * Global modal context allows hooks to display modals without needing to return any JSX.
 *
 * Call `setRenderFn` to automatically open the modal.
 * The render function receives arguments `open` and `onClose`
 * so that it can close and hide by itself.
 *
 * Can clear the global modal state after the transition is done
 * by passing down prop `TransitionProps` to the dialog.
 */

export interface ModalPassedProps {
    open: boolean;
    onClose: () => void;
    TransitionProps: {
        onExited: () => void;
    }
}

export type ModalRender = (props: ModalPassedProps) => JSX.Element | null;

type SetModalRender = (modal: ModalRender) => void;

type ModalContextValue = Omit<IsOpenHandlers, 'isOpen'> & {
    setModal: SetModalRender;
};

const defaultValue = () => console.error('No ModalProvider found.');

const ModalUpdateContext = createContext<ModalContextValue>({
    setModal: defaultValue,
    setIsOpen: defaultValue,
    handleClose: defaultValue,
    handleOpen: defaultValue,
    handleToggle: defaultValue
});

/**
 * Returns a function to set the global modal renderer.
 */
export const useModal = (): SetModalRender =>
    useContext(ModalUpdateContext).setModal;

/**
 * Returns the entire modal context, so that `handleClose`, `handleOpen`, etc.
 * can be called explicitly.
 */
export const useModalContext = (): ModalContextValue =>
    useContext(ModalUpdateContext);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [renderFn, setRenderFn] = useState<ModalRender>();

    const {
        isOpen,
        setIsOpen,
        handleToggle,
        handleOpen,
        handleClose
    } = useIsOpen();

    const update = useCallback((modal: ModalRender) => {
        // Note: must use a prevState callback, otherwise the render function gets treated as a callback.
        setRenderFn(() => modal);
        setTimeout(() => handleOpen(), 0);
    }, [setRenderFn, handleOpen]);

    const TransitionProps = useMemo(() => ({
        onExited: () => {
            setRenderFn(undefined);
        }
    }), [setRenderFn]);

    const value = useMemo(() => ({
        setModal: update,
        setIsOpen,
        handleToggle,
        handleOpen,
        handleClose
    }), [update, setIsOpen, handleToggle, handleOpen, handleClose]);

    return (
        <ModalUpdateContext.Provider value={value}>
            {children}
            {renderFn ? renderFn({
                open: isOpen,
                onClose: handleClose,
                TransitionProps
            }) : null}
        </ModalUpdateContext.Provider>
    );
}
