import { SENTRY_URL } from '@constants';
import * as Sentry from '@sentry/react';

const getEnvironment = (hostname: string) => {
    if (hostname === 'localhost') return 'localhost';
    // extract the subdomain 'dev' or 'integration'
    const match = hostname.match(/(.*)\.basil\.dev/i);
    return match ? match[1] : 'production';
}

// Access global variable created by webpack
// declare const MODE: string;

export default function sentryInit() {
    Sentry.init({
        dsn: SENTRY_URL,
        denyUrls: [/localhost/i, ],
        // Note: since integration uses production mode (for now), can get the mode by looking at the url.
        environment: getEnvironment(window.location.hostname),
        // Avoids sending errors from the console on localhost (with <anonymous>),
        // see: https://github.com/getsentry/sentry-javascript/issues/3045
        beforeSend: (event) => {
            if (event.environment === 'localhost') return null;
            return event;
        },
        // Note: there is a react-router integration, but it does not support routing via `useRoutes`, as of 7/14/22.
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v6
    });
}
