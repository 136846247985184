import { QueryArgFrom, ResultTypeFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { BasicQueryHook, Query } from './rtkTypes';

export const mapQueryHook = <Q extends Query>(
    useHook: UseQuery<Q>
) => <MappedArg, MappedRes>(
    mapArg: (arg: MappedArg) => QueryArgFrom<Q>,
    mapRes: (data: ResultTypeFrom<Q>, arg: QueryArgFrom<Q>) => MappedRes
): BasicQueryHook<MappedArg, MappedRes> =>
    (arg, options) => {
        const innerArg = useMemo(
            () => typeof arg === 'symbol' ? skipToken : mapArg(arg),
            [mapArg, arg]
        );
        const { data, originalArgs, ...rest } = useHook(innerArg, options);
        const mappedData = useMemo(
            // TODO: pass the mapped args?
            () => data && originalArgs ? mapRes(data, originalArgs) : undefined,
            // TODO: dependency on innerArg fixes issues with infinite tables, but feels dumb.
            [mapRes, data, originalArgs, innerArg]
        );
        return { ...rest, data: mappedData, originalArgs }
    }
