import emptyApi from '@api/emptyApi';
import type { ExportReadyResponse, ExportResponse } from './endpoints';

interface InitializeArgs {
    url: string;
    body: any;
    baseFileName: string;
}

const exportAPI = emptyApi.injectEndpoints({
    endpoints: build => ({
        initializeExport: build.mutation<ExportResponse, InitializeArgs>({
            query: ({ body, url, baseFileName }) => ({
                url,
                body,
                method: 'POST',
                params: {
                    baseFileName
                }
            })
        }),
        checkExportReady: build.query<ExportReadyResponse, string>({
            query: (filename) => ({
                url: '/search/v2/common/export/ready',
                params: {
                    filename
                }
            }),
            // Throw an error so that `isError` is `true`.
            transformResponse: (res) => {
                const data = res as ExportReadyResponse;
                if (data.error) {
                    throw new Error(data.error ?? '');
                }
                return data;
            }
        })
    })
});

export const {
    useInitializeExportMutation,
    useCheckExportReadyQuery
} = exportAPI;
