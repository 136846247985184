import { default as api } from '@api/emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExecDatasetList: build.query<
      GetExecDatasetListApiResponse,
      GetExecDatasetListApiArg
    >({
      query: () => ({ url: `/user-data/v1/exec/regulatory/dataset` }),
    }),
    addExecDataset: build.mutation<
      AddExecDatasetApiResponse,
      AddExecDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/exec/regulatory/dataset`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getExecDatasetDetail: build.query<
      GetExecDatasetDetailApiResponse,
      GetExecDatasetDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/exec/regulatory/dataset/${queryArg}`,
      }),
    }),
    updateExecDataset: build.mutation<
      UpdateExecDatasetApiResponse,
      UpdateExecDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/exec/regulatory/dataset/${queryArg._id}`,
        method: "PUT",
        body: queryArg.userExecutiveRegulatoryDataset,
      }),
    }),
    patchExecDataset: build.mutation<
      PatchExecDatasetApiResponse,
      PatchExecDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/exec/regulatory/dataset/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.executiveRegulatoryDatasetPatch,
      }),
    }),
    deleteExecDataset: build.mutation<
      DeleteExecDatasetApiResponse,
      DeleteExecDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/exec/regulatory/dataset/${queryArg}`,
        method: "DELETE",
      }),
    }),
    deleteMultiExecDataset: build.mutation<
      DeleteMultiExecDatasetApiResponse,
      DeleteMultiExecDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/exec/regulatory/dataset/delete`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getMultiDrugDatasetList: build.query<
      GetMultiDrugDatasetListApiResponse,
      GetMultiDrugDatasetListApiArg
    >({
      query: () => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/multi`,
      }),
    }),
    addMultiDrugDataset: build.mutation<
      AddMultiDrugDatasetApiResponse,
      AddMultiDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getDrugBrandDatasetList: build.query<
      GetDrugBrandDatasetListApiResponse,
      GetDrugBrandDatasetListApiArg
    >({
      query: () => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/brand`,
      }),
    }),
    addDrugBrandDataset: build.mutation<
      AddDrugBrandDatasetApiResponse,
      AddDrugBrandDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/brand`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getDrugTrialDatasetList: build.query<
      GetDrugTrialDatasetListApiResponse,
      GetDrugTrialDatasetListApiArg
    >({
      query: () => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/clinical-trial`,
      }),
    }),
    addDrugTrialDataset: build.mutation<
      AddDrugTrialDatasetApiResponse,
      AddDrugTrialDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/clinical-trial`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getGuidanceDatasetList: build.query<
      GetGuidanceDatasetListApiResponse,
      GetGuidanceDatasetListApiArg
    >({
      query: () => ({
        url: `/user-data/v1/pharma/covigilance/dataset/guidance`,
      }),
    }),
    addGuidanceDataset: build.mutation<
      AddGuidanceDatasetApiResponse,
      AddGuidanceDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/guidance`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getEuUkDrugDatasetList: build.query<
      GetEuUkDrugDatasetListApiResponse,
      GetEuUkDrugDatasetListApiArg
    >({
      query: () => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/eu-uk`,
      }),
    }),
    addEuUkDrugDataset: build.mutation<
      AddEuUkDrugDatasetApiResponse,
      AddEuUkDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/eu-uk`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getMultiDrugDatasetDetail: build.query<
      GetMultiDrugDatasetDetailApiResponse,
      GetMultiDrugDatasetDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/multi/${queryArg}`,
      }),
    }),
    updateMultiDrugDataset: build.mutation<
      UpdateMultiDrugDatasetApiResponse,
      UpdateMultiDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/multi/${queryArg._id}`,
        method: "PUT",
        body: queryArg.multiDrugDataset,
      }),
    }),
    patchMultiDrugDataset: build.mutation<
      PatchMultiDrugDatasetApiResponse,
      PatchMultiDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/multi/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.multiDrugDatasetPatch,
      }),
    }),
    deleteMultiDrugDataset: build.mutation<
      DeleteMultiDrugDatasetApiResponse,
      DeleteMultiDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/multi/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getDrugBrandDatasetDetail: build.query<
      GetDrugBrandDatasetDetailApiResponse,
      GetDrugBrandDatasetDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/brand/${queryArg}`,
      }),
    }),
    updateDrugBrandDataset: build.mutation<
      UpdateDrugBrandDatasetApiResponse,
      UpdateDrugBrandDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/brand/${queryArg._id}`,
        method: "PUT",
        body: queryArg.drugBrandDataset,
      }),
    }),
    patchDrugBrandDataset: build.mutation<
      PatchDrugBrandDatasetApiResponse,
      PatchDrugBrandDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/brand/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.drugBrandDatasetPatch,
      }),
    }),
    deleteDrugBrandDataset: build.mutation<
      DeleteDrugBrandDatasetApiResponse,
      DeleteDrugBrandDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/brand/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getDrugTrialDatasetDetail: build.query<
      GetDrugTrialDatasetDetailApiResponse,
      GetDrugTrialDatasetDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/clinical-trial/${queryArg}`,
      }),
    }),
    updateDrugTrialDataset: build.mutation<
      UpdateDrugTrialDatasetApiResponse,
      UpdateDrugTrialDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/clinical-trial/${queryArg._id}`,
        method: "PUT",
        body: queryArg.drugTrialDataset,
      }),
    }),
    patchDrugTrialDataset: build.mutation<
      PatchDrugTrialDatasetApiResponse,
      PatchDrugTrialDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/clinical-trial/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.drugTrialDatasetPatch,
      }),
    }),
    deleteDrugTrialDataset: build.mutation<
      DeleteDrugTrialDatasetApiResponse,
      DeleteDrugTrialDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/clinical-trial/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getGuidanceDatasetDetail: build.query<
      GetGuidanceDatasetDetailApiResponse,
      GetGuidanceDatasetDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/guidance/${queryArg}`,
      }),
    }),
    updateGuidanceDataset: build.mutation<
      UpdateGuidanceDatasetApiResponse,
      UpdateGuidanceDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/guidance/${queryArg._id}`,
        method: "PUT",
        body: queryArg.guidanceDataset,
      }),
    }),
    patchGuidanceDataset: build.mutation<
      PatchGuidanceDatasetApiResponse,
      PatchGuidanceDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/guidance/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.guidanceDatasetPatch,
      }),
    }),
    deleteGuidanceDataset: build.mutation<
      DeleteGuidanceDatasetApiResponse,
      DeleteGuidanceDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/guidance/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getEuUkDrugDatasetDetail: build.query<
      GetEuUkDrugDatasetDetailApiResponse,
      GetEuUkDrugDatasetDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/eu-uk/${queryArg}`,
      }),
    }),
    updateEuUkDrugDataset: build.mutation<
      UpdateEuUkDrugDatasetApiResponse,
      UpdateEuUkDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/eu-uk/${queryArg._id}`,
        method: "PUT",
        body: queryArg.euukDrugDataset,
      }),
    }),
    patchEuUkDrugDataset: build.mutation<
      PatchEuUkDrugDatasetApiResponse,
      PatchEuUkDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/eu-uk/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.euukDrugDatasetPatch,
      }),
    }),
    deleteEuUkDrugDataset: build.mutation<
      DeleteEuUkDrugDatasetApiResponse,
      DeleteEuUkDrugDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pharma/covigilance/dataset/drug/eu-uk/${queryArg}`,
        method: "DELETE",
      }),
    }),
    userGetPmiDatasetList: build.query<
      UserGetPmiDatasetListApiResponse,
      UserGetPmiDatasetListApiArg
    >({
      query: () => ({ url: `/user-data/v1/pmi/dataset` }),
    }),
    addPmiDataset: build.mutation<
      AddPmiDatasetApiResponse,
      AddPmiDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/dataset`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getPmiDatasetDetail: build.query<
      GetPmiDatasetDetailApiResponse,
      GetPmiDatasetDetailApiArg
    >({
      query: (queryArg) => ({ url: `/user-data/v1/pmi/dataset/${queryArg}` }),
    }),
    updatePmiDataset: build.mutation<
      UpdatePmiDatasetApiResponse,
      UpdatePmiDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/dataset/${queryArg._id}`,
        method: "PUT",
        body: queryArg.pmiDataset,
      }),
    }),
    patchPmiDataset: build.mutation<
      PatchPmiDatasetApiResponse,
      PatchPmiDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/dataset/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.pmiDatasetPatch,
      }),
    }),
    deletePmiDataset: build.mutation<
      DeletePmiDatasetApiResponse,
      DeletePmiDatasetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/dataset/${queryArg}`,
        method: "DELETE",
      }),
    }),
    pmiGetMatrixList: build.query<
      PmiGetMatrixListApiResponse,
      PmiGetMatrixListApiArg
    >({
      query: () => ({ url: `/user-data/v1/pmi/matrix` }),
    }),
    pmiAddMatrix: build.mutation<PmiAddMatrixApiResponse, PmiAddMatrixApiArg>({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/matrix`,
        method: "POST",
        body: queryArg,
      }),
    }),
    pmiGetMatrixDetail: build.query<
      PmiGetMatrixDetailApiResponse,
      PmiGetMatrixDetailApiArg
    >({
      query: (queryArg) => ({ url: `/user-data/v1/pmi/matrix/${queryArg}` }),
    }),
    pmiUpdateMatrix: build.mutation<
      PmiUpdateMatrixApiResponse,
      PmiUpdateMatrixApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/matrix/${queryArg._id}`,
        method: "PUT",
        body: queryArg.matrix,
      }),
    }),
    pmiPatchMatrix: build.mutation<
      PmiPatchMatrixApiResponse,
      PmiPatchMatrixApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/matrix/${queryArg._id}`,
        method: "PATCH",
        body: queryArg.matrixPatch,
      }),
    }),
    pmiDeleteMatrix: build.mutation<
      PmiDeleteMatrixApiResponse,
      PmiDeleteMatrixApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/pmi/matrix/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getSavedData: build.query<GetSavedDataApiResponse, GetSavedDataApiArg>({
      query: () => ({ url: `/user-data/v1/user/saved/all` }),
    }),
    getSavedDataForDashboard: build.query<
      GetSavedDataForDashboardApiResponse,
      GetSavedDataForDashboardApiArg
    >({
      query: () => ({ url: `/user-data/v1/user/saved/dashboard` }),
    }),
    getSavedItemList: build.query<
      GetSavedItemListApiResponse,
      GetSavedItemListApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/user/saved-item`,
        params: { itemType: queryArg },
      }),
    }),
    addSavedItem: build.mutation<AddSavedItemApiResponse, AddSavedItemApiArg>({
      query: (queryArg) => ({
        url: `/user-data/v1/user/saved-item`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getSavedItemDetail: build.query<
      GetSavedItemDetailApiResponse,
      GetSavedItemDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/user/saved-item/${queryArg}`,
      }),
    }),
    updateSavedItem: build.mutation<
      UpdateSavedItemApiResponse,
      UpdateSavedItemApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/user/saved-item/${queryArg._id}`,
        method: "PUT",
        body: queryArg.savedItem,
      }),
    }),
    deleteSavedItem: build.mutation<
      DeleteSavedItemApiResponse,
      DeleteSavedItemApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/user/saved-item/${queryArg}`,
        method: "DELETE",
      }),
    }),
    markSavedItemAsViewed: build.query<
      MarkSavedItemAsViewedApiResponse,
      MarkSavedItemAsViewedApiArg
    >({
      query: (queryArg) => ({
        url: `/user-data/v1/user/saved-item/${queryArg}/viewed`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetExecDatasetListApiResponse =
  /** status 200 Successful Response */ ExecutiveRegulatoryDatasetListResponse;
export type GetExecDatasetListApiArg = void;
export type AddExecDatasetApiResponse =
  /** status 200 Successful Response */ ExecutiveRegulatoryDatasetResponse;
export type AddExecDatasetApiArg = ExecutiveRegulatoryDataset;
export type GetExecDatasetDetailApiResponse =
  /** status 200 Successful Response */ ExecutiveRegulatoryDatasetResponse;
export type GetExecDatasetDetailApiArg = string;
export type UpdateExecDatasetApiResponse =
  /** status 200 Successful Response */ ExecutiveRegulatoryDatasetResponse;
export type UpdateExecDatasetApiArg = {
  _id: string;
  userExecutiveRegulatoryDataset: ExecutiveRegulatoryDataset;
};
export type PatchExecDatasetApiResponse =
  /** status 200 Successful Response */ ExecutiveRegulatoryDatasetResponse;
export type PatchExecDatasetApiArg = {
  _id: string;
  executiveRegulatoryDatasetPatch: ExecutiveRegulatoryDatasetPatch;
};
export type DeleteExecDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteExecDatasetApiArg = string;
export type DeleteMultiExecDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteMultiExecDatasetApiArg =
  BodyDeleteMultiExecutiveRegulatoryDatasetExecRegulatoryDatasetDeletePost;
export type GetMultiDrugDatasetListApiResponse =
  /** status 200 Successful Response */ MultiDrugDatasetListResponse;
export type GetMultiDrugDatasetListApiArg = void;
export type AddMultiDrugDatasetApiResponse =
  /** status 200 Successful Response */ MultiDrugDatasetResponse;
export type AddMultiDrugDatasetApiArg = MultiDrugDataset;
export type GetDrugBrandDatasetListApiResponse =
  /** status 200 Successful Response */ DrugBrandDatasetListResponse;
export type GetDrugBrandDatasetListApiArg = void;
export type AddDrugBrandDatasetApiResponse =
  /** status 200 Successful Response */ DrugBrandDatasetResponse;
export type AddDrugBrandDatasetApiArg = DrugBrandDataset;
export type GetDrugTrialDatasetListApiResponse =
  /** status 200 Successful Response */ DrugTrialDatasetListResponse;
export type GetDrugTrialDatasetListApiArg = void;
export type AddDrugTrialDatasetApiResponse =
  /** status 200 Successful Response */ DrugTrialDatasetResponse;
export type AddDrugTrialDatasetApiArg = DrugTrialDataset;
export type GetGuidanceDatasetListApiResponse =
  /** status 200 Successful Response */ GuidanceDatasetListResponse;
export type GetGuidanceDatasetListApiArg = void;
export type AddGuidanceDatasetApiResponse =
  /** status 200 Successful Response */ GuidanceDatasetResponse;
export type AddGuidanceDatasetApiArg = GuidanceDataset;
export type GetEuUkDrugDatasetListApiResponse =
  /** status 200 Successful Response */ EuukDrugDatasetListResponse;
export type GetEuUkDrugDatasetListApiArg = void;
export type AddEuUkDrugDatasetApiResponse =
  /** status 200 Successful Response */ EuukDrugDatasetResponse;
export type AddEuUkDrugDatasetApiArg = EuukDrugDataset;
export type GetMultiDrugDatasetDetailApiResponse =
  /** status 200 Successful Response */ MultiDrugDatasetResponse;
export type GetMultiDrugDatasetDetailApiArg = string;
export type UpdateMultiDrugDatasetApiResponse =
  /** status 200 Successful Response */ MultiDrugDatasetResponse;
export type UpdateMultiDrugDatasetApiArg = {
  _id: string;
  multiDrugDataset: MultiDrugDataset;
};
export type PatchMultiDrugDatasetApiResponse =
  /** status 200 Successful Response */ MultiDrugDatasetResponse;
export type PatchMultiDrugDatasetApiArg = {
  _id: string;
  multiDrugDatasetPatch: MultiDrugDatasetPatch;
};
export type DeleteMultiDrugDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteMultiDrugDatasetApiArg = string;
export type GetDrugBrandDatasetDetailApiResponse =
  /** status 200 Successful Response */ DrugBrandDatasetResponse;
export type GetDrugBrandDatasetDetailApiArg = string;
export type UpdateDrugBrandDatasetApiResponse =
  /** status 200 Successful Response */ DrugBrandDatasetResponse;
export type UpdateDrugBrandDatasetApiArg = {
  _id: string;
  drugBrandDataset: DrugBrandDataset;
};
export type PatchDrugBrandDatasetApiResponse =
  /** status 200 Successful Response */ DrugBrandDatasetResponse;
export type PatchDrugBrandDatasetApiArg = {
  _id: string;
  drugBrandDatasetPatch: DrugBrandDatasetPatch;
};
export type DeleteDrugBrandDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteDrugBrandDatasetApiArg = string;
export type GetDrugTrialDatasetDetailApiResponse =
  /** status 200 Successful Response */ DrugTrialDatasetResponse;
export type GetDrugTrialDatasetDetailApiArg = string;
export type UpdateDrugTrialDatasetApiResponse =
  /** status 200 Successful Response */ DrugTrialDatasetResponse;
export type UpdateDrugTrialDatasetApiArg = {
  _id: string;
  drugTrialDataset: DrugTrialDataset;
};
export type PatchDrugTrialDatasetApiResponse =
  /** status 200 Successful Response */ DrugTrialDatasetResponse;
export type PatchDrugTrialDatasetApiArg = {
  _id: string;
  drugTrialDatasetPatch: DrugTrialDatasetPatch;
};
export type DeleteDrugTrialDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteDrugTrialDatasetApiArg = string;
export type GetGuidanceDatasetDetailApiResponse =
  /** status 200 Successful Response */ GuidanceDatasetResponse;
export type GetGuidanceDatasetDetailApiArg = string;
export type UpdateGuidanceDatasetApiResponse =
  /** status 200 Successful Response */ GuidanceDatasetResponse;
export type UpdateGuidanceDatasetApiArg = {
  _id: string;
  guidanceDataset: GuidanceDataset;
};
export type PatchGuidanceDatasetApiResponse =
  /** status 200 Successful Response */ GuidanceDatasetResponse;
export type PatchGuidanceDatasetApiArg = {
  _id: string;
  guidanceDatasetPatch: GuidanceDatasetPatch;
};
export type DeleteGuidanceDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteGuidanceDatasetApiArg = string;
export type GetEuUkDrugDatasetDetailApiResponse =
  /** status 200 Successful Response */ EuukDrugDatasetResponse;
export type GetEuUkDrugDatasetDetailApiArg = string;
export type UpdateEuUkDrugDatasetApiResponse =
  /** status 200 Successful Response */ EuukDrugDatasetResponse;
export type UpdateEuUkDrugDatasetApiArg = {
  _id: string;
  euukDrugDataset: EuukDrugDataset;
};
export type PatchEuUkDrugDatasetApiResponse =
  /** status 200 Successful Response */ EuukDrugDatasetResponse;
export type PatchEuUkDrugDatasetApiArg = {
  _id: string;
  euukDrugDatasetPatch: EuukDrugDatasetPatch;
};
export type DeleteEuUkDrugDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteEuUkDrugDatasetApiArg = string;
export type UserGetPmiDatasetListApiResponse =
  /** status 200 Successful Response */ PmiDatasetListResponse;
export type UserGetPmiDatasetListApiArg = void;
export type AddPmiDatasetApiResponse =
  /** status 200 Successful Response */ PmiDatasetResponse;
export type AddPmiDatasetApiArg = PmiDataset;
export type GetPmiDatasetDetailApiResponse =
  /** status 200 Successful Response */ PmiDatasetResponse;
export type GetPmiDatasetDetailApiArg = string;
export type UpdatePmiDatasetApiResponse =
  /** status 200 Successful Response */ PmiDatasetResponse;
export type UpdatePmiDatasetApiArg = {
  _id: string;
  pmiDataset: PmiDataset;
};
export type PatchPmiDatasetApiResponse =
  /** status 200 Successful Response */ PmiDatasetResponse;
export type PatchPmiDatasetApiArg = {
  _id: string;
  pmiDatasetPatch: PmiDatasetPatch;
};
export type DeletePmiDatasetApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeletePmiDatasetApiArg = string;
export type PmiGetMatrixListApiResponse =
  /** status 200 Successful Response */ MatrixListResponse;
export type PmiGetMatrixListApiArg = void;
export type PmiAddMatrixApiResponse =
  /** status 200 Successful Response */ MatrixInDb;
export type PmiAddMatrixApiArg = Matrix;
export type PmiGetMatrixDetailApiResponse =
  /** status 200 Successful Response */ MatrixInDb;
export type PmiGetMatrixDetailApiArg = string;
export type PmiUpdateMatrixApiResponse =
  /** status 200 Successful Response */ MatrixInDb;
export type PmiUpdateMatrixApiArg = {
  _id: string;
  matrix: Matrix;
};
export type PmiPatchMatrixApiResponse =
  /** status 200 Successful Response */ MatrixInDb;
export type PmiPatchMatrixApiArg = {
  _id: string;
  matrixPatch: MatrixPatch;
};
export type PmiDeleteMatrixApiResponse =
  /** status 204 Successful Response */ undefined;
export type PmiDeleteMatrixApiArg = string;
export type GetSavedDataApiResponse =
  /** status 200 Successful Response */ SavedDataResponse;
export type GetSavedDataApiArg = void;
export type GetSavedDataForDashboardApiResponse =
  /** status 200 Successful Response */ SavedDataResponse;
export type GetSavedDataForDashboardApiArg = void;
export type GetSavedItemListApiResponse =
  /** status 200 Successful Response */ SavedItemListResponse;
export type GetSavedItemListApiArg = string | null;
export type AddSavedItemApiResponse =
  /** status 200 Successful Response */ SavedItemResponse;
export type AddSavedItemApiArg = SavedItem;
export type GetSavedItemDetailApiResponse =
  /** status 200 Successful Response */ SavedItemResponse;
export type GetSavedItemDetailApiArg = string;
export type UpdateSavedItemApiResponse =
  /** status 200 Successful Response */ SavedItemResponse;
export type UpdateSavedItemApiArg = {
  _id: string;
  savedItem: SavedItem;
};
export type DeleteSavedItemApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteSavedItemApiArg = string;
export type MarkSavedItemAsViewedApiResponse =
  /** status 200 Successful Response */ SavedItemResponse;
export type MarkSavedItemAsViewedApiArg = string;
export type ExecutiveRegulatoryDatasetTypeEnum =
  | "PRODUCT_CODES"
  | "MANUFACTURERS";
export type ExecutiveRegulatoryDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: ExecutiveRegulatoryDatasetTypeEnum;
  enableExecutiveRegulatoryAlert: boolean;
};
export type ExecutiveRegulatoryDatasetListResponse = {
  datasets: ExecutiveRegulatoryDatasetSummary[];
};
export type FamilyTypeEnum = "ESTABLISHMENT" | "OTHER" | "OWNER";
export type FamilyCompany = {
  key: string;
  name: string;
  companies?: string[] | null;
  familyType?: FamilyTypeEnum | null;
};
export type ExecutiveRegulatoryDatasetInDb = {
  name: string;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  datasetType: ExecutiveRegulatoryDatasetTypeEnum;
  productCodes?: string[] | null;
  enableExecutiveRegulatoryAlert?: boolean;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
  hasFilters?: boolean;
};
export type ExecutiveRegulatoryDatasetResponse = {
  dataset: ExecutiveRegulatoryDatasetInDb;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ExecutiveRegulatoryDataset = {
  name: string;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  datasetType: ExecutiveRegulatoryDatasetTypeEnum;
  productCodes?: string[] | null;
  enableExecutiveRegulatoryAlert?: boolean;
};
export type ExecutiveRegulatoryDatasetPatch = {
  name?: string | null;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  datasetType?: ExecutiveRegulatoryDatasetTypeEnum | null;
  productCodes?: string[] | null;
  enableExecutiveRegulatoryAlert?: boolean | null;
  dateLastViewed?: string | null;
};
export type BodyDeleteMultiExecutiveRegulatoryDatasetExecRegulatoryDatasetDeletePost =
  {
    datasetIds: string[];
  };
export type MultiDrugDatasetTypeEnum = "MULTI_DRUG";
export type MultiDrugDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: MultiDrugDatasetTypeEnum;
  defaultView?: string | null;
  enableAlert: boolean;
};
export type MultiDrugDatasetListResponse = {
  datasets: MultiDrugDatasetSummary[];
};
export type MultiDrugDatasetFilter = {
  field: string;
  values: string[];
  operator?: string | null;
  labelOnly?: boolean | null;
};
export type MultiDrugDatasetInDb = {
  datasetType?: MultiDrugDatasetTypeEnum;
  defaultView?: string | null;
  enableAlert?: boolean;
  filters?: MultiDrugDatasetFilter[] | null;
  name: string;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
  hasFilters?: boolean;
};
export type MultiDrugDatasetResponse = {
  dataset: MultiDrugDatasetInDb;
};
export type MultiDrugDataset = {
  datasetType?: MultiDrugDatasetTypeEnum;
  defaultView?: string | null;
  enableAlert?: boolean;
  filters?: MultiDrugDatasetFilter[] | null;
  name: string;
};
export type DrugBrandDatasetTypeEnum = "DRUG_BRAND";
export type DrugBrandDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: DrugBrandDatasetTypeEnum;
  enableAlert: boolean;
};
export type DrugBrandDatasetListResponse = {
  datasets: DrugBrandDatasetSummary[];
};
export type DrugBrandDatasetInDb = {
  brandName: string;
  datasetType?: DrugBrandDatasetTypeEnum;
  enableAlert?: boolean;
  name: string;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
  hasFilters?: boolean;
};
export type DrugBrandDatasetResponse = {
  dataset: DrugBrandDatasetInDb;
};
export type DrugBrandDataset = {
  brandName: string;
  datasetType?: DrugBrandDatasetTypeEnum;
  enableAlert?: boolean;
  name: string;
};
export type DrugTrialDatasetTypeEnum = "DRUG_TRIAL";
export type DrugTrialDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: DrugTrialDatasetTypeEnum;
  enableAlert: boolean;
};
export type DrugTrialDatasetListResponse = {
  datasets: DrugTrialDatasetSummary[];
};
export type DrugTrialDatasetQuery = {
  query: string;
  filters?: object[] | null;
  within?: string[] | null;
  labelOnly?: boolean | null;
};
export type DrugTrialDatasetInDb = {
  datasetQueries: DrugTrialDatasetQuery[];
  datasetType?: DrugTrialDatasetTypeEnum;
  enableAlert?: boolean;
  name: string;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
};
export type DrugTrialDatasetResponse = {
  dataset: DrugTrialDatasetInDb;
};
export type DrugTrialDataset = {
  datasetQueries: DrugTrialDatasetQuery[];
  datasetType?: DrugTrialDatasetTypeEnum;
  enableAlert?: boolean;
  name: string;
};
export type GuidanceDatasetTypeEnum = "GUIDANCE";
export type GuidanceDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: GuidanceDatasetTypeEnum;
  enableAlert: boolean;
};
export type GuidanceDatasetListResponse = {
  datasets: GuidanceDatasetSummary[];
};
export type DatasetQuery = {
  query: string;
  filters?: object[] | null;
};
export type GuidanceDatasetInDb = {
  datasetQueries: DatasetQuery[];
  datasetType?: GuidanceDatasetTypeEnum;
  enableAlert?: boolean;
  name: string;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
};
export type GuidanceDatasetResponse = {
  dataset: GuidanceDatasetInDb;
};
export type GuidanceDataset = {
  datasetQueries: DatasetQuery[];
  datasetType?: GuidanceDatasetTypeEnum;
  enableAlert?: boolean;
  name: string;
};
export type EuukDrugDatasetTypeEnum = "EU_UK_DRUG";
export type EuukDrugDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: EuukDrugDatasetTypeEnum;
  defaultView?: string | null;
  enableAlert: boolean;
};
export type EuukDrugDatasetListResponse = {
  datasets: EuukDrugDatasetSummary[];
};
export type EuukDrugDatasetFilter = {
  field: string;
  values: string[];
  operator?: string | null;
  labelOnly?: boolean | null;
};
export type EuukDrugDatasetInDb = {
  datasetType?: EuukDrugDatasetTypeEnum;
  defaultView?: string | null;
  enableAlert?: boolean;
  filters?: EuukDrugDatasetFilter[] | null;
  name: string;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
  hasFilters?: boolean;
};
export type EuukDrugDatasetResponse = {
  dataset: EuukDrugDatasetInDb;
};
export type EuukDrugDataset = {
  datasetType?: EuukDrugDatasetTypeEnum;
  defaultView?: string | null;
  enableAlert?: boolean;
  filters?: EuukDrugDatasetFilter[] | null;
  name: string;
};
export type MultiDrugDatasetPatch = {
  datasetType?: MultiDrugDatasetTypeEnum | null;
  defaultView?: string | null;
  enableAlert?: boolean | null;
  filters?: MultiDrugDatasetFilter[] | null;
  name?: string | null;
  dateLastViewed?: string | null;
};
export type DrugBrandDatasetPatch = {
  brandName?: string | null;
  datasetType?: DrugBrandDatasetTypeEnum | null;
  enableAlert?: boolean | null;
  name?: string | null;
  dateLastViewed?: string | null;
};
export type DrugTrialDatasetPatch = {
  datasetQueries?: DatasetQuery[] | null;
  datasetType?: DrugTrialDatasetTypeEnum;
  enableAlert?: boolean | null;
  name?: string | null;
  dateLastViewed?: string | null;
};
export type GuidanceDatasetPatch = {
  datasetQueries?: DatasetQuery[] | null;
  datasetType?: GuidanceDatasetTypeEnum;
  enableAlert?: boolean | null;
  name?: string | null;
  dateLastViewed?: string | null;
};
export type EuukDrugDatasetPatch = {
  datasetType?: EuukDrugDatasetTypeEnum | null;
  defaultView?: string | null;
  enableAlert?: boolean | null;
  filters?: EuukDrugDatasetFilter[] | null;
  name?: string | null;
  dateLastViewed?: string | null;
};
export type PmiDatasetTypeEnum =
  | "BRANDS"
  | "CATEGORIES"
  | "COMPANIES"
  | "REVIEW_PANEL";
export type PmiDatasetSummary = {
  _id?: string;
  dashboard?: boolean | null;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  hasFilters?: boolean;
  name: string;
  datasetType: PmiDatasetTypeEnum;
};
export type PmiDatasetListResponse = {
  datasets: PmiDatasetSummary[];
};
export type Brand = {
  query: string;
  adverseEventBrands?: string[] | null;
  recallBrands?: string[] | null;
};
export type FamilyBrand = {
  key: string;
  name: string;
  brands?: string[] | null;
};
export type PmiDatasetInDb = {
  name: string;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  brands?: Brand[] | null;
  dashboard?: boolean | null;
  datasetType: PmiDatasetTypeEnum;
  familyBrands?: FamilyBrand[] | null;
  filters?: object[] | null;
  gmdns?: string[] | null;
  productCodes?: string[] | null;
  reviewPanels?: string[] | null;
  within?: string | null;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
  hasFilters?: boolean;
};
export type PmiDatasetResponse = {
  dataset: PmiDatasetInDb;
};
export type PmiDataset = {
  name: string;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  brands?: Brand[] | null;
  dashboard?: boolean | null;
  datasetType: PmiDatasetTypeEnum;
  familyBrands?: FamilyBrand[] | null;
  filters?: object[] | null;
  gmdns?: string[] | null;
  productCodes?: string[] | null;
  reviewPanels?: string[] | null;
  within?: string | null;
};
export type PmiDatasetPatch = {
  name?: string | null;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  brands?: Brand[] | null;
  dashboard?: boolean | null;
  datasetType?: PmiDatasetTypeEnum | null;
  familyBrands?: FamilyBrand[] | null;
  filters?: object[] | null;
  gmdns?: string[] | null;
  productCodes?: string[] | null;
  reviewPanels?: string[] | null;
  within?: string | null;
  dateLastViewed?: string | null;
};
export type MatrixSummary = {
  _id?: string;
  dateCreated: string;
  dateLastViewed?: string | null;
  dateModified: string;
  name: string;
};
export type MatrixListResponse = {
  matrixes: MatrixSummary[];
};
export type MatrixColumn = {
  brandSearch: string;
  companySearch: string;
  heading: string;
  key: string;
};
export type DateRange = {
  start: string;
  end: string;
};
export type MatrixInDb = {
  name: string;
  columns: MatrixColumn[];
  dateRange: DateRange;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
};
export type Matrix = {
  name: string;
  columns: MatrixColumn[];
  dateRange: DateRange;
};
export type MatrixPatch = {
  name?: string | null;
  columns?: MatrixColumn[] | null;
  dateRange?: DateRange | null;
};
export type DeviceItem = {
  docType: string;
  itemId: string;
  title?: string | null;
};
export type SavedDataResponse = {
  datasets: object[];
  savedItems: DeviceItem[];
};
export type SavedItemTypeEnum =
  | "DEVICE_SEARCH"
  | "DEVICE_ITEM"
  | "REGULATORY_REVIEW_PANEL";
export type Filter = {
  field: string;
  value?: string[] | null;
  end?: string | null;
  start?: string | null;
};
export type DeviceSearch = {
  docType: string;
  query: string;
  filters?: Filter[] | null;
};
export type ReviewPanelItem = {
  reviewPanel: string;
  title?: string | null;
};
export type SavedItemInDb = {
  itemType: SavedItemTypeEnum;
  item: DeviceSearch | DeviceItem | ReviewPanelItem;
  name?: string | null;
  _id?: string;
  ownerId: string;
  saveDate: string;
  lastViewed: string;
  lastModified: string;
  title?: string | null;
};
export type SavedItemListResponse = {
  success: boolean;
  items: SavedItemInDb[];
};
export type SavedItemResponse = {
  success: boolean;
  message?: string | null;
  item: SavedItemInDb;
};
export type SavedItem = {
  itemType: SavedItemTypeEnum;
  item: DeviceSearch | DeviceItem | ReviewPanelItem;
  name?: string | null;
};
export const {
  useGetExecDatasetListQuery,
  useAddExecDatasetMutation,
  useGetExecDatasetDetailQuery,
  useUpdateExecDatasetMutation,
  usePatchExecDatasetMutation,
  useDeleteExecDatasetMutation,
  useDeleteMultiExecDatasetMutation,
  useGetMultiDrugDatasetListQuery,
  useAddMultiDrugDatasetMutation,
  useGetDrugBrandDatasetListQuery,
  useAddDrugBrandDatasetMutation,
  useGetDrugTrialDatasetListQuery,
  useAddDrugTrialDatasetMutation,
  useGetGuidanceDatasetListQuery,
  useAddGuidanceDatasetMutation,
  useGetEuUkDrugDatasetListQuery,
  useAddEuUkDrugDatasetMutation,
  useGetMultiDrugDatasetDetailQuery,
  useUpdateMultiDrugDatasetMutation,
  usePatchMultiDrugDatasetMutation,
  useDeleteMultiDrugDatasetMutation,
  useGetDrugBrandDatasetDetailQuery,
  useUpdateDrugBrandDatasetMutation,
  usePatchDrugBrandDatasetMutation,
  useDeleteDrugBrandDatasetMutation,
  useGetDrugTrialDatasetDetailQuery,
  useUpdateDrugTrialDatasetMutation,
  usePatchDrugTrialDatasetMutation,
  useDeleteDrugTrialDatasetMutation,
  useGetGuidanceDatasetDetailQuery,
  useUpdateGuidanceDatasetMutation,
  usePatchGuidanceDatasetMutation,
  useDeleteGuidanceDatasetMutation,
  useGetEuUkDrugDatasetDetailQuery,
  useUpdateEuUkDrugDatasetMutation,
  usePatchEuUkDrugDatasetMutation,
  useDeleteEuUkDrugDatasetMutation,
  useUserGetPmiDatasetListQuery,
  useAddPmiDatasetMutation,
  useGetPmiDatasetDetailQuery,
  useUpdatePmiDatasetMutation,
  usePatchPmiDatasetMutation,
  useDeletePmiDatasetMutation,
  usePmiGetMatrixListQuery,
  usePmiAddMatrixMutation,
  usePmiGetMatrixDetailQuery,
  usePmiUpdateMatrixMutation,
  usePmiPatchMatrixMutation,
  usePmiDeleteMatrixMutation,
  useGetSavedDataQuery,
  useGetSavedDataForDashboardQuery,
  useGetSavedItemListQuery,
  useAddSavedItemMutation,
  useGetSavedItemDetailQuery,
  useUpdateSavedItemMutation,
  useDeleteSavedItemMutation,
  useMarkSavedItemAsViewedQuery,
} = injectedRtkApi;
