import { FaersField } from '@api/enums';
import type { CategoryDatum } from '@charts';
import { makeArray } from '@helpers/arrayUtils';
import type { OpenedTable } from '../data/pharmaDemoReducer';
import { ChartType, meddraPathToFilters } from './util';

export const tableStateForRow = (
    datum: CategoryDatum, 
    meddraPath: string[],
    chartType: ChartType
): OpenedTable => {
    const expectedCount = datum.count;
    if (chartType === ChartType.Outcomes) {
        return {
            type: 'faers',
            filters: [{
                field: FaersField.reactionOutcome,
                values: makeArray(datum.key)
            }],
            expectedCount,
            breadcrumbs: [datum.label]
        };
    } else if (chartType === ChartType.Seriousness) {
        return {
            type: 'faers',
            filters: [{
                field: FaersField.seriousness,
                values: makeArray(datum.key)
            }],
            expectedCount,
            breadcrumbs: [datum.label]
        };
    } else {
        const breadcrumbs = meddraPath.concat(datum.key);
        const filters = meddraPathToFilters(breadcrumbs);
        return {
            type: chartType === ChartType.ClinicalTrial ? 'clinicalTrial' : 'faers',
            filters,
            expectedCount,
            breadcrumbs
        };
    }
}
