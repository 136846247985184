/**
 * Explode comma-separate search terms into an OR query.
 * Wrap the query in (), but avoid double (()) if the () is already present.
 * Note: does not support the use of commas inside of parentheses.
 */
export const explodeSearch = (query: string = ''): string => {
    if (!query) return '';
    const withOr = query.replaceAll(/,\s*/g, ' OR ').trim();
    if ((withOr.startsWith('(') && !withOr.endsWith(')')) || !withOr.toUpperCase().includes(' OR ')) return withOr;
    return `(${withOr})`;
}
