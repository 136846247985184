import { AnalyticsEvents, GA4Handler, HasGtag } from '@features/ga4/GA4';
import { useMemo } from 'react';
import { useCurrentModule } from '../../Module/CurrentModuleContext';
import { useSaveAnalyticsMutation } from './api';

/**
 * Send events to Google Analytics but also to the Basil analytics API
 *
 * Note: missing trial_expired flag from the baseConfig.
 */
export const useCombinedAnalytics = (): AnalyticsEvents => {

    const [callApi] = useSaveAnalyticsMutation();

    const module = useCurrentModule();

    return useMemo(() => {
        const handler: HasGtag = {
            gtag(name: Gtag.EventNames | string, params?: Record<string, string>) {
                GA4Handler.gtag(name, params);
                callApi({
                    events: [name],
                    // Note: module cannot be empty.
                    module: module ?? 'COMMON',
                    // Pull out to a top-level property
                    page: params?.page_location,
                    parameters: params,
                    version: 'v1'
                });
            }
        };
        return new AnalyticsEvents(handler);
    }, [callApi, module]);
}
