import type { FilterOptionalFields as Optional } from '@helpers/filterEnums';
import { typedEntries } from '@helpers/types';
import type { RangeSelections } from '../selectionStateReducer';

/**
 * Minimal interface avoids additional properties which could cause TS errors.
 */
export interface PickedFilter<E> extends Optional {
    field: E;
    values: string[];
}

/**
 * Pull the filters from PmiFacetSelections which match the provided field enum.
 */
export const pickValidFilters = <E extends string>(mapping: Record<string, E>) =>
    (selections: Record<string, string[]>, optional?: Optional): PickedFilter<E>[] =>
        typedEntries(mapping)
            .map<PickedFilter<E>>(([key, field]) => ({
                field,
                // TODO: remove after backend issues are fixed.
                values: selections[key]?.flatMap(v => v === '' || v === 'NULL' ? ['', 'NULL'] : v) || [],
                ...optional
            }))
            .filter(o => o.values?.length)

/**
 * Minimal interface avoids additional properties which could cause TS errors.
 * Always has both `start` and `end`, though the values may be null.
 * Casts values to strings.
 */
export interface PickedRangeFilter<E> extends Optional {
    field: E;
    start: string | null;
    end: string | null;
}

export const pickValidRanges = <E extends string>(mapping: Record<string, E>) =>
    (selections: RangeSelections, optional?: Optional): PickedRangeFilter<E>[] =>
        typedEntries(mapping)
            .map<PickedRangeFilter<E>>(([key, field]) => ({
                field,
                start: selections[key]?.start?.toString() ?? null,
                end: selections[key]?.end?.toString() ?? null,
                ...optional
            }))
            .filter(o => o.start || o.end)
