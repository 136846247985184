import type { TotalResults } from '@api';
import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import type { InfinitePageResponse } from './types';

export const transformPaginatedResponse =
    <T, R extends { totalResults?: object }>(
        mapResToItems: (result: R) => T[],
        mapResToExtra?: (result: R) => any
    ) =>
        (result: R, meta: FetchBaseQueryMeta, arg: { pageNumber?: number }): InfinitePageResponse<T> => ({
            items: mapResToItems(result),
            totalResults: result.totalResults as TotalResults,
            pageNumber: arg.pageNumber || 1,
            extra: mapResToExtra?.(result)
            // offset: ((arg.pageNumber ?? 1) - 1) * (arg.pageSize ?? AE_PAGE_SIZE),
        });
