import { sentryWarn } from '@features/errors';
import { createIsValidEnum, keyToEnum } from '@helpers/filterEnums';
import { titleCase } from '@helpers/textUtils';
import { keyBy } from 'lodash';
import type { CamelCase } from 'type-fest';

export interface CountryFieldUtil<Field extends string> {
    isValidField: (value: string) => value is Field;
    fieldEnum: Record<string, Field>;
    fieldOptions: Field[];
    formatField: (field: Field | string) => string;
    formatFieldForSubtitle: (field: Field | string) => string;
    getSectionTitle: (key: CamelCase<Field> | string) => string;
    historySections: Array<{ property: string; label: string }>;
    getHistoryLabel: (key: CamelCase<Field> | string) => string;
}

export default function createCountryFieldUtil<Field extends string>(
    fieldEnum: Record<string, Field>,
    fieldOptions: Field[], // TODO: support nesting
    titleOverrides: Partial<Record<Field, string>> = {},
    historySections: [string, string][] = []
): CountryFieldUtil<Field> {
    /**
     * Store mappings, both from SNAKE_CASE and camelCase to title.
     */
    const knownKeyMapping: Record<string, string> = { ...titleOverrides };
    /**
     * Map from the snake case enum to the title.
     * Will use any provided overrides, or default to title-case.
     */
    const formatField = (field: Field | string): string => {
        const known = knownKeyMapping[field];
        if (known) return known;
        const title = titleCase(field);
        knownKeyMapping[field] = title;
        return title;
    }
    /**
     * Use a different text for the "all" case.
     */
    const formatFieldForSubtitle = (field: Field | string): string => {
        if (field === 'LABEL' || !field) {
            return 'All Sections';
        }
        return formatField(field);
    }
    /**
     * Map from the camel-cased property in the highlights table.
     */
    const getSectionTitle = (key: CamelCase<Field> | string): string => {
        if (knownKeyMapping[key]) return knownKeyMapping[key];
        const upper = fieldEnum[key];
        if (!upper) {
            sentryWarn(`Encountered unknown field ${key}`);
        }
        // Note: will format and store even if unknown.
        const label = formatField(upper || keyToEnum(key));
        knownKeyMapping[key] = label;
        // console.log(`added ${key} - ${upper} - ${label}`);
        return label;
    }
    /**
     * Check if a string is applicable to this country.
     */
    const isValidField = createIsValidEnum(fieldEnum);

    const HISTORY_SECTIONS = historySections
        .map(([label, property]) => ({ label, property }));

    // For faster lookup vs. Array.find()
    const keyedSections = keyBy(HISTORY_SECTIONS, o => o.property);

    /**
     * Map from the camel-cased property on the history diff viewer.
     */
    const getHistoryLabel = (key: CamelCase<Field> | string): string => {
        return keyedSections[key]?.label ?? getSectionTitle(key);
    }

    return {
        isValidField,
        fieldEnum,
        fieldOptions,
        formatField,
        formatFieldForSubtitle,
        getSectionTitle,
        historySections: HISTORY_SECTIONS,
        getHistoryLabel
    }
}
