import { patchPmiDataset } from '@features/saved-datasets';
import { combineArrays, makeArray, updateArraySelections, updateSelectionArray } from '@helpers/arrayUtils';
import { KeyAndName, typedKeys } from '@helpers/types';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { keyBy, uniq } from 'lodash';
import { extractFamilies, extractQueries, isSavedDataset, toEmptySelections, toSelectAll } from './convertDataset';
import {
    AllSelections,
    BoxControlsState,
    BrandSearchMode,
    CategoryChangePayload,
    CategorySearchType,
    DatasetCore,
    DatasetEditState,
    DatasetInDbCore,
    FamilyChangePayload,
    SelectionState,
    TermChangePayload,
    ViewingDetails
} from './types';

export const emptySelections: AllSelections = {
    families: {},
    queries: {},
    gmdns: [],
    productCodes: []
}

const initialState: DatasetEditState = {
    ...emptySelections,
    hasUnsavedChanges: false,
    search: {}
}

const ensureFamilyExists = (dictionary: Draft<SelectionState>, family: KeyAndName) => {
    if (!dictionary[family.key]) {
        dictionary[family.key] = toEmptySelections(family)
    }
    return dictionary[family.key];
}

const datasetEditSlice = createSlice({
    name: 'datasetEdit',
    initialState,
    reducers: {
        /**
         * Load an existing saved (or temporary) dataset to be edited.
         */
        editExisting: (state, action: PayloadAction<DatasetCore>) => {
            const dataset = action.payload;
            if (isSavedDataset(dataset)) {
                state.saved = dataset;
                state.hasUnsavedChanges = false;
            } else {
                state.hasUnsavedChanges = true;
            }
            state.name = dataset.name;
            state.datasetType = dataset.datasetType;
            const selected = extractFamilies(dataset);
            state.families = keyBy(selected, s => s.key);
            const queries = extractQueries(dataset);
            state.queries = keyBy(queries, s => s.key);
            state.productCodes = dataset.productCodes ?? [];
            state.gmdns = dataset.gmdns ?? [];
            // Open a search based on the first
            if (queries.length) {
                state.search = {
                    byQuery: queries[0].key.toLowerCase(),
                    searchMode: 'queries'
                }
            } else if (selected.length) {
                state.search = {
                    byKey: selected[0],
                    searchMode: 'families'
                }
            } else if (state.productCodes.length) {
                state.search = {
                    byQuery: state.productCodes[0],
                    searchType: 'productCodes'
                }
            } else if (state.gmdns.length) {
                state.search = {
                    byQuery: state.gmdns[0],
                    searchType: 'gmdns'
                }
            } else {
                state.search = {}
            }
        },
        /**
         * Handle clicking "x" on dataset contents.
         * Clears all selections but keeps search state, etc.
         */
        clearDatasetContents: (state) => {
            return {
                ...state,
                ...emptySelections
            };
        },
        /**
         * Remove everything from state -- used in useEffect callbacks.
         */
        clearDataset: () => {
            return initialState;
        },
        /**
         * Handle checkbox for a single term.
         */
        selectTerm: (state, action: PayloadAction<TermChangePayload>) => {
            const { family, term, isSelected } = action.payload;
            // The family might not be in the state yet.
            const existing = ensureFamilyExists(state.families, family);
            // selectedTerms might not exist when deselecting from selectAll.
            const existingSelections = existing.isSelectAll ? family.terms : existing.selectedTerms.family ?? [];
            existing.selectedTerms.family = updateSelectionArray(existingSelections, term, isSelected);
            existing.isSelectAll = isSelected && existing.selectedTerms.family.length === family.terms.length;
            // Delete family if now empty.
            if (existing.selectedTerms.family.length === 0) {
                delete state.families[family.key];
            }
            state.hasUnsavedChanges = true;
        },
        /**
         * Handle checkbox for an entire family.
         */
        selectFamily: (state, action: PayloadAction<FamilyChangePayload>) => {
            const { isSelected } = action.payload;
            makeArray(action.payload.family).forEach(family => {
                const dict = state[family.searchMode];
                if (isSelected) {
                    const { subItems, parentKey } = family;
                    // When selecting a parent, deselect all children.
                    subItems?.forEach(child => {
                        if (child.key in dict) {
                            delete dict[child.key];
                        }
                    })
                    // When selecting a child, deselect the parent.
                    if (parentKey !== family.key && parentKey in dict) {
                        delete dict[parentKey];
                    }
                    // Combine partial select with any existing selection in this family.
                    if (family.isPartial) {
                        const existing = ensureFamilyExists(dict, family);
                        if (existing?.isSelectAll) return;
                        // Now both existing and current must be partial.
                        Object.keys(family.terms).forEach(property => {
                            const combined = combineArrays(
                                existing.selectedTerms[property],
                                family.terms[property]
                            );
                            existing.selectedTerms[property] = uniq(combined);
                        });
                    } else {
                        // Replace when selecting all.
                        dict[family.key] = toSelectAll(family);
                        // Store the active query.
                        if (state.search.byQuery) {
                            dict[family.key].fromQuery = state.search.byQuery;
                        }
                    }
                } else {
                    delete dict[family.key];
                }
            });
            state.hasUnsavedChanges = true;
        },
        /**
         * Handle checkbox for an array of one or more pro codes or gmdns.
         */
        selectCategories: (state, action: PayloadAction<CategoryChangePayload>) => {
            const { searchType, isSelected, term } = action.payload;
            const terms = makeArray(term, true);
            state[searchType] = updateArraySelections(state[searchType], terms, isSelected);
            state.hasUnsavedChanges = true;
        },
        /**
         * Only need the key and the type in order to delete a family or query.
         */
        removeFamily: (state, action: PayloadAction<{ key: string; searchMode: BrandSearchMode }>) => {
            const { key, searchMode } = action.payload;
            const dict = state[searchMode];
            delete dict[key];
            state.hasUnsavedChanges = true;
        },
        submitSearch: (state, action: PayloadAction<string>) => {
            // Note: clear key and controls but keep tab selection intact.
            state.search = {
                byQuery: action.payload,
                searchType: state.search.searchType,
                searchMode: state.search.searchMode
            };
        },
        clearSearch: (state) => {
            state.search = {};
        },
        searchFamily: (state, action: PayloadAction<{ name: string; key: string; searchMode: BrandSearchMode }>) => {
            const { searchMode } = action.payload;
            state.search = searchMode === 'queries' ? {
                byQuery: action.payload.key.toLowerCase(),
                searchMode
            } : {
                byKey: action.payload,
                searchMode
            };
        },
        searchCategory: (state, action: PayloadAction<{ term: string; searchType: CategorySearchType }>) => {
            state.search = {
                byQuery: action.payload.term,
                searchType: action.payload.searchType
            }
        },
        /**
         * Clear selections on type change.
         * Keep the query when selecting a type, but not when clearing the type.
         */
        setDatasetType: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...initialState,
                datasetType: action.payload,
                search: action.payload ? state.search : initialState.search
            }
        },
        /**
         * Switch between procodes and gmdns.
         */
        setSearchType: (state, action: PayloadAction<CategorySearchType>) => {
            state.search.searchType = action.payload;
        },
        /**
         * Switch between queries and families.
         * Change a `byKey` search to a `byQuery` when activating query mode.
         */
        setSearchMode: (state, action: PayloadAction<BrandSearchMode>) => {
            state.search.searchMode = action.payload;
            if (action.payload === 'queries' && state.search.byKey) {
                state.search.byQuery = state.search.byKey.name.toLowerCase();
                delete state.search.byKey;
            }
        },
        /**
         * Clear the `hasUnsavedChanges` flag in save callbacks.
         * Optionally set the saved id when saving for the first time.
         */
        afterSaveDataset: (state, action: PayloadAction<DatasetInDbCore | undefined>) => {
            state.hasUnsavedChanges = false;
            if (action.payload) {
                state.saved = action.payload;
            }
        },
        /**
         * Common action for updating values from the TermSelect header.
         * Will keep other intact unless explicitly `undefined`.
         */
        updateControls: (state, action: PayloadAction<Partial<BoxControlsState>>) => {
            state.search.controls = {
                ...state.search.controls,
                ...action.payload
            }
        },
        /**
         * Open a family or category into the details pane.
         */
        openDetails: (state, action: PayloadAction<ViewingDetails>) => {
            state.viewingDetails = action.payload;
        }
    },
    // TODO: optimistic updates with error rollback.
    extraReducers: builder => builder.addMatcher(
        patchPmiDataset.matchPending,
        (state, action) => {
            const originalArgs = action.meta.arg.originalArgs;
            if (state.saved && originalArgs._id === state.saved._id) {
                typedKeys(originalArgs.pmiDatasetPatch).forEach(property => {
                    // Note: property is a union so it is unassignable.
                    state.saved[property] = originalArgs.pmiDatasetPatch[property];
                })
            }
        }
    )
})

export const {
    editExisting,
    clearDataset,
    clearDatasetContents,
    selectFamily,
    selectTerm,
    selectCategories,
    removeFamily,
    submitSearch,
    searchFamily,
    searchCategory,
    clearSearch,
    setDatasetType,
    setSearchType,
    setSearchMode,
    afterSaveDataset,
    updateControls,
    openDetails
} = datasetEditSlice.actions;

export default datasetEditSlice.reducer;
