import { uniq, without, xor } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

/**
 * Force a value to be an array.
 * Note: this will turn '' into [] instead of [''] -- is that desired?
 */
export function makeArray<T>(val: T | T[], allowFalsy: true): T[]
export function makeArray<T>(val: T | T[] | undefined | null, allowFalsy: false): T[]
export function makeArray<T>(val: T | T[] | undefined | null, allowFalsy?: boolean): (T | undefined | null)[]
export function makeArray<T>(val: T | T[] | undefined | null, allowFalsy = false): T[] {
    if (Array.isArray(val)) {
        return val;
    } else if (allowFalsy || !!val) {
        return [val];
    } else {
        return [];
    }
}

/**
 * Handle optional array string types by joining.
 */
export function joinStrings(val: string | string[] | undefined | null, separator = ', '): string {
    return Array.isArray(val) ? val.join(separator) : val || '';
}

/**
 * Combine multiple arrays which might be undefined.
 */
export const combineArrays = <T>(...arrays: (T[] | undefined | null)[]): T[] => {
    return arrays.filter(arr => !!arr).flat(1);
}

/**
 * Add an item to an array, or remove it if it already exists.
 */
export const toggleArray = <T>(array: T[], value: T): T[] => xor(array || [], [value]);

export const handleToggle = <T>(value: T) => (array: T[]): T[] => xor(array || [], [value]);

/**
 * Either add or remove a value from an array, depending on the boolean isSelected.
 */
export const updateSelectionArray = <T>(array: T[], value: T, isSelected: boolean): T[] =>
    isSelected ? uniq((array || []).concat(value)) : without(array || [], value);

export const updateArraySelections = <T>(array: T[], values: T[], isSelected: boolean): T[] =>
    isSelected
        ? uniq((array || []).concat(values))
        : without((array || []), ...values);

/**
 * Pass the setState function as an argument to create the callback.
 */
export const handleSelection = <T>(setState: Dispatch<SetStateAction<T[]>>) =>
    (value: T, isSelected: boolean) => setState(array =>
        updateSelectionArray(array, value, isSelected)
    );


/**
 * Flatten a 2D array of results by page into a sparse 1D array where elements have the correct index.
 */
export const flattenPages = <T>(pages: T[][], perPage: number): (T | undefined)[] => {
    const results: (T | undefined)[] = new Array(pages.length * perPage);
    pages.forEach((page, pageIndex) => {
        page.forEach((result, resultIndex) => {
            results[pageIndex * perPage + resultIndex] = result;
        })
    });
    return results;
}

/**
 * Ensure that an index is in-bounds.
 * Return 0 if index is >= length; last index if < 0.
 */
export const loopIndex = (index: number, length: number): number => {
    if (index >= length) return 0;
    if (index < 0) return length - 1;
    return index;
}

export const maxLengthArray = <T>(allItems: T[], maxCount: number): {
    moreCount: number;
    items: T[];
    hasMore: boolean;
} => ({
    items: allItems.slice(0, maxCount),
    moreCount: allItems.length - maxCount,
    hasMore: allItems.length > maxCount
});

/**
 * Drop one element from an array without mutation.
 */
export const removeIndex = <T>(array: T[], index: number): T[] => {
    return array.slice(0, index).concat(array.slice(index + 1));
}

/**
 * Modify one element of an array without mutation.
 */
export const replaceIndex = <T>(array: T[], index: number, newValue: T): T[] => {
    return [...array.slice(0, index), newValue, ...array.slice(index + 1)];
}
