import { deburr, snakeCase } from 'lodash';
import type { Row } from 'react-table';
import { titleCase as titleCaseFn } from 'title-case';
import { formatCount } from './numberUtils';

export type TextFormatter = (text: string) => string;

/**
 * Factory function for applying any sort of formatting to a string (or other value)
 * which might be `null` or `undefined`.
 * All empty values (including `''`) will return an empty string.
 */
export const formatNonEmpty = <T = string>(applyFormatting: (text: T) => string) =>
    (text: T | null | undefined): string =>
        text ? applyFormatting(text) : '';

export const removeInvertedQuestionMarks = formatNonEmpty(
    str => str.replace(/¿/g, '')
);

export function removeFileExtension(text: string | undefined, ext: string) {
    return (text || '').replace(new RegExp(`\.${ext}$`), '');
}

export const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
export const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

export function sentenceCase(str: string | undefined) {
    return !str ? '' : str.toLowerCase().replace(/(^\w)|\.\s+(\w)/gm, (str) => str.toUpperCase());
}

export const removeUnderscores = formatNonEmpty(
    str => str.replace(/_/g, ' ')
);

export function addPlural(val: number | undefined): string {
    return val != 1 ? 's' : '';
}

export function withPluralLabel(
    val: number,
    labelSingular: string,
    formatter: (val: number) => string = formatCount
): string {
    return `${formatter(val)} ${labelSingular}${addPlural(val)}`
}

export function removeBlankLines(text: string) {
    const lines = text.split('\n').filter(line => {
        return !/^\s*$/.test(line);
    });

    return lines.join('\n');
}

export const screamingSnakeCase = (str: string | undefined) => snakeCase(str).toUpperCase();

/**
 * Wrapper around titleCase function:
 * - Replaces underscores because this is always desired.
 * - Ignores words which are already upper-case.
 */
export const titleCase = formatNonEmpty(str => {
    const input = str.toLowerCase().replaceAll('_', ' ');
    return titleCaseFn(input);
});

/**
 * titleCaseFn doesn't capitalize around /, so need to add spaces to get capitalization.
 * Can either keep the extra spaces or remove them.
 */
export function suppReasonCase(str: string, pad = true): string {
    const cased = titleCaseFn((str || '').replaceAll('/', ' / '));
    return pad ? cased : cased.replaceAll(' / ', '/');
}

/**
 * Adds parenthesis around a text, but only if it isn't empty.
 */
export const withParentheses = formatNonEmpty((str: string | number) => `(${str})`);

/**
 * Combine two strings with the secondary in parentheses.
 * If the primary is empty, return just the secondary.
 */
export const parenthetical = (
    primary: string | null | undefined,
    secondary: string | null | undefined
): string => {
    if (primary && secondary) return `${primary} (${secondary})`;
    return primary || secondary || '';
}

/**
 * Adds a separator between two or more strings only if both are non-empty.
 */
export function joinOptional(separator: string, ...strings: (string | number | null | undefined)[]): string {
    return strings.filter(s => s).join(separator);
}

/**
 * Consider "NULL" and "N/A" as the same.
 */
export const isNa = (label: string) => ['NULL', 'N/A', 'NA', '-1', ''].includes(label);

/**
 * Replace empty values with "N/A"
 */
export const applyNa = (label: string | null | undefined) =>
    (label && !isNa(label)) ? label : 'N/A';

/**
 * Replace "TRUE"/"FALSE" with "Yes"/"No"/"Unreported"
 */
export const booleanFormatter = (text: string) => {
    if (text === 'TRUE') return 'Yes';
    if (text === 'FALSE') return 'No';
    if (text === '__UNHANDLED__') return 'N/A';
    return 'Unreported';
}

/**
 * Create a function to map the yes and no labels to true/false/undefined.
 */
export const labelToBoolean = (trueLabel: string, falseLabel?: string) =>
    (text: string | undefined): boolean | undefined => {
        if (text === trueLabel) return true;
        if (falseLabel && text === falseLabel) return false;
        return undefined;
    }

/**
 * Chain together multiple text formatters, calling from left to right.
 * Allow undefined to support cases where the formatter is a variable.
 */
export const combineFormatters = (...formatters: (TextFormatter | undefined)[]): TextFormatter =>
    (text: string) => formatters.reduce((acc, fn) => fn ? fn(acc) : acc, text);

/**
 * Replace "&#8482" with "™", etc.
 * Happens when the source data contains bad/unnecessary encodings.
 * Based on https://stackoverflow.com/a/29824550/10431574
 */
export const decodeHtmlEntities = (text: string): string =>
    text.replace(/&#(\d+);/g, (_, dec) => String.fromCharCode(dec));

/**
 * Break a single string with "\n" line breaks into an array of paragraphs.
 */
export const paragraphs = (text: string | null | undefined): string[] =>
    !text ? [] : text
        .replaceAll(/\t/g, '    ')
        .split(/[\n\r]/g);

/**
 * Create a url-friendly version of a string with lower case letters and hyphens instead of spaces.
 * TODO: strip punctuation instead of encoding.
 */
export const createSlug = (name: string) =>
    deburr(name).toLowerCase().replaceAll(/\s/g, '-')

/**
 * Separate a prefix from a text based on a delimiter.
 * If no delimiter is found, then the entire text goes in the second position of the returned array.
 */
export const splitSingle = (text: string | null | undefined, delimiter: string = ' - '): [string, string] => {
    const pos = (text || '').indexOf(delimiter);
    if (pos === -1) return ['', text];
    return [text.substring(0, pos), text.substring(pos + delimiter.length)];
}

/**
 * Remove `<p>` from a string, while preserving specified exceptions such as '<em>`.
 * Replace closing tags with a space, then remove double spaces.
 */
export const stripHtmlTags = (text: string | null | undefined, allowed: string[] = []): string =>
    (text || '').replaceAll(/<(\/?)(.+?)>/g, (match, closing, tag) => {
        if (allowed.includes(tag)) return match;
        return closing ? ' ' : '';
    }).replaceAll('  ', ' ');


export const alphaCompare = (a: string, b: string): number => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
}

/**
 * Sorts case-insensitive, but keeps all-caps entries together.
 */
export const caseInsensitiveCompare = (a: string = '', b: string = ''): number => {
    const lowerCompare = alphaCompare(a.toLowerCase(), b.toLowerCase());
    return lowerCompare === 0 ? alphaCompare(a, b) : lowerCompare;
}

/**
 * Can be passed to react-table.
 * TODO: always put empty strings last.
 * Note: desc param is provided, but the inverting is done automatically.
 */
export const caseInsensitiveTableSort = <D extends object>(rowA: Row<D>, rowB: Row<D>, columnId: string) => {
    return caseInsensitiveCompare(rowA.values[columnId], rowB.values[columnId]);
}
