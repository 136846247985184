import { API_BASE_URL } from '@constants';
import { selectJwt } from '@features/auth';
import { withoutLeadingSlash } from '@helpers/textUtils';
import { useSelector } from '@store';
import React, { ForwardedRef, forwardRef } from 'react';

/**
 * Forces download by submitting a form to a hidden iframe.
 *
 * Form can be executed 2 ways:
 * 1. Pass a ref for the form element and call `submit()` on the ref.
 * 2. Include a <button type="submit"> in the children.
 *
 * Additional data can be sent to the API by including more <input> child elements.
 */

type HiddenDownloadProps = JSX.IntrinsicElements['form'] & {
    filename?: string;
    apiPath: string;
    uniqueId?: string;
}

const DownloadForm = forwardRef((
        { filename, apiPath, children, uniqueId = '', ...rest }: HiddenDownloadProps,
        ref: ForwardedRef<HTMLFormElement>
    ) => {
        const token = useSelector(selectJwt);

        const frameId = `exportFrame${uniqueId}`;
        const formId = `exportForm${uniqueId}`; // Note: not strictly necessary

        return (
            <>
                <iframe name={frameId} width="0" height="0" style={{ display: 'none' }}/>
                <form
                    ref={ref}
                    action={`${API_BASE_URL}/${withoutLeadingSlash(apiPath)}`}
                    id={formId}
                    method="post"
                    name="exportForm"
                    target={frameId}
                    encType="application/x-www-form-urlencoded"
                    {...rest}
                >
                    <input type="hidden" name="filename" value={filename}/>
                    <input type="hidden" name="jwt" value={token}/>
                    {children}
                </form>
            </>
        )
    }
);

export default DownloadForm;
