import { IS_DEV_MODE } from '@constants';
import * as Sentry from '@sentry/react';
import { Contexts, Extras, Primitive, ScopeContext, SeverityLevel } from '@sentry/types';

// Subset of ScopeContext, but with some properties renamed.
export type Args = {
    /**
     * Allows for sending raw data of any type, whereas `extra` must be an object.
     */
    data?: any;
    severity?: SeverityLevel;
    extra?: Extras;
    contexts?: Contexts;
    tags?: Record<string, Primitive>;
};
const prepareArgs = ({ severity, extra, tags, contexts, data }: Args): Partial<ScopeContext> => ({
    level: severity ?? 'warning',
    tags: {
        ...tags,
        source: 'JS' // 'JS' vs 'API'
    },
    extra: {
        ...extra,
        data
    },
    contexts
});

/**
 * Log to the console in dev mode, or send to sentry in prod.
 */
export const sentryWarn = (message: string, args: Args = {}) => {
    if (IS_DEV_MODE) {
        console.warn(message, args);
    }
    Sentry.captureMessage(message, prepareArgs(args));
}

/**
 * For use in try/catch blocks.
 */
export const sentryCapture = (errorLike: Error | any, args: Args = {}) => {
    if (IS_DEV_MODE) {
        console.error(errorLike, args);
    }
    Sentry.captureException(errorLike, prepareArgs(args));
}
