import { EuukDrugField } from '@api/enums';
import { enumToKey } from '@helpers/filterEnums';
import type { EuukDrugFieldEnum } from '../../../API';
import createCountryFieldUtil from './countryFieldUtil';

export type SubItemData = [number, number | null, string, EuukDrugFieldEnum];
export type HasSubItems = [number, string, EuukDrugFieldEnum, SubItemData[]];
export type TopLevelData = [number | null, string, EuukDrugFieldEnum] | HasSubItems;

// TODO: consistent capitalization
export const fieldsEMA: TopLevelData[] = [
    [null, 'Search in All Sections', 'LABEL'],
    [null, 'Indications and Usage', EuukDrugField.indicationsAndUsage],
    [1, 'Name of the Medicinal Product', EuukDrugField.nameOfTheMedicinalProduct],
    [2, 'Qual/Quant Composition', EuukDrugField.qualitativeAndQuantitativeComposition, [
        [2, null, 'Excipients of Known Effect', EuukDrugField.excipientsOfKnownEffect]
    ]],
    [3, 'Pharmaceutical Form', EuukDrugField.pharmaceuticalForm],
    [4, 'Clinical Particulars', EuukDrugField.clinicalParticulars, [
        [4, 1, 'Therapeutic indications', EuukDrugField.therapeuticIndications],
        [4, 2, 'Posology and administration', EuukDrugField.posologyAndMethodOfAdministration],
        [4, 3, 'Contraindications', EuukDrugField.contraindications],
        [4, 4, 'Special warnings and precautions', EuukDrugField.specialWarningsAndPrecautionsForUse],
        [4, 5, 'Interaction with other medicines', EuukDrugField.interactionWithOtherMedicinalProductsAndOtherFormsOfInteraction],
        [4, 6, 'Fertility, pregnancy and lactation', EuukDrugField.fertilityPregnancyLactation],
        [4, 7, 'Effects on ability to drive etc.', EuukDrugField.effectsOnAbilityToDriveAndUseMachines],
        [4, 8, 'Undesirable effects', EuukDrugField.undersirableEffects],
        [4, 9, 'Overdose', EuukDrugField.overdose],
    ]],
    [5, 'Pharmacological Properties', EuukDrugField.pharmacologicalProperties, [
        [5, 1, 'Pharmacodynamic properties', EuukDrugField.pharmacodynamicProperties],
        [5, 2, 'Pharmacokinetic properties', EuukDrugField.pharmacokineticProperties],
        [5, 3, 'Preclinical safety data', EuukDrugField.preclinicalSafetyData],
    ]],
    [6, 'Pharmaceutical Particulars', EuukDrugField.pharmaceuticalParticulars, [
        [6, 1, 'List of excipients', EuukDrugField.listOfExipients],
        [6, 2, 'Incompatibilities', EuukDrugField.incompatibilities],
        [6, 3, 'Shelf life', EuukDrugField.shelfLife],
        [6, 4, 'Special precautions for storage', EuukDrugField.specialPrecautionsForStorage],
        [6, 5, 'Nature and contents of container', EuukDrugField.natureAndContentsOfContainer],
        [6, 6, 'Special precautions for disposal and other handling', EuukDrugField.specialPrecautionsForDisposal]
    ]],
    [7, 'Marketing Auth Holder', EuukDrugField.marketingAuthorisationHolder],
    [8, 'Marketing Auth Number(s)', EuukDrugField.marketingAuthorisationNumbers],
    // [9, 'Date of Auth or Renewal', ''],
    // [10, 'Date of Revision of Text', ''],
];

// The keys in response dictionaries don't include the numbers.
const createKey = (field: EuukDrugFieldEnum): string => {
    const cleaned = field.replace(/\d+(\.\d+)?_/, '');
    return enumToKey(cleaned);
}

// Build out a mapping from the array structure.
const mapping: Partial<Record<EuukDrugFieldEnum, string>> = {};
fieldsEMA.forEach((item) => {
    const [number, label, field, subItems] = item;
    // TODO: should these names include the number?
    mapping[field] = number ? `${number}. ${label}` : label;
    subItems?.forEach((sub) => {
        const [topNum, subNum, label, field] = sub;
        mapping[field] = (topNum && subNum) ? `${topNum}.${subNum} ${label}` : label;
    });
});

// TODO: support nesting or indentation.
const orderedFields = fieldsEMA.flatMap<EuukDrugFieldEnum>(([_, __, field, subItems]) => {
    return [field, ...(subItems || [])?.map(([_, __, ___, subField]) => subField)];
});


export const ema = createCountryFieldUtil<EuukDrugFieldEnum>(
    EuukDrugField,
    orderedFields,
    mapping
);
