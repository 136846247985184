import { PageView, PageViewProps } from '@features/ga4';
import React, { ComponentType } from 'react';
import Authenticated from './Authenticated';
import { Capability } from './capabilities';

interface AuthenticatedElementProps extends PageViewProps {
    component: ComponentType;
    capability: Capability;
}

/**
 * Combined wrapper for authentication and GA tracking.
 */
const AuthenticatedElement = (
    { component: Component, ga4Title, v2View, capability }: AuthenticatedElementProps
) => {
    return (
        <Authenticated capability={capability}>
            <PageView ga4Title={ga4Title} v2View={v2View}>
                <Component/>
            </PageView>
        </Authenticated>
    )
}
/**
 * Turns AuthenticatedElement into an HOC.
 */
export const withAuthentication = (Page: React.ComponentType, ga4Title: string, capability: Capability) => {
    return () => (
        <AuthenticatedElement ga4Title={ga4Title} component={Page} capability={capability}/>
    )
};

export default AuthenticatedElement;
