import { BasilModule } from '@constants';
import { createContext, useContext } from 'react';

/**
 * The user can be inside of the Regulatory or Postmarket module.
 * Or they can be in neither module when they are on pages like login, etc.
 *
 * Note: CurrentModule is based on the current page layout, so it is
 * possible that the user does not have access to the current module.
 */
const CurrentModuleContext = createContext<BasilModule | undefined>(undefined);

export const useCurrentModule = () => useContext(CurrentModuleContext);
export const useIsPostmarketModule = () => useCurrentModule() === BasilModule.Postmarket;
export const useIsRegulatoryModule = () => useCurrentModule() === BasilModule.Regulatory;

export default CurrentModuleContext;
