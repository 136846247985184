import { DrugField, FaersField } from '@api/enums';
import { formatKey } from '@content/Facet/formatLabels';
import { sentryWarn } from '@features/errors';
import type { MultiDrugDatasetFilter } from '@features/saved-items';
import type { ApiFilter } from '@helpers/filterEnums';
import { safeAdd } from '@helpers/numberUtils';
import { SkipToken, skipToken } from '@reduxjs/toolkit/query';
import type {
    BasicDrugSearchArg,
    BasicDrugSearchQuery,
    Count,
    DrugDatasetQuery,
    DrugFilterCountResponse,
    DrugQuery,
    IngredientQuery
} from '../../API';
import { LabelSearchCountry } from '../../API/sources';
import type { IngredientSuggestion } from '../data/pharmaDemoReducer';
import type { ContentsItem } from '../Header/MultiContents';
import { fieldUtil } from './Fields';
import type { CountryFieldUtil } from './Fields/countryFieldUtil';

export interface FormMultiCriterion {
    /**
     * The filter field.
     */
    field: string;
    /**
     * The content of the input.
     */
    text: string;
    /**
     * Can override the values sent to the API.
     * Used when creating a multi-search based on ingredients by unii.
     * Cannot be created from the form itself, which only uses name.
     */
    unii?: string;
}

export interface MultiSearchState {
    mode: LabelSearchCountry;
    inputs: FormMultiCriterion[];
    routes: string[];
    categories: string[];
}

// Note: matches location state
export interface MultiFormState {
    multi: MultiSearchState;
    brands: string[];
    actives: string[];
}

export interface SearchFormState extends MultiFormState {
    query: string;
}

export const initialFormState: SearchFormState = {
    query: '',
    actives: [],
    brands: [],
    multi: {
        inputs: [],
        routes: [],
        categories: [],
        mode: 'us'
    }
}

export type MultiQueryArg = SkipToken | DrugDatasetQuery;

// Check for empty fields and empty values.
export const isValidCriterion = (criteria: FormMultiCriterion): boolean =>
    criteria.field.length > 0 && criteria.text.trim().length > 0;

const hasFilters = (state: FormMultiCriterion[]): boolean =>
    state.some(isValidCriterion);

/**
 * Need to require the values array for compatibility with MultiDrugDatasetFilter interface.
 */
export type CreatedFilter<F extends string = string> = {
    field: F;
    values: string[];
    operator?: 'OR'
}

/**
 * Get all filters from the current form, regardless of applicability.
 *
 * Convert form fields into API filters, preserving order.
 * Handle fields which need to be sent to the API using code instead of text.
 * Check that text is not empty.
 * Note: don't combine multiple inputs for the same field in order to assign count
 */
export const stateToFiltersWithoutCheck = (
    state: Partial<MultiSearchState>
): CreatedFilter[] => {
    const { inputs = [], routes = [], categories = [] } = state;
    const filters: CreatedFilter[] = [];
    // Helper for adding an array from checkboxes.
    const maybeAddArray = (field: string, values: string[]) => {
        if (values.length > 0) {
            filters.push({ field, values, operator: 'OR' });
        }
    }
    // Helper for adding a single text input, if not empty.
    const maybeAddOne = (field: string, value: string) => {
        if (value) {
            filters.push({ field, values: [value] });
        }
    }
    // Loop though inputs
    inputs.forEach(({ field, text, unii }) => {
        // Special handling for editing search by ingredients (US only)
        if (unii && field === DrugField.activeIngredient) {
            maybeAddOne(DrugField.activeCodeIngredient, unii);
        } else if (unii && field === DrugField.inactiveIngredient) {
            maybeAddOne(DrugField.inactiveCodeIngredient, unii);
        } else {
            maybeAddOne(field, text.trim());
        }
    });
    // Handle checkboxes (US only)
    // Do not add additional filters if there are no core filters -- want to skip.
    if (filters.length > 0) {
        maybeAddArray(DrugField.administrationRoute, routes);
        maybeAddArray(DrugField.drugCategory, categories);
    }
    return filters;
}

/**
 * Adds applicability check for the country.
 */
export const genericStateToFilters = <F extends string>(
    state: Partial<MultiSearchState>,
    util: CountryFieldUtil<F>
): CreatedFilter<F>[] => {
    const allFilters = stateToFiltersWithoutCheck(state);
    return allFilters.filter(o => util.isValidField(o.field)) as CreatedFilter<F>[];
}

/**
 * Create an API query from the form state.
 */
export const nonEmptyStateToQuery = (
    state: Partial<MultiSearchState>
): DrugQuery => {
    if (state.mode && state.mode !== 'us') {
        sentryWarn(
            'function nonEmptyStateToQuery is expected to be used on us/spl data only'
        );
    }
    return ({
        filters: genericStateToFilters(state, fieldUtil.us),
        faersFilters: [],
        orderBy: {
            fieldName: 'FAERS',
            ascending: false
        }
    });
}

export const nonEmptyStateToLabelQuery = (
    state: Partial<MultiSearchState>
): BasicDrugSearchQuery => {
    const country = state.mode || 'us';
    return ({
        filters: stateToFiltersWithoutCheck(state),
        country
    });
}

/**
 * Create an API query from the form state,
 * returning a skip token if the query is empty.
 */
export const stateToQuery = (
    state: Partial<MultiSearchState> | undefined
): MultiQueryArg => {
    if (!state || !state.inputs?.length) return skipToken;
    const query = nonEmptyStateToQuery(state);
    if (query.filters.length === 0) return skipToken;
    return query;
}

export const stateToLabelQuery = (
    state: Partial<MultiSearchState> | undefined
): BasicDrugSearchArg => {
    if (!state || !state.inputs?.length) return skipToken;
    const query = nonEmptyStateToLabelQuery(state);
    if (query.filters.length === 0) return skipToken;
    return query;
}


export const sumCounts = (a: Count, b: Count): Count => ({
    clinicalTrial: safeAdd(a?.clinicalTrial, b?.clinicalTrial),
    faers: safeAdd(a?.faers, b?.faers)
});

/**
 * Convert an ingredient from autosuggest into an initial form state.
 */
export const ingredientToState = ({ name, isActive }: IngredientSuggestion): MultiSearchState => {
    return {
        inputs: [{
            field: isActive ? DrugField.activeIngredient : DrugField.inactiveIngredient,
            text: name
        }],
        routes: [],
        categories: [],
        mode: 'us'
    }
};

export const _ingredientToState = (ingredient: string, isActive: boolean): MultiSearchState =>
    ingredientToState({ name: ingredient, isActive });


/**
 * Work backwards from an array of filters
 * from a dataset.
 */
export const filtersToMulti = (
    filters: MultiDrugDatasetFilter[],
    mode: LabelSearchCountry = 'us'
): MultiSearchState => {
    const inputs: FormMultiCriterion[] = [];
    const routes: string[] = [];
    const categories: string[] = [];
    filters.forEach((filter) => {
        const { field, values } = filter;
        if (field === FaersField.administrationRoute) {
            routes.push(...values);
        } else if (field === DrugField.drugCategory) {
            categories.push(...values);
        } else {
            inputs.push(...(values).map(value => ({
                field,
                text: value
            })));
        }
    });
    return {
        inputs,
        routes,
        categories,
        mode
    }
}


// Note: matches the button names in CountryToggle
export const mapModeToLabel: Record<LabelSearchCountry, string> = {
    us: 'SPL',
    fda: 'Drugs@FDA',
    eu: 'EMA & UK',
    combined: 'Combined'
}

/**
 * Convert a multi-search state into data for the "i" contents popup.
 */
export const multiToContents = (
    { inputs, routes, categories, mode }: MultiSearchState
): ContentsItem[] => {
    const util = fieldUtil[mode ?? 'us'];
    // Note: could combine multiples of the same field.
    const items = inputs.map((input) => ({
        title: util.formatField(input.field),
        values: [input.text.trim()]
    })).filter(o => o.values[0].length > 0);
    if (routes.length > 0) {
        items.push({
            title: 'Administration Route',
            values: routes.map(formatKey('administrationRoute'))
        });
    }
    if (categories.length > 0) {
        items.push({
            title: 'Drug Category',
            values: categories.map(formatKey('drugCategory'))
        });
    }
    if (mode) {
        items.push({
            title: 'Data Source',
            values: [mapModeToLabel[mode]]
        });
    }
    return items;
}

const uniiField = (ingredient: IngredientQuery): FormMultiCriterion => {
    return {
        // Use the name field rather than the code field so that it works with the edit UI
        field: ingredient.isActive ? DrugField.activeIngredient : DrugField.inactiveIngredient,
        text: ingredient.name?.toLowerCase(),
        unii: ingredient.fdaUniiCode
    }
}

export const uniiQueryToState = (ingredients: IngredientQuery[]): MultiSearchState => {
    // Note: assumes the actives are already first.
    return {
        inputs: ingredients.map(uniiField),
        routes: [],
        categories: [],
        mode: 'us'
    }
}

export interface StepCount {
    drugs: number;
    ingredients: number;
}

export interface FieldWithCounts extends FormMultiCriterion {
    counts?: StepCount;
}

/**
 * Compare form fields to filter objects in the API response.
 */
const isSameFilter = (criterion: FormMultiCriterion, filter: ApiFilter<string>) => {
    // TODO: what about ingredients by unii?
    return (
        criterion.field === filter.field &&
        criterion.text.trim() === filter.values?.[0]
    );
}

/**
 * Empty values are dropped before sending to API, so the arrays might not match up.
 */
export const assignCountsToFields = (
    state: FormMultiCriterion[],
    counts: DrugFilterCountResponse['filterCounts'] | undefined
): FieldWithCounts[] => {
    if (!counts) {
        return state;
    }
    const apiCounts = counts as (StepCount & { filter: CreatedFilter })[];
    return state.map<FieldWithCounts>(criterion => {
        if (isValidCriterion(criterion)) {
            const match = apiCounts.find(o => isSameFilter(criterion, o.filter));
            if (match) {
                return {
                    ...criterion,
                    counts: match // Note: could drop other fields
                }
            }
        }
        return criterion;
    });
}
