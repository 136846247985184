import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedbackProps } from "./types";

// Note: could store more than one alert at a time,
// but this would require a unique id to dismiss the right one.

interface AlertState {
    open: boolean;
    props?: FeedbackProps;
}

const initialState: AlertState = {
    open: false
}

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        createAlert: (state, action: PayloadAction<FeedbackProps>) => ({
            open: true,
            props: action.payload
        }),
        // Note: keep the props present to ensure that unmount animation works
        dismissAlert: (state) => {
            state.open = false;
        }
    }
});

export default alertSlice.reducer;

export const { createAlert, dismissAlert } = alertSlice.actions;
