import { extent } from 'd3';
import { endOfMonth, format, formatISO, getTime, parseISO, setISOWeek } from 'date-fns';
import { isNil, maxBy, minBy } from 'lodash';
import { DateFacet, DayMonthYear, HistogramPeriod, MonthYear, OptionalDate, RangeDates } from './types';

/**
 * Fixes dates in legacy timestamp format ('2021-04-21T00:00:00.000Z')
 * as well as those in the current date-only format ('2021-04-21').
 *
 * Legacy dates need to have the trailing 'Z' removed
 * so that they are interpreted as local time instead of UTC.
 * New dates need to have an empty timestamp added.
 */
export function stripUTC(dateString: string): string {
    // Note: assumes that the time is unimportant.
    return `${dateString.slice(0, 10)}T00:00:00.000`;
}

/**
 * Export standard formats as constants.
 */
export const SLASH_DATE_FMT = 'MM/dd/yyyy';
export const DASH_DATE_FMT = 'MM-dd-yyyy';
export const MON_DATE_FMT = 'MMM d, yyyy';
export const MON_YEAR_FMT = 'MMM yyyy';

/**
 * Applies standard format while handling
 * `Uncaught RangeError: Invalid time value at format`
 */
export function safeFormatDate(dateString: string | null | undefined, fmt = SLASH_DATE_FMT): string {
    if (!dateString) return '';
    try {
        return format(parseISO(dateString.slice(0, 10)), fmt);
    } catch (e) {
        try {
            return format(new Date(stripUTC(dateString)), fmt);
        } catch (e) {
            return dateString;
        }
    }
}

/**
 * ISO strings don't need to be converted to Date objects.
 */
const reorderIso = (separator: string) =>
    (iso: string | undefined): string => {
        if (!iso) return '';
        const [y, m, d] = iso.slice(0, 10).split('-');
        return [m, d, y].join(separator);
    }

/**
 * Convert an ISO date with or without time to MM/dd/yyyy.
 */
export const isoToSlash = reorderIso('/');

/**
 * Convert an ISO date with or without time to MM-dd-yyyy.
 */
export const isoToDash = reorderIso('-');

/**
 * Will always return a `number` when a `string` is provided,
 * but will return `undefined` if input is empty.
 */
const overloadedFormatter = (formatString: (iso: string) => number) => {
    function formatter(iso: string): number;
    function formatter(iso: string | undefined): number | undefined;
    function formatter(iso: string | undefined): number | undefined {
        return iso ? formatString(iso) : undefined;
    }
    return formatter;
}

/**
 * Extract a number from the characters of an ISO string.
 */
const parseIso = (start: number, end: number) =>
    overloadedFormatter((iso) => parseInt(iso.slice(start, end)));

export const isoToYear = parseIso(0, 4);
export const isoToMonth = parseIso(5, 7);
export const isoToDay = parseIso(8, 10);

/**
 * Convert Date object to ISO string.
 */
export function dateToStr(date: Date | number): string;
export function dateToStr(date: OptionalDate): string | null;
export function dateToStr(date: OptionalDate): string | null {
    return date ? formatISO(date, { representation: 'date' }) : null;
}

/**
 * Convert ISO string to Date object.
 */
export function strToDate (str: string): Date;
export function strToDate (str: string | null): Date | null;
export function strToDate (str: string | null): Date | null {
    return str ? parseISO(str) : null;
}

/**
 * Export fallback values for year ranges.
 */
export const DEFAULT_START_YEAR = 1960;
export const DEFAULT_END_YEAR = new Date().getFullYear();

export const DEFAULT_START_ISO = '1960-01-01';
export const DEFAULT_END_ISO = new Date().toISOString().slice(0, 10);
export const CURRENT_MONTH_END_ISO = dateToStr(endOfMonth(new Date()));
export const DEFAULT_AE_START_ISO = '1991-01-01';

/**
 * Check for NaN values when parsing a string.  Replace empty with a fallback.
 */
export const parseYearString = (fallback: number) => (year?: string | number | null) => {
    const n = +year;
    return (n && !isNaN(n)) ? n : fallback;
}
export const parseStartYear = parseYearString(DEFAULT_START_YEAR);
export const parseEndYear = parseYearString(DEFAULT_END_YEAR);
export const parseYearRange = (start?: string | number | null, end?: string | number | null) =>
    [parseStartYear(start), parseEndYear(end)];

/**
 * Find the min and max year from a set of data, with fallbacks if empty.
 */
export const findYearRange = <T>(data: T[], accessor: (datum: T) => number) => {
    const [min, max] = extent(data || [], accessor);
    return [min || DEFAULT_START_YEAR, max || DEFAULT_END_YEAR]
}

/**
 * Compare a range against a min and max
 */
export const isFilteredYearRange = (
    [start, end]: [number | null | undefined, number | null | undefined],
    min = DEFAULT_START_YEAR,
    max = DEFAULT_END_YEAR
) => (start && start !== min) || (end && end !== max);

/**
 * Note: date-fns has a clamp function, but need to modify to handle possible null interval.
 * Using simple >/< comparisons works for either Date objects or strings -- just not both at once.
 */
export const clampDate = <T extends Date | string | number>(
    date: T | null,
    { min, max }: { min?: T | null; max?: T | null }
): T | null => {
    if (!date) return null;
    if (min && min > date) return min;
    if (max && max < date) return max;
    return date;
}

export const isoToTime = overloadedFormatter(
    iso => getTime(parseISO(iso))
);

/**
 * Export month name abbreviations
 */
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * Can convert to an object with numeric properties year, month, day.
 */
export const isoToObject = (iso: string): DayMonthYear => ({
    year: isoToYear(iso),
    month: isoToMonth(iso),
    day: isoToDay(iso)
})

/**
 * Join a year, month, day back into an ISO string.
 */
export const objectToIso = ({ day, month, year }: DayMonthYear): string =>
    `${year}-${month}-${day}`

/**
 * Note: using iteratee shorthand ['year', 'month'] does not work.
 */
export const minMonYear = (...objects: MonthYear[]) => minBy(objects, o => 100 * o.year + o.month);
export const maxMonYear = (...objects: MonthYear[]) => maxBy(objects, o => 100 * o.year + o.month);
export const isSameMonYear = (a: MonthYear, b: MonthYear) => a.year === b.year && a.month === b.month;

/**
 * Get the x value for a time series, using the middle of the month to avoid off-by-one.
 */
export const monYearToTime = ({ month, year }: MonthYear) =>
    new Date(year, month, 15).getTime();

/**
 * Both start and end are required by API date filters.
 */
export const createDateFilter = <T>(
    field: T,
    { start, end }: { start?: string | null | undefined; end?: string | null | undefined }
) => ({
    field,
    start: start || DEFAULT_START_ISO,
    end: end || DEFAULT_END_ISO
})

/**
 * Validates that: start is >= min, end is <= max, end >= start
 */
export const validateDateRange = (range: DateFacet = {}) => (startDate: OptionalDate, endDate: OptionalDate) => {
    const start = clampDate(dateToStr(startDate), range);
    const end = clampDate(dateToStr(endDate), range);
    return end >= start ? { start, end } : { start, end: start };
}

/**
 * Can compare two ISO strings without needing to convert to Date objects.
 * True if and only if both are defined but different.
 */
export const isDifferentIsoMonth = (str1: string | null | undefined, str2: string | null | undefined): boolean => {
    if (str1 && str2) {
        return str1.slice(0, 7) !== str2.slice(0, 7);
    }
    return false;
}

/**
 * Typescript type guard makes sure that all properties are present and defined.
 */
export const hasAllRangeDates = <T>(range: Partial<RangeDates<T>>): range is RangeDates<NonNullable<T>> =>
    Boolean(range.minDate && range.maxDate && range.startDate && range.endDate);

/**
 * Convert a histogram object based on which properties it contains.
 * TODO: version where the interval is an argument.
 */
export const periodToDate = ({ year, month, week, day }: HistogramPeriod): Date => {
    if (!year) {
        throw new Error("missing year");
    }
    if (!isNil(week)) {
        // Note: month 0 causes previous year.
        return setISOWeek(new Date(year, 2), week);
    }
    if (!isNil(month)) {
        return new Date(year, month, day ?? 15);
    }
    throw new Error("must contain either week or month");
};

export const periodToTime = (period: HistogramPeriod): number =>
    getTime(periodToDate(period));
