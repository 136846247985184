import type { FeedbackProps } from '@features/feedback';

const alertPropsFactory = (props: Pick<FeedbackProps, 'severity' | 'message' | 'icon'>) =>
    // Note: not using overrides so that the function can be passed directly to useMutationWithFeedback
    (): FeedbackProps => ({
        // Shared arguments provided to all export alerts.
        variant: 'outlined',
        sx: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            marginTop: '-14px'
        },
        vertical: 'top',
        horizontal: 'center',
        autoHideDuration: 2000,
        // Specific props.
        ...props
    });

// Error is used in multiple places.
export const errorAlertProps = alertPropsFactory({
    severity: 'error',
    message: 'An error occurred exporting the file.'
});

export const pendingAlertProps = alertPropsFactory({
    severity: 'success',
    message: 'Preparing data for export...',
    icon: 'clock'
});

export const successAlertProps = alertPropsFactory({
    severity: 'success',
    message: 'Downloading file...',
    icon: 'download'
});

export const singleAlertProps = alertPropsFactory({
    severity: 'success',
    message: 'Creating and downloading your file...',
    icon: 'download'
});
