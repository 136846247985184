import { BasilModule, IS_FREEMIUM, IS_TRIAL_SITE, UserRoleId } from '@constants';
import type { User } from '@features/iam';
import { hasLinkedProduct, isAdminRole, isSysAdminRole } from '@helpers/userRoles';

/**
 * The error message or redirect location depends on the reason why they cannot access.
 */
export enum DeniedReason {
    EXPIRED_TRIAL,
    TRIAL_DISABLED_FEATURE,
    BASIC_DISABLED_FEATURE,
    LOGGED_OUT,
    REQUIRES_MODULE,
    INSUFFICIENT_PRIVILEGES,
    PHARMA_MODULE_LIMITED
}

const check = <T extends Record<string, false | { type: DeniedReason }>>(data: T) => data;

/**
 * Creates a dictionary. For each capability, return a reason object stating why it is not allowed,
 * or `false` if the action is ok.
 */
export const getBlockedReasons = (user: User, maxRole: UserRoleId, isExpiredTrial: boolean | undefined) => {

    const isAdmin = isAdminRole(maxRole);
    const isSysAdmin = isSysAdminRole(maxRole);

    // Allowed if the user can access any of the provided modules. Message displays the first.
    const moduleDenial = (...modules: BasilModule[]) =>
        modules.some(module => hasLinkedProduct(user, module)) ? false : {
            type: DeniedReason.REQUIRES_MODULE as const,
            module: modules[0]
        };

    const expiredDenial = isExpiredTrial ? { type: DeniedReason.EXPIRED_TRIAL } as const : false;

    const trialSiteDenial = (featureDesc: string) => IS_TRIAL_SITE ? {
        type: DeniedReason.TRIAL_DISABLED_FEATURE,
        featureDesc
    } as const : false;

    const basicSiteDenial = (featureDesc: string) => IS_FREEMIUM ? {
        type: DeniedReason.BASIC_DISABLED_FEATURE,
        featureDesc
    } as const : false;

    const pharmaLimitedDenial = (featureDesc: string) => user.limitModulePharma ? {
        type: DeniedReason.PHARMA_MODULE_LIMITED,
        featureDesc
    } as const : false;

    return check({
        // General capability check which requires only that the user is logged-in.
        'logged-in': false,
        // Must have regulatory access to view dashboard.
        'view-dashboard': moduleDenial(BasilModule.Regulatory, BasilModule.BasicRegulatory),
        // Saving items is blocked during the trial.
        'save-items': trialSiteDenial('Saving Items') || basicSiteDenial('Saving Items'),
        // CSV export is blocked for all trial users.
        // TODO: combine metering with capabilities.
        'export-csv': trialSiteDenial('CSV data export') || pharmaLimitedDenial('CSV data export'),
        // Only the sysadmin is an admin on trial site.
        'edit-users': (IS_TRIAL_SITE ? isSysAdmin : isAdmin) ? false : {
            type: DeniedReason.INSUFFICIENT_PRIVILEGES
        },
        // Probably not used anywhere.
        'administer-system': isSysAdmin ? false : {
            type: DeniedReason.INSUFFICIENT_PRIVILEGES
        },
        // Check for module access for all postmarket content.
        'view-postmarket': moduleDenial(BasilModule.Postmarket),
        // TODO: probably do not need module denial again here due to nested routes
        // Can view/create/update datasets during the trial period.
        'pmi-datasets': moduleDenial(BasilModule.Postmarket) || expiredDenial,
        // Can view PMI analytics during the trial period.
        'pmi-analytics': moduleDenial(BasilModule.Postmarket) || expiredDenial,
        // Can view matrix during the trial period.
        'create-matrix': moduleDenial(BasilModule.Postmarket) || expiredDenial,
        // Trial users can view devices until expired.
        'view-devices': moduleDenial(BasilModule.Regulatory, BasilModule.BasicRegulatory) || expiredDenial,
        // Even expired trials can search devices.
        'search-devices': moduleDenial(BasilModule.Regulatory, BasilModule.BasicRegulatory),
        // Allow users of both modules to view stripped-down reports.  TODO: revisit
        'view-reports': expiredDenial,
        // Right now only have one permission for Executive.
        // Can use during trial but not after trial expired.
        'view-executive': moduleDenial(BasilModule.Executive) || expiredDenial,
        // Need module access to view Pharma.
        'view-pharma': moduleDenial(BasilModule.PharmaCovigilance),
        // Use a separate permission for exporting on Pharma module, which
        // checks for limited Pharma access.
        'export-pharma-data': moduleDenial(BasilModule.PharmaCovigilance) || pharmaLimitedDenial('Excel data export')
    })
}

export type BlockReasonDict = ReturnType<typeof getBlockedReasons>;

export type Capability = keyof BlockReasonDict;

type SpecificDenials = {
    [K in Capability]: Exclude<BlockReasonDict[K], boolean>
}

export type DenialReason<C extends Capability = Capability> = SpecificDenials[C];

export type ReasonLoggedOut = { type: DeniedReason.LOGGED_OUT }
