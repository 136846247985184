import type { BasicQueryHook } from '@api';
import { mapQueryHook } from '@api/mapHook';
import { generatedApi, HdeSupplement, PmaSupplement } from './deviceDataApi.gen';
import type { DeviceIdentifier, DeviceResponse } from './deviceTypes';
import { DocConfig } from './deviceTypes';
import { DocType } from './docType';

export * from './responseTypes';

const deviceDataApi = generatedApi.injectEndpoints({
    endpoints: build => ({
        /**
         * Create a single endpoint which takes the doc type as a param.
         * Note: generated endpoints are not actually used anywhere, but could be.
         */
        getAppSupplementsList: build.query<(HdeSupplement | PmaSupplement)[], DeviceIdentifier>({
            query: ({ docType, itemId }) => docType === DocType.PMA ? ({
                url: `/device-data/v1/pma-supplement/list`,
                method: 'POST',
                body: { pmaNumbers: [itemId] }
            }) : ({
                url: `/device-data/v1/hde-supplement/list`,
                method: 'POST',
                body: { hdeNumbers: [itemId] }
            })
        }),
        /**
         * Get device details from any of the six endpoints by passing the type and id as args.
         */
        getDeviceDetails: build.query<DeviceResponse, DeviceIdentifier>({
            query: ({ docType, itemId }) => ({
                url: `/device-data/v1/${DocConfig.from(docType, 'docType').apiSlug}/${itemId}`
            })
        })
    })
});

export const {
    useGetAppSupplementsListQuery,
    useGetDeviceDetailsQuery
} = deviceDataApi;

export const {
    getEcosystem
} = deviceDataApi.endpoints;

/**
 * Can require only the itemId by providing the docType in advance.
 * Need to assert the correct return type of `data`.
 */
export const hookForDocType = <T extends DocType>(docType: T): BasicQueryHook<string, DeviceResponse<T>> =>
    mapQueryHook(useGetDeviceDetailsQuery)(
        (itemId: string) => ({ itemId, docType }),
        (data) => data as DeviceResponse<T>
    );
