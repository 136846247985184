import { Tags } from '@api';
import { omit, pick } from 'lodash';

import { generatedApi } from './PreferencesAPI.gen';

const topLevelProperties = [
    'enableEmails',
    'enableExecutiveRegulatoryAlert',
    'executiveRegulatoryAlertFrequency'
];

/**
 * Must invalidate the preferences query when updating.
 * Use an optimistic update to modify the stored preferences immediately and roll back on error.
 */
export const preferencesAPI = generatedApi.enhanceEndpoints({
    endpoints: {
        getPreference: {
            providesTags: [Tags.Preferences],
            keepUnusedDataFor: 3600
        },
        updatePreference: {
            invalidatesTags: () => [Tags.Preferences]
        },
        patchPreference: {
            onQueryStarted(preferencePatch: Partial<Record<string, any>>, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    generatedApi.util.updateQueryData('getPreference', undefined, (draft) => {
                        const blobPatch = omit(preferencePatch, topLevelProperties);
                        const rootPatch = pick(preferencePatch, topLevelProperties);
                        Object.assign(draft.preferences, rootPatch);
                        Object.assign(draft.preferences.preferenceBlob, blobPatch);
                    })
                );
                // or: queryFulfilled.catch(dispatch(api.util.invalidateTags([Tags.Preferences])))
                queryFulfilled.catch(patchResult.undo);
            }
        }
    }
})

export const {
    useGetPreferenceQuery,
    usePatchPreferenceMutation
} = preferencesAPI;
