import { IS_DEV_MODE } from '@constants';
import { sentryWarn } from '@features/errors';

// map for query params to GA event parameter names (update this list as needed)
const ACCEPT_PARAMS: Record<string, string> = {
    tab: 'basil_tab',
    pageSize: 'basil_page_size',
    sortDirection: 'basil_sort_direction',
    orderBy: 'basil_order_by',
    application_type: 'basil_application_type'
};

// these url segments will pass through to GA (update this list as needed)
const ACCEPT_WORDS = [
    // regulatory
    'medical-device',
    'results',
    // doc types
    'product-code',
    '510k',
    'den',
    'pma',
    'hde',
    'regulation-number',
    'guidance',
    'clinical-trial',
    // regulatory tabs -- standard
    'ecosystem',
    'review-time',
    'recalls',
    'adverse-events',
    'devices',
    'documents',
    // regulatory tabs -- clinical trials
    'design',
    'eligibility',
    'interventions',
    'outcome-measures',
    'results',
    'contacts',
    'references',
    // app pages
    'profile',
    'security',
    'settings',
    'auth',
    'admin',
    'setup-account',
    'reset-password',
    'upgrade',
    'cancel',
    'success',
    'get-started',
    'help-videos',
    // aemod
    'aemod',
    'procode',
    'app',
    // PMI
    'postmarket',
    'dataset',
    'new',
    'edit',
    'brands',
    'companies',
    'categories',
    'analytics',
    'table',
    'warning-letter',
    'recall-event',
    'recall',
    'adverse-event',
    'details',
    // executive
    'executive',
    'dashboard',
    'create',
    'search',
];

const wordMap = Object.fromEntries(ACCEPT_WORDS.map(word => [word, 1]));

const hideId = (segment: string) => (wordMap[segment] || !segment) ? segment : 'xxx';

/*  translateLocation()

    Cleans up the URL being sent to GA:
      - replace any ids in the path with xxx
      - remove all query params */

function translateLocation() {
    const { origin, pathname } = window.location;
    // Remove trailing slash.
    const path = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
    // Censor ids.
    return origin + path.split('/').map(hideId).join('/');
}

/*  addEventParams()

    For every query param that we care about (and isn't sensitive info),
    include it as a parameter of the page view event. */

function addEventParams(config: Record<string, string>) {
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
        const mapped = ACCEPT_PARAMS[key];
        if ( mapped ) {
            config[mapped] = value;
        }
    })
    return config;
}

interface ConfigArgs {
    userId?: string;
    isExpiredTrial: boolean;
}

// Common interface for Basil or GA handlers.
export interface HasGtag {
    gtag(name: Gtag.EventNames | string, params?: Record<string, string>): void;
}

// Singleton class for controlling GA4.

export class GA4Handler {
    private static instance: GA4Handler;

    private constructor(private args: ConfigArgs) { }

    private setGA4HandlerConfig(measurementId: string, debugMode: boolean) {
        const baseConfig = {
            user_id: this.args.userId,
            basil_hostname: window.location.hostname,
            page_location: translateLocation(),
            page_title: '',
            page_referrer: '',
            send_page_view: false // page views are handled manually
        };

        if (this.args.isExpiredTrial) {
            baseConfig['trial_expired'] = true;
        }

        if (IS_DEV_MODE) {
            baseConfig['debug_mode'] = debugMode;
        }

        window.gtag?.('config', measurementId, baseConfig);
    }

    public static initialize(measurementId: string, args: ConfigArgs) {
        // Allow the instance to be replaced in order to modify arg.
        GA4Handler.instance = new GA4Handler(args);
        GA4Handler.instance.setGA4HandlerConfig(measurementId, true);
    }

    public static gtag(name: Gtag.EventNames | string, params: Record<string, string> = {}) {
        if (!GA4Handler.instance) {
            sentryWarn(
                'Cannot send Google Analytics event before initialization',
                { data: { name, params } }
            );
            return;
        }

        if (!window.gtag && !IS_DEV_MODE) {
            sentryWarn(
                'Google Analytics not found in window'
            );
        }

        const p = {
            user_id: GA4Handler.instance.args.userId,
            ...params
        };

        // Uncomment for debugging
        // console.log(`sent Google Analytics event '${name}' with params`, p);

        window.gtag?.('event', name, p);
    }
}

// Define events as an instance so that Basil Analytics can use the same tracking.

export class AnalyticsEvents {
    constructor(private handler: HasGtag) {}
    
    public login(userId: string, isFirst: boolean) {
        // Always send login event.
        this.handler.gtag('login', {
            user_id: userId
        });
        // Optionally send an additional first_login event.
        if (isFirst) {
            this.handler.gtag('first_login', {
                user_id: userId
            });
        }
    }

    public export() {
        this.handler.gtag('file_download');
    }

    public selectContent(contentType: string) {
        this.handler.gtag('select_content', {
            content_type: contentType
        });
    }

    public selectItem(listName: string) {
        this.handler.gtag('select_item', {
            item_list_name: listName
        });
    }

    public pageView(title: string) {
        this.handler.gtag('page_view', addEventParams({
            page_title: title,
            page_location: translateLocation()
        }));
    }
}

// Export a single instance of GA4, instead of a singleton
type LegacyGA = AnalyticsEvents & {
    initialize(measurementId: string, args: ConfigArgs): void;
};
const GA4 = new AnalyticsEvents(GA4Handler) as LegacyGA;
GA4.initialize = GA4Handler.initialize;

export default GA4;
