import { alpha, darken, lighten } from '@mui/material';

export const PRIMARY = {
    main: '#3C748A',
    light: 'rgb(93,155,189)',
    dark: '#2e4c5f',
    contrastText: '#FFFFFF'
}

export const LINK_BLUE = {
    main: '#0059CC'
};

export const LIGHT_BLUE = {
    main: '#afc4de',
    light: '#e2eef9',
    dark: '#C3D6E7' // '#8DC9F1' // TODO
}

export const BRIGHT_BLUE = {
    main: '#3A92C7',
    // TODO
    dark: '#2d657b'// TODO -- too dark
}

export const HEADING_COLOR = {
    main: '#798CA0',
    dark: '#607080' // Note: from darken(.2)
}

export const TEXT_COLOR = {
    main: '#222222',
    dark: '#000000'
}

export const BRAND_COLOR = {
    main: '#009c3b'
}

export const COMPANY_COLOR = {
    main: '#246774'
}

export const CATEGORY_COLOR = {
    main: '#fba94e'
}

export const REVIEW_PANEL_COLOR = {
    main: '#8e1a1c' // TODO
}

export const TRIAL_COLOR = {
    main: '#D9631E'
}

export const SELF_REGISTER_COLOR = {
    main: '#BD5111'
};

export const INFO_BLUE = {
    main: '#456e8f'
};

export const INSTRUCTION_NAVY = {
    main: '#0b3755',
}

export const PMI_ICON_COLOR = {
    main: '#7d9dac'
}

export const PMI_OUTLINE_COLOR = {
    main: '#b7cbd9',
    dark: alpha(PRIMARY.main, 0.6)
}

export const BASIL_LOGO_GREEN = {
    main: '#346f34'
}

export const PMI_BACKGROUND_GREY = {
    light: '#F9FAFC',
    main: '#F4F7F9',
    dark: '#EEEDF2'
}

export const PMI_BACKGROUND_BLUE = {
    main: '#f2f9fd'
}

export const REG_BACKGROUND_GREY = {
    main: '#E7ECEE',
    dark: '#dbe3e6' // TODO
}

export const PHARMA_PURPLE = {
    main: '#7f0a80',
    light: lighten('#7f0a80', 0.20),// #8236a5
    dark: darken('#7f0a80', 0.20),
    contrastText: '#FFFFFF'
}
