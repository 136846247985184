import { createTagArrays, Tags } from '@api';
import { inferableValidateArgsMutationEnhancer } from '@api/reject';
import { loginValidator, resetValidator, signupValidator } from './argValidators';
import { generatedApi, SalesApiResponse } from './iamApi.gen';

export * from './iamApi.gen';

// TODO: get correct SalesApiArg type via generated code
// https://github.com/basil-sys/monaco-iam-service/blob/1c199bfe22859cfd75995778fe38c960631d0994/internal/models/contact.go#L4
export type ContactRequest = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    company: string;
    title?: string; // TODO: add to backend?
}

const userTags = createTagArrays(Tags.User);

/**
 * Invalidate a single user object and the list of users.
 * The API queryArg must contain a `userId` property.
 */
const invalidateHelper = {
    invalidatesTags: (result: unknown, error: unknown, arg: { userId?: string }) =>
        userTags.idsAndList(arg.userId)
}

const options = { stripUnknown: true };

const enhancedApi = generatedApi.enhanceEndpoints({
    endpoints: {
        /**
         * Actions which modify a user in the user list.
         * Note: resetTrial changes the creation date.
         * Note: no invalidation on requestPasswordReset, resendInvitation
         */
        changeStatus: invalidateHelper,
        resetTrial: invalidateHelper,
        patchUser: invalidateHelper,
        /**
         * Inviting a user invalidates the previously-stored user list,
         * as this new user needs to be added to it.
         */
        inviteUser: {
            invalidatesTags: userTags.list
        },

        /**
         * Handle invalid form states as if they were API errors,
         * But don't actually call the API unless the form data is valid.
         */
        login: (endpoint) =>
            inferableValidateArgsMutationEnhancer(endpoint, loginValidator, options),
        requestOwnPasswordReset: (endpoint) =>
            inferableValidateArgsMutationEnhancer(endpoint, resetValidator, options),
        resendSignupInvitation: (endpoint) =>
            inferableValidateArgsMutationEnhancer(endpoint, resetValidator, options),
        signup: (endpoint) =>
            inferableValidateArgsMutationEnhancer(endpoint, signupValidator, { stripUnknown: false })
        // Note: technically there should be an `invalidatesTags` on signup and acceptInvitation,
        // But in practice this will never be an issue.
        // Note: may want to map from `password` to `newPassword` on setupAccount, but the API accepts both.
        // see: https://github.com/basil-sys/monaco-iam-service/commit/e3e0707e53ccda866709d77cd57ce6495915b1f4
    }
}).injectEndpoints({
    endpoints: build => ({
        // Note: form is validated locally
        contactSales: build.mutation<SalesApiResponse, ContactRequest>({
            query: (queryArg) => ({
                url: `/iam/v1/contact/sales`,
                method: "POST",
                body: queryArg
            })
        })
    })
})

export const { login, bySingleUseToken } = enhancedApi.endpoints;

export const { useContactSalesMutation } = enhancedApi;
