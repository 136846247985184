import { useGetPreferenceQuery } from './PreferencesAPI';

/**
 * Load preferences in the root of the App and keep a query subscription.
 * Prevents jumpiness for toggles which get their initial value from a preference query.
 */

export default function LoadPreferences() {
    useGetPreferenceQuery();
    return null;
}
