import { titleCase } from '@helpers/textUtils';
import { startCase } from 'lodash';
import type { AutocompleteResponse, DrugAutocompleteMetadataResponse } from '../../API';
import {
    urlActiveIngredientAnalytics,
    urlDrugLabelingFda,
    urlDrugLabelingSpl,
    urlInactiveIngredientAnalytics,
    urlSingleDrugAnalytics
} from '../../pharmaUrls';

/**
 * matches the names used in the 'metadata' property.
 */
export type AutocompleteTypeUs = 'drugs' | 'drugs_fda' | 'activeIngredients' | 'inactiveIngredients';
export type AutocompleteType = AutocompleteTypeUs | 'drugs_eu' | 'drugs_uk';

export const getTermsForType = (
    data: DrugAutocompleteMetadataResponse | undefined,
    type: AutocompleteType
): string[] => {
    if (!data) return [];
    const index = data.metadata.indexOf(type);
    return data.results
        .filter(result => result.metadata.includes(index))
        .map(result => result.name);
}

export interface AutocompleteOption {
    type: AutocompleteTypeUs;
    label: string;
}

export const getTypedObjects = (
    data: DrugAutocompleteMetadataResponse | undefined
): AutocompleteOption[] => {
    if (!data) return [];
    return data.results.flatMap((result) => {
        // Always include `drugs` and ingredients.  Only include `drugs_fda` if there is no `drugs`.
        const label = result.name.toLowerCase();
        const types = result.metadata.map(index => data.metadata[index] as AutocompleteTypeUs);
        const options: AutocompleteOption[] = [];
        types.forEach(type => {
            if (type === 'drugs_fda' && types.includes('drugs')) {
                return;
            }
            options.push({ label, type });
        });
        return options;
    });
}

export const optionToKey = (option: AutocompleteOption): string =>
    `${option.label}--${option.type}`;

export const eitherToKey = (value: AutocompleteOption | string): string =>
    typeof value === 'string' ? value : optionToKey(value);

export const eitherToLabel = (value: AutocompleteOption | string): string =>
    typeof value === 'string' ? value : value.label.toLowerCase();

export const metadataToBooleans = (values: number[], labels: string[]): Partial<Record<AutocompleteType, boolean>> =>
    Object.fromEntries(values.map(i => [labels[i], true]));

export const autocompleteOptionUrl = (option: AutocompleteOption): string => {
    const fn = {
        drugs: urlSingleDrugAnalytics,
        drugs_fda: urlSingleDrugAnalytics,
        activeIngredients: urlActiveIngredientAnalytics,
        inactiveIngredients: urlInactiveIngredientAnalytics
    }[option.type];
    return fn(option.label);
}

export const getLabelHref = (option: AutocompleteOption): string | undefined => {
    const fn = {
        drugs: urlDrugLabelingSpl,
        drugs_fda: urlDrugLabelingFda
    }[option.type];
    return fn?.(option.label);
}

export const getTooltipText = (type: AutocompleteTypeUs): string => {
    return {
        drugs: 'Drug',
        drugs_fda: 'Drug',
        activeIngredients: 'Active Ingredient',
        inactiveIngredients: 'Inactive Ingredient'
    }[type] || titleCase(startCase(type)).replace(/s$/, '');
}

export interface LabelAutocompleteOption {
    brandName: string;
    hasSpl?: boolean;
    hasFda?: boolean;
}

const isMetadataResponse = (data: DrugAutocompleteMetadataResponse | AutocompleteResponse): data is DrugAutocompleteMetadataResponse =>
    'metadata' in data;

export const getLabelAutocompleteOptions = (
    data: DrugAutocompleteMetadataResponse | AutocompleteResponse | undefined
): LabelAutocompleteOption[] => {
    if (!data) return [];
    if (!isMetadataResponse(data)) {
        return data.results.map(value => ({ brandName: value }));
    }
    return data.results.flatMap((result) => {
        if (typeof result === 'string') { // not necessary
            return {
                brandName: result
            };
        } else {
            const option: LabelAutocompleteOption = {
                brandName: result.name
            };
            result.metadata.forEach(index => {
                const type = (data as DrugAutocompleteMetadataResponse).metadata?.[index] as AutocompleteTypeUs;
                if (type === 'drugs_fda') {
                    option.hasFda = true;
                } else if (type === 'drugs') {
                    option.hasSpl = true;
                }
            });
            // Need to skip over ingredients.
            return (option.hasSpl || option.hasFda) ? option : [];
        }
    });
}
