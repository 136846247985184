import type { TotalResults } from '@api';

const NUMBER_FORMATTER = new Intl.NumberFormat('en-US');

const ONE_DECIMAL_DIGIT = new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 });

const TWO_DECIMAL_DIGITS = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

const PERCENT_FORMATTER = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, maximumSignificantDigits: 2 });

export type NumberFormatter = (count: number) => string;

export const createFormatter = (options: Intl.NumberFormatOptions): NumberFormatter => {
    const format = new Intl.NumberFormat('en-US', options);
    return (n: number = 0) => format.format(n);
}

type Options = {
    formatter?: NumberFormatter;
}

export type CustomNumberFormatter<T extends Options> = (count: number, options?: T) => string;

/**
 * Transform a number into a string which uses commas after thousands.
 * If no number is provided, return "0".
 */
export const formatCount = (count: number = 0): string => NUMBER_FORMATTER.format(count);

export const formatStringCount = (count: string = "0"): string => {
    try {
        return formatCount(parseInt(count));
    } catch (e) {
        return count;
    }
}

/**
 * Adds a '+' to elasticSearch response counts if they hit the maximum,
 * which is either 100,000 or 10,000 depending on the service.
 *
 * Relation is "eq" for equals or "gte" for greater than or equal.
 *
 * Can format the count only, or add a label with optional plural 's'.
 */
export const formatTotalResults = (
    results: TotalResults | undefined,
    { label, formatter = formatCount }: Options & { label?: string } = {}
): string => {
    if (!results) return '';
    const count = formatter(results.value);
    const formatted = results.relation === 'gte' ? `${count}+` : count;
    if (!label) return formatted;
    return `${formatted} ${label}${results.value === 1 ? '' : 's'}`;
}

/**
 * Accepts either a number or an object.
 */
export const formatCountOrResults = (
    count: number | TotalResults | undefined
): string =>
    typeof count === 'number' ? formatCount(count) : formatTotalResults(count);

/**
 * Format all numbers above a base value (default 100k)
 * as an amount of thousands.
 */
export const formatWithK = (
    count: number = 0,
    { threshold = 100000 }: { threshold?: number } = {}
): string => {
    if (count < threshold) return formatCount(count);
    return `${ONE_DECIMAL_DIGIT.format(count / 1000)}k`;
}

/**
 * Format all numbers above a base value as an amount of millions,
 * and apply formatWithK to the rest.
 */
export const formatWithM = (
    count: number = 0,
    { mThreshold = 1000000, kThreshold = 100000 }: { mThreshold?: number; kThreshold?: number } = {}
): string => {
    if (count >= mThreshold) return `${TWO_DECIMAL_DIGITS.format(count / 1000000)}m`;
    return formatWithK(count, { threshold: kThreshold });
}

/**
 * Append % symbol.
 * Round to 2 significant figures, but allow "100%".
 * Assumes that the input is already out of 100.
 * Note: will round numbers greater than 100, ie. 124 => "120%".
 * Desired outputs: 3,000%, 100%, 53%, 5.3%, 0.53%, 0.05%.
 */
export const formatPercent = (
    percentage: number,
    noSymbol: boolean = false
) => {
    const number = PERCENT_FORMATTER.format(percentage);
    return noSymbol ? number : `${number}%`;
}

/**
 * Return the rank suffix for a number.
 */
export const nth = (n: number) => (['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th');

/**
 * Combine the number and its suffix
 */
export const withNth = (n: number) => `${n}${nth(n)}`;

/**
 * Combine two numbers which could be undefined or null, using 0 in place of nullish values.
 */
export const safeAdd = (a: number | null | undefined, b: number | null | undefined): number => (a || 0) + (b || 0);

/**
 * Sum up all numbers in a dictionary object.
 */
export const sumDictionary = (dict: Record<PropertyKey, number | null | undefined> | undefined): number =>
    Object.values(dict || {}).reduce(safeAdd, 0);

/**
 * Helper to use numbers and TotalResults objects interchangeably.
 * Note: include `object` type because API types are not always accurate.
 */
export const totalCount = (count: number | TotalResults | object | undefined | null): number => {
    if (typeof count === 'object') {
        return (count as TotalResults)?.value || 0;
    }
    return count || 0;
}
