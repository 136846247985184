import { BasilModule, IS_FREEMIUM } from '@constants';
import { mapValues } from 'lodash';
import React, { ReactNode } from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BsCapsulePill, BsNewspaper } from 'react-icons/bs';
import { SiGoogleanalytics } from 'react-icons/si';
import asMuiIcon from '../Misc/Icons/asMuiIcon';

/**
 * Each backend module name matches a pairing of module type and module level.
 */

enum ModuleLevel {
    Basic,
    Pro,
    Enterprise
}

export enum ModuleType {
    Regulatory,
    Postmarket,
    Executive,
    Pharma
}

export enum Industry {
    MedDevices,
    Pharma
}

interface ModuleMapping {
    type: ModuleType;
    level: ModuleLevel;
}

interface ModuleSettings {
    name: string;
    nameShort?: string;
    industry: Industry;
    icon: ReactNode;
    rootUrl: string;
    apiRoot: string;
    salesText: string;
    hidden?: boolean;
}

type ModuleConfig = ModuleSettings & ModuleMapping & { backendName: BasilModule };

const RegulatoryIcon = asMuiIcon(AiOutlineFileSearch);
const PostmarketIcon = asMuiIcon(SiGoogleanalytics);
const ExecutiveIcon = asMuiIcon(BsNewspaper);
const PharmaIcon = asMuiIcon(BsCapsulePill);


export const CORE_MODULE_TYPES: Record<ModuleType, ModuleSettings> = {
    [ModuleType.Regulatory]: {
        name: "Regulatory",
        industry: Industry.MedDevices,
        icon: <RegulatoryIcon/>,
        rootUrl: '/medical-device',
        apiRoot: '/regulatory/',
        salesText: 'Activate today and discover new insights to advance your regulatory strategy.'
    },
    [ModuleType.Postmarket]: {
        name: "Postmarket",
        industry: Industry.MedDevices,
        icon: <PostmarketIcon/>,
        rootUrl: '/postmarket',
        apiRoot: '/pmi/',
        salesText: 'Activate today to add game-changing analytics to your postmarket surveillance efforts.'
    },
    [ModuleType.Executive]: {
        name: "Executive",
        industry: Industry.MedDevices,
        icon: <ExecutiveIcon/>,
        rootUrl: '/executive',
        apiRoot: '/exec/regulatory/',
        salesText: 'Activate today and discover new insights to advance your regulatory strategy.' // TODO
    },
    [ModuleType.Pharma]: {
        name: "Pharma Intelligence",
        nameShort: "Pharma",
        industry: Industry.Pharma,
        icon: <PharmaIcon/>,
        rootUrl: '/pharma',
        apiRoot: '/pharma/covigilance/',
        salesText: 'New! The Basil Pharma Intelligence module is now available.  Supercharge your safety research with access to advanced analytics based on FAERS, Clinical Trials, and much more.'
        // hidden: true
    }
}

const MODULE_MAPPING: Record<BasilModule, ModuleMapping> = {
    [BasilModule.Regulatory]: {
        level: ModuleLevel.Enterprise,
        type: ModuleType.Regulatory
    },
    [BasilModule.BasicRegulatory]: {
        level: ModuleLevel.Basic,
        type: ModuleType.Regulatory
    },
    [BasilModule.Postmarket]: {
        level: ModuleLevel.Enterprise,
        type: ModuleType.Postmarket
    },
    [BasilModule.Executive]: {
        level: ModuleLevel.Enterprise,
        type: ModuleType.Executive
    },
    [BasilModule.PharmaCovigilance]: {
        level: ModuleLevel.Enterprise,
        type: ModuleType.Pharma
    }
}

const MODULE_CONFIGS: Record<BasilModule, ModuleConfig> = mapValues(MODULE_MAPPING,
    ({ level, type }, key) => ({ level, type, ...CORE_MODULE_TYPES[type], backendName: key as BasilModule })
);

export const getModuleName = (module: BasilModule): string =>
    MODULE_CONFIGS[module]?.name || '';

export const getModuleSettings = (module: BasilModule): ModuleSettings | undefined =>
    MODULE_CONFIGS[module]

export const getModuleRootUrl = (module: BasilModule): string =>
    MODULE_CONFIGS[module]?.rootUrl || '';

// Enterprise users should not see basic and vice-versa.
// TODO: will need to add Pro here, once available.
const AVAILABLE_MODULE_CONFIGS = Object.values(MODULE_CONFIGS).filter(config =>
    config.level === (IS_FREEMIUM ? ModuleLevel.Basic : ModuleLevel.Enterprise)
    && !config.hidden
);
export const ALL_MODULES = AVAILABLE_MODULE_CONFIGS.map(c => c.backendName);
