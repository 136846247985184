import { BasilModule, TRIAL_DAYS, UserRoleId } from '@constants';
import type { User, UserEmailAddress } from '@features/iam';
import { typedValues } from '@helpers/types';
import { addDays, getTime, isPast } from 'date-fns';

export interface RoleBooleans {
    isAdmin: boolean;
    isSysAdmin: boolean;
}

/**
 * Find the highest privilege in the array of roles for a user
 */
export function getUserRole(user: Pick<User, 'assignedRoles'>): UserRoleId {
    const roles = user.assignedRoles ?? [];

    let maxAdmin: UserRoleId = UserRoleId.User;
    for (let role of roles) {
        if (role.id == UserRoleId.SystemAdmin) {
            return UserRoleId.SystemAdmin;
        } else if (role.id == UserRoleId.OrgAdmin) {
            maxAdmin = UserRoleId.OrgAdmin;
        }
    }

    return maxAdmin;
}

export function isAdminRole(roleId: string | undefined): boolean {
    return roleId == UserRoleId.SystemAdmin || roleId == UserRoleId.OrgAdmin;
}

export function isSysAdminRole(roleId: string | undefined): boolean {
    return roleId == UserRoleId.SystemAdmin;
}

const hasMatchingRole = (callback: (roleId: string | undefined) => boolean) =>
    (user: User | undefined): boolean =>
        (user?.assignedRoles || []).some(role => callback(role.id));

export const isAdminUser = hasMatchingRole(isAdminRole);

export const isSysAdminUser = hasMatchingRole(isSysAdminRole);

export const booleansToRole = ({ isAdmin, isSysAdmin }: RoleBooleans): UserRoleId =>
    isSysAdmin ? UserRoleId.SystemAdmin : isAdmin ? UserRoleId.OrgAdmin : UserRoleId.User;

export const roleToBooleans = (role: UserRoleId | undefined): RoleBooleans => ({
    isAdmin: isAdminRole(role),
    isSysAdmin: isSysAdminRole(role)
})

export const getFullName = (u: Pick<User, 'firstName' | 'lastName'>): string =>
    u ? (u.firstName + ' ' + u.lastName) : '';

/**
 * Returns the Primary, if one exists. Then returns any email.
 * Or undefined if no email or no user.
 * Note: current backend only supports one email, so this is not really necessary.
 */
export const getEmailObject = (u: Pick<User, 'emailAddresses'>): UserEmailAddress | undefined => {
    const primary = u?.emailAddresses?.find(elem => elem.type == 'PRIMARY');
    return primary ?? u?.emailAddresses?.[0];
}

export const getEmailAddress = (u: Pick<User, 'emailAddresses'>): string | null =>
    getEmailObject(u)?.address || null;

/**
 * Check if a user can access a particular module.
 */
export const hasLinkedProduct = (user: Pick<User, 'assignedRoles'>, module: BasilModule): boolean =>
    user?.assignedRoles?.some(role => role.linkedProducts?.some(product => product.textId === module)) ?? false;

/**
 * Get a boolean mapping of whether the user can access each module or not.
 * Note: sysadmins have a `linkedProduct` with textId 'SYSTEM_ACCESS'
 */
export const getUserModules = (user: Pick<User, 'assignedRoles'>): Record<BasilModule, boolean> => {
    // Reduce the linked products to a boolean mapping.
    // May contain non-module keys and will not have any false values.
    const roleDict: Record<string, true> = {};
    (user?.assignedRoles || [])
        .flatMap(role => role.linkedProducts || [])
        .forEach(product => {
            roleDict[product.textId!] = true;
        });
    // See if each BasilModule is contained in the dictionary or not.
    const modules = {} as Record<BasilModule, boolean>;
    typedValues(BasilModule).forEach(module => {
        modules[module] = roleDict[module] ?? false
    });
    return modules;
}

export const getUserTrial = (user: User) => {
    // TODO: throw error if no date?
    const startTime = new Date(getEmailObject(user)?.createdDatetime);
    if (!startTime) return;
    const endTime = addDays(startTime, TRIAL_DAYS);
    const isExpired = isPast(endTime);
    return {
        endTime: getTime(endTime),
        isExpired
    }
}

/**
 * Check the user object to see if they have logged in before.
 * Note: for initial setup only.  After, use the `state.auth.isFirstLogin` property.
 */
export const isFirstLogin = (user: User): boolean => {
    return !user.prevAuthDatetime;
}

/**
 * Check if the user is registered using SSO.
 */
export const isSsoUser = (user: User): boolean =>
    user.userType === 'SSO';
