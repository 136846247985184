import { BasilModule } from '@constants';
import { typedKeys } from '@helpers/types';
import { getUserModules } from '@helpers/userRoles';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { BlockReasonDict, getBlockedReasons } from './capabilities';

/**
 * Memoized selectors return values which are derived from the user object.
 * Will only re-evaluate when the user changes.
 */

const selectAuthState = (state: RootState) => state.auth.user;
const selectUserObject = (state: RootState) => state.auth.user?.original;

/**
 * Select the dictionary of blocked reasons for each capability.
 */
export const selectCapabilities = createSelector(
    selectAuthState,
    (state): BlockReasonDict | undefined => {
        if (state) {
            return getBlockedReasons(state.original, state.userRole, state.trial?.isExpired)
        }
    }
)

/**
 * Select a dictionary containing the name of each module
 * and a true/false value of whether the current user can access it.
 */
export const selectModuleAccess = createSelector(
    selectUserObject,
    getUserModules
);

/**
 * Select an array containing only the modules which the current user can access.
 */
export const selectUserModules = createSelector(
    selectModuleAccess,
    (dict): BasilModule[] => typedKeys(dict).filter(module => dict[module])
);


