import type { LineageApplication } from '@features/device-data';
import { mapValues, uniq, uniqBy } from 'lodash';

/**
 * Can use a generic T instead of LineageApplication.
 */
export interface GenericLineage<T> {
    children?: T[];
    parents?: T[];
    siblings?: {
        [key: string]: T[];
    };
    vertexDegree?: number;
}

export interface HasGenericLineage<T> {
    lineage?: GenericLineage<T>;
}

/**
 * Functions do not need to require a complete 510k document.
 * Only the lineage property is examined, and is not required.
 */
export type HasLineage<T = LineageApplication> = HasGenericLineage<T>;

export const getId = (app: LineageApplication) => app.applicationNumber;
/**
 * Helper function since all getters map from a LineageApplication object to its applicationNumber.
 * Always returns an array, but will be empty if properties are missing.
 */
export const numbers = (relatives: LineageApplication[]): string[] => (relatives || []).map(getId);

/**
 * Returns an array of parent/predicate objects.
 */
export const getPredApps = <T>(item: HasLineage<T>): T[] =>
    item?.lineage?.parents || [];

/**
 * Returns an array of parent/predicate ids.
 */
export const getPredIds = (item: HasLineage): string[] =>
    numbers(getPredApps(item));

/**
 * Returns the count of parents/predicates.
 */
export const getPredCount = (item: HasLineage<unknown>): number =>
    getPredApps(item).length;

/**
 * Check if there are any predicated before fetching.
 */
export const hasPred = (item: HasLineage): boolean =>
    !!item?.lineage?.parents?.length

/**
 * Returns an array of child/posticate objects.
 */
export const getPostApps = <T>(item: HasLineage<T>): T[] =>
    item?.lineage?.children || [];

/**
 * Returns an array of child/posticate ids.
 */
export const getPostIds = (item: HasLineage): string[] =>
    numbers(getPostApps(item));

/**
 * Returns the count of children/posticates.
 */
export const getPostCount = (item: HasLineage<unknown>): number =>
    getPostApps(item).length;

/**
 * Returned object keys are the parent names and values are other children of that parent.
 */
export const getSibGroups = (item: HasLineage): { [k: string]: string[] } =>
    mapValues(item?.lineage?.siblings || {}, numbers)

/**
 * Returns a flat array with the application objects of all siblings, across all parents.
 * Need to de-duplicate because the same sibling could appear under multiple parents.
 */
export const getSibApps__withoutDeduplication = <T>(item: HasLineage<T>): T[] =>
    Object.values(item?.lineage?.siblings || []).flat();

export const getSibApps = (item: HasLineage) =>
    uniqBy(Object.values(item?.lineage?.siblings || []).flat(), 'applicationNumber');

/**
 * Returns a flat array with the application numbers of all siblings, across all parents.
 */
export const getSibIds = (item: HasLineage): string[] =>
    numbers(getSibApps(item));

/**
 * Returns a flat array with all predicates, posticates, and siblings.
 */
export const getRelatedApps = (item: HasLineage): LineageApplication[] => [
    ...getPredApps(item),
    ...getPostApps(item),
    ...getSibApps(item)
];

/**
 * Returns a flat array with the ids of all predicates, posticates, and siblings.
 */
export const getRelatedIds = (item: HasLineage): string[] =>
    uniq(numbers(getRelatedApps(item)));

/**
 * Access the vertexDegree property.
 */
const getVertexDegree = (item: HasLineage<unknown>): number =>
    item.lineage?.vertexDegree || 1;

/**
 * Can figure out the number of distinct siblings based on the vertexDegree.
 */
export const getUniqueSiblingCount = (item: HasLineage<unknown>): number =>
    getVertexDegree(item) - (1 + getPredCount(item) + getPostCount(item));

/**
 * Map a number to a dummy application object.
 */
const toApplication = (raw: LineageApplication | number): LineageApplication =>
    typeof raw === 'number' ? {
        applicationType: '',
        applicationNumber: ''
    } : raw;

/**
 * Convert BasicLineage to be compatible with regular Lineage.
 */
export const fillLineage = (item: HasLineage<LineageApplication | number>): Required<GenericLineage<LineageApplication>> => {
    return {
        children: getPostApps(item).map(toApplication),
        parents: getPredApps(item).map(toApplication),
        siblings: mapValues(item.lineage?.siblings || {}, arr => arr.map(toApplication)),
        vertexDegree: getVertexDegree(item)
    }
}
