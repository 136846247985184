import type { Tags } from './tags';

interface IdItem {
    type: Tags;
    id: string;
}

/**
 * Basic logic for creating arrays to pass to `providesTags` and `invalidatedTags`.
 */
export const createTagArrays = (type: Tags) => {
    const idItem = (id: string): IdItem => ({ type, id });

    // Use dummy id 'LIST' to represent the list of items as an invalidatable item.
    const list = idItem('LIST');

    // Array containing individual items and the list.
    const idsAndList = (...ids: (string | undefined)[]): IdItem[] => ids
        .filter(id => id) // drop any undefined due to api failures
        .map(id => ({ type, id }))
        .concat(list)

    return {
        all: [type],
        list: [list],
        idsAndList,
    }
}

/**
 * Handling for objects which use an id property `_id`.
 */
export const createTagHelper = (type: Tags, keepUnusedDataFor = 60) => {
    const { list, idsAndList } = createTagArrays(type);

    return {
        /**
         * Array containing the list item only.
         */
        list,
        /**
         * Create an array containing the list item and all individual items.
         */
        idsAndList,
        /**
         * Adding invalidates the list of items.
         */
        add: {
            invalidatesTags: list
        },
        /**
         * Updating/Deleting invalidates the individual item and the list.
         * Expects to get the id from the arguments.
         */
        update: {
            invalidatesTags: (result: unknown, error: unknown, arg: { _id: string }): IdItem[] => idsAndList(arg._id)
        },
        /**
         * Delete expects the arg to be just the string id instead of an object.
         */
        delete: {
            invalidatesTags: (result: unknown, error: unknown, arg: string): IdItem[] => idsAndList(arg)
        },
        /**
         * List response provides the list and all individual items.
         *
         * Function which creates an object with `providesTags`.
         * Requires argument: a function to access an array of items with an `_id` property.
         * TODO: ask Greg to change `datasets` to `items`?
         */
        getList: <T extends object>(getItems: (data: T) => { _id?: string }[]) => ({
            providesTags: (data: T | undefined): IdItem[] => data ? idsAndList(
                ...(getItems(data) || [])?.map(item => item._id)
            ) : [],
            keepUnusedDataFor
        }),
        /**
         * Detail provides one single item.
         *
         * Function which creates an object with `providesTags`.
         * Requires argument: a function to map from the response to the id.
         */
        getDetail: <T extends object>(getId: (data: T) => string | undefined) => ({
            // Note: data might be undefined if API fails, id might be undefined if missing in response.
            // TODO: how to properly handle missing id
            providesTags: (data: T | undefined): IdItem[] => {
                if (!data) return [];
                const id = getId(data);
                return id ? [{ type, id }] : []
            },
            keepUnusedDataFor
        })
    }
}
