import type { ExecutiveRegulatoryDatasetSummary } from '@features/saved-items';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ExecutiveRegulatoryDateRangeEnum, ExecutiveRegulatoryInspectionDateRangeEnum } from '../../API';

export interface TempDataset {
    _id: string;
    // Note: not currently used.
    name?: string;
    // Note: sections opened via email links are exempt from automatic closing effects.
    initialSection?: string;
}

export type LayoutType = 'grid' | 'table';

export type TransitionType = 'load-in' | 'all-to-tab' | 'tab-to-all' | 'tab-to-tab' | 'none';

export type ShortDateRange = ExecutiveRegulatoryDateRangeEnum;
export type LongDateRange = ExecutiveRegulatoryInspectionDateRangeEnum;

export interface InsiderDashboardState {
    dataset: TempDataset | null;
    dateRange: ShortDateRange;
    longDateRange: LongDateRange;
    chips: {
        expanded: boolean;
        editMode: boolean;
    }
    expandedSection?: string;
    pageEndReached: Record<string, number>;
    userViewed: Record<string, number>;
    layout: LayoutType;
    transitionType: TransitionType;
}

const initialState: InsiderDashboardState = {
    dateRange: '3M',
    longDateRange: '1Y',
    dataset: null,
    chips: {
        expanded: false,
        editMode: false
    },
    layout: 'grid',
    transitionType: 'load-in',
    pageEndReached: {},
    userViewed: {}
}

const dashboardSlice = createSlice({
    name: 'insiderDashboard',
    initialState,
    reducers: {
        // TODO: fix types in API -- _id is optional.
        setDataset: (state, action: PayloadAction<({ _id: string } | ExecutiveRegulatoryDatasetSummary) & { expandedSection?: string }>) => {
            const { expandedSection, _id, ...dataset } = action.payload;
            state.dataset = {
                ...dataset,
                _id,
                initialSection: expandedSection
            };
            state.chips = initialState.chips;
            state.pageEndReached = {};
            if (expandedSection) {
                state.expandedSection = expandedSection;
                state.transitionType = 'none';
            }
        },
        setDateRange: (state, action: PayloadAction<ShortDateRange>) => {
            state.dateRange = action.payload ?? initialState.dateRange;
            state.pageEndReached = {};
        },
        setLongDateRange: (state, action: PayloadAction<LongDateRange>) => {
            state.longDateRange = action.payload ?? initialState.longDateRange;
            state.pageEndReached = {};
        },
        toggleEditMode: (state) => {
            state.chips.editMode = !state.chips.editMode;
        },
        toggleExpandChips: (state) => {
            state.chips.expanded = !state.chips.expanded;
            state.chips.editMode = false;
        },
        collapseChips: (state) => {
            state.chips.expanded = false;
            state.chips.editMode = false;
        },
        sectionCardClick: (state, action: PayloadAction<string>) => {
            if (state.expandedSection === action.payload) return;
            state.transitionType = 'none';
            state.expandedSection = action.payload;
        },
        expandSection: (state, action: PayloadAction<string>) => {
            if (state.expandedSection === action.payload) return;
            state.transitionType = state.expandedSection ? 'tab-to-tab' : 'all-to-tab';
            state.expandedSection = action.payload;
        },
        toggleExpandSection: (state, action: PayloadAction<string>) => {
            const isAlreadyExpanded = state.expandedSection === action.payload;
            if (isAlreadyExpanded) {
                state.transitionType = 'tab-to-all';
                delete state.expandedSection;
            } else {
                state.transitionType = state.expandedSection ? 'tab-to-tab' : 'all-to-tab';
                state.expandedSection = action.payload;
            }
        },
        collapseSection: (state, action: PayloadAction<{ keepInitial?: boolean; transition: boolean }>) => {
            if (!state.expandedSection) return;
            const { keepInitial = false, transition } = action.payload;
            if (keepInitial && state.expandedSection === state.dataset?.initialSection) return;
            state.transitionType = transition ? 'tab-to-all' : 'none';
            delete state.expandedSection;
        },
        sectionEndReached: (state, action: PayloadAction<string>) => {
            if (state.pageEndReached[action.payload]) {
                state.pageEndReached[action.payload]++;
            } else {
                state.pageEndReached[action.payload] = 1;
            }
        },
        sectionPageViewed: (state, action: PayloadAction<{ section: string; pageNumber: number }>) => {
            const { section, pageNumber } = action.payload;
            state.pageEndReached[section] = pageNumber;
        },
        setLayoutType: (state, action: PayloadAction<LayoutType>) => {
            state.layout = action.payload;
        },
        handleUnmount: (state) => {
            delete state.expandedSection;
            state.dataset = null;
        }
    }
})

export default dashboardSlice.reducer;

export const {
    setDataset,
    setDateRange,
    setLongDateRange,
    toggleEditMode,
    toggleExpandChips,
    collapseChips,
    sectionCardClick,
    expandSection,
    collapseSection,
    toggleExpandSection,
    sectionEndReached,
    sectionPageViewed,
    setLayoutType,
    handleUnmount
} = dashboardSlice.actions;
