import type { Ecosystem, Lineage, LineageApplication } from '@features/device-data';
import { getEcosystem } from '@features/device-data';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { getRelatedApps, HasLineage } from './lineageUtil';

/**
 * Caches the known application details
 */

export type EcoData = Omit<Ecosystem, 'lineage'>;

export interface EcoItem {
    original: LineageApplication;
    data?: EcoData;
    lineage?: Lineage;
    isInvalid?: boolean;
}

interface LineageState {
    byId: {
        [itemId: string]: EcoItem;
    }
    invalidIds: string[];
}

const initialState: LineageState = {
    byId: {},
    invalidIds: []
};

// extract just the needed properties
export const pickApp = ({ applicationType, applicationNumber }: LineageApplication) =>
    ({ applicationType, applicationNumber });

// Prefer fetched appNum over original, in case of reclassification.
export const ecoAppNum = (item: EcoItem) =>
    item.data?.applicationNumber ?? item.original.applicationNumber;

// Helper functions which mutate draft state

// Make sure that a key exists before assigning to it.
const ensureExists = (state: Draft<LineageState>, key: string, original: LineageApplication) => {
    if (!(key in state.byId)) {
        state.byId[key] = {
            original: pickApp(original)
        };
    }
}

// Add basic info for all relatives of an item.
const addRelatives = (state: Draft<LineageState>, item: HasLineage) => {
    getRelatedApps(item)
        .forEach(relative => ensureExists(state, relative.applicationNumber, relative));
}

// Save the details of a loaded item.
const saveEcoToKey = (state: Draft<LineageState>, item: Ecosystem, key: string, originalId: string) => {
    const { lineage, ...data } = item;
    // Make sure it has an original with the correct id.
    const original = { applicationNumber: originalId, applicationType: data.applicationType };
    ensureExists(state, key, original);
    // Set the lineage and data.
    state.byId[key].lineage = lineage;
    state.byId[key].data = data;
}

// Save the details and relatives of a loaded item.
// Make life easy. If reclassified, just save to both ids.
const applyEco = (state: Draft<LineageState>, item: Ecosystem, originalId: string = '') => {
    const { lineage, ...data } = item;
    // Save to main id
    const id = data.applicationNumber;
    saveEcoToKey(state, item, id, originalId);
    // Save to alternate id
    if (originalId && originalId !== id) {
        saveEcoToKey(state, item, originalId, originalId);
    }
    // Create new entries for the nested lineages.
    // Note: relatives only need to be handled once.
    addRelatives(state, item);
}

const lineageSlice = createSlice({
    name: 'lineage',
    initialState,
    reducers: {
        storeRootLineage: (state, action: PayloadAction<Ecosystem>) => {
            applyEco(state, action.payload, action.payload.applicationNumber);
        },
        clearLineages: () => initialState
    },
    extraReducers: builder => builder.addMatcher(
        getEcosystem.matchFulfilled,
        (state, action) => {
            const data = action.payload.items;
            const requestedIds = action.meta.arg.originalArgs.applicationNumbers;
            // Save responses to state.
            // Check if every id that was requested is a key of the response.
            requestedIds.forEach(id => {
                if (data[id]) {
                    applyEco(state, data[id], id);
                } else {
                    state.invalidIds.push(id);
                }
            })
        }
    )
})

export const { clearLineages, storeRootLineage } = lineageSlice.actions;

export default lineageSlice.reducer;
