import type { CombinedDrugBrandResponse } from './drugApi.gen';

export type LabelSearchCountry = 'us' | 'fda' | 'eu' | 'combined';

export type SourceKey = keyof CombinedDrugBrandResponse;

export type RegionString = 'US' | 'UK' | 'EU';

const mapSourceToRegion: Record<SourceKey, RegionString> = {
    euEma: 'EU',
    ukEmc: 'UK',
    fdaSpl: 'US',
    drugsAtFda: 'US'
}

export const sourceToRegion = (source: SourceKey): RegionString => {
    return mapSourceToRegion[source];
}

/**
 * Can the history endpoint be called.
 */
export const supportsHistory = (source: SourceKey) =>
    source === 'fdaSpl' || source === 'drugsAtFda';

/**
 * Does the root package have a version identifier.
 */
export const supportsVersion = (source: SourceKey) =>
    source === 'fdaSpl' || source === 'drugsAtFda' || source === 'euEma';
