import { DateFacet } from '@api';
import { subDays, subMonths, subYears } from 'date-fns';
import { upperFirst } from 'lodash';
import { joinOptional, withPluralLabel } from '../textUtils';
import { validateDateRange } from './dateUtils';
import { DateRange, TimeInterval, TimeUnit } from './types';

const fnMap = {
    day: subDays,
    month: subMonths,
    year: subYears
}

export const ALL_INTERVAL: TimeInterval = {
    timeUnit: 'year',
    count: -1
}

export const KEY_OTHER_INTERVAL = 'other';

const TIME_UNITS: TimeUnit[] = ['day', 'month', 'year'];

/**
 * Calculate the start date for an interval.
 * Can provide a custom `end` date, or end with today.
 * Will return `null` for an "All" interval.
 */
export const getIntervalStart = ({ timeUnit, count }: TimeInterval, end: number | Date = Date.now()): null | Date =>
    ( count < 0 ) ? null : fnMap[timeUnit](end, count);

/**
 * Standard text: "90 Days", "1 Year"
 */
export const getIntervalLabel = ({timeUnit, count}: TimeInterval, allText = 'Show All'): string =>
    count < 0 ? allText : withPluralLabel(count, upperFirst(timeUnit));

/**
 * Abbreviated text: "90", "1Y"
 */
export const getIntervalShortLabel = ({timeUnit, count}: TimeInterval, allText = 'All'): string =>
    count < 0 ? allText : timeUnit === 'day' ? count.toString() : `${count}${timeUnit.slice(0, 1).toUpperCase()}`;

/**
 * Parse the abbreviated text back into an interval.
 * Note: will return 'day' on unexpected input rather than throwing an error.
 */
export const parseIntervalShortLabel = (label: string): TimeInterval => {
    if ( label.toLowerCase() === 'all' ) return ALL_INTERVAL;
    const count = parseInt(label, 10);
    const lastChar = label.slice(-1).toLowerCase(); // Note: will be a number if days.
    const timeUnit = TIME_UNITS.find(unit => unit.startsWith(lastChar)) ?? 'day';
    return { timeUnit, count };
}

/**
 * Combine the units and count from an interval object into a unique string key.
 */
export const intervalToKey = (interval: TimeInterval | null): string =>
    interval ? `${interval.timeUnit}_${interval.count}` : KEY_OTHER_INTERVAL;

/**
 * Parse the key back into an object.
 */
export const keyToInterval = (key: string): TimeInterval | null => {
    if (key === KEY_OTHER_INTERVAL) return null;
    try {
        const [timeUnit, countStr] = key.split('_');
        const count = parseInt(countStr, 10);
        if (isNaN(count)) return null;
        return { timeUnit, count } as TimeInterval;
    } catch (e) {
        return null;
    }
}

/**
 * Adds start and end date values to an interval.
 */
export const intervalToRange = (interval: TimeInterval, minMax?: DateFacet): DateRange => {
    const start = getIntervalStart(interval);
    const range = validateDateRange(minMax)(start, Date.now());
    return { interval, ...range };
}

/**
 * Helper for applying AE start and end dates from meta.
 */
export const overrideDateRange = (start: string | null, end: string | null, interval: TimeInterval): DateRange => {
    if (start && end) {
        return { start, end, interval }
    }
    const range = intervalToRange(interval);
    return {
        interval,
        start: start || range.start,
        end: end || range.end
    }
}

/**
 * Return a string like "1 Year" if on an interval, nothing if all time,
 * or a concatenated number like "20210328-20220328" on a custom range.
 */
export const fileDate = (date: DateRange | undefined) => {
    if (!date) return '';
    if (date.interval) {
        return getIntervalLabel(date.interval, '');
    }
    return joinOptional('-',
        date.start?.replaceAll('-', ''),
        date.end?.replaceAll('-', ''),
    );
}
