import { makeArray } from '@helpers/arrayUtils';
import { useCallback, useEffect } from 'react';
import { useSendBasilEvent } from './useSendBasilEvent';

export const BasilView = ({ children, event, changeKey = '' }: { children: JSX.Element, event: string | string[], changeKey?: string }) => {

    const send = useSendBasilEvent();

    useEffect(() => {
        send(...makeArray(event));
        // Note: don't want it to depend on an events array.
    }, [send, changeKey]);

    return children;
}

export const useAnalyticsClickHandler = (event: string) => {

    const send = useSendBasilEvent();

    // TODO: can it be memoized properly with multiple events?
    return useCallback(() => {
        send(event);
    }, [send, event]);
}


/**
 * Will send the view one time, or whenever the changeKey changes.
 */
export const useSendView = (event: string, changeKey: string = '') => {
    const send = useSendBasilEvent();

    useEffect(() => {
        send(event);
    }, [event, send, changeKey]);
}
