import FingerprintJS from '@fingerprintjs/fingerprintjs';

/**
 * Simple wrapper around fingerprintJS library.
 * Note: Promise may reject.
 */
export const getFingerprint = async (): Promise<string> => {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();
    return visitorId;
}
