import React from 'react';

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

/**
 * Need a type guard that asserts that the callback is this specific function type.
 */
export const isCallback = <T>(value: React.SetStateAction<T>): value is ((prevState: T) => T) =>
    typeof value === 'function';

/**
 * Create a setState callback for a nested property.
 */
export const setStateProperty = <S extends object, K extends keyof S>(
    setState: SetState<S>, property: K
): SetState<S[K]> =>
    (update) => {
        setState(prevState => ({
            ...prevState,
            [property]: isCallback(update) ? update(prevState[property]) : update
        }))
    }

/**
 * Create a callback that reverses a boolean state.
 */
export const toggle = (setState: SetState<boolean>) =>
    (): void =>
        setState(prevState => !prevState);
