import { CombinedDrugField } from '@api/enums';
import createCountryFieldUtil from './countryFieldUtil';
import { NAME_OVERRIDES } from './fieldsSPL';

/**
 * Controls the order of options in the add a section list.
 * Note: is the same order as SPL, but with some fields dropped.
 */
const FIELD_CHOICES = [
    CombinedDrugField.label,
    CombinedDrugField.indicationsAndUsage,
    CombinedDrugField.mechanismOfAction,
    CombinedDrugField.descriptionSection,
    // CombinedDrugField.representedOrganization,
    // CombinedDrugField.activeIngredient,
    // CombinedDrugField.inactiveIngredient,
    // CombinedDrugField.brandName,
    CombinedDrugField.dosageAndAdministration,
    CombinedDrugField.warningsAndPrecautions,
    CombinedDrugField.adverseReactions,
    CombinedDrugField.contraindications,
    CombinedDrugField.drugInteractions,
    CombinedDrugField.useInSpecificPopulations,
    CombinedDrugField.clinicalPharmacology,
];

export const combined = createCountryFieldUtil<CombinedDrugField>(
    CombinedDrugField,
    FIELD_CHOICES,
    NAME_OVERRIDES
);
