import { ArrowBackIos as ArrowBackIosIcon, ErrorOutline } from '@mui/icons-material'
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const Fallback: Sentry.FallbackRender = ({ eventId }) => {

    const navigate = useNavigate();

    return (
        <Box width={1} height={1} flex={1} display="flex" justifyContent="center" alignItems="center">
            <Stack spacing={5} alignItems="center">
                <Box display="flex" alignItems="center">
                    <ErrorOutline color="error" sx={{ fontSize: 30 }}/>
                    <Typography variant="h1" fontSize={24} ml={1.5}>We're sorry — something's gone wrong.</Typography>
                </Box>
                <Button
                    onClick={() => navigate(-1)}
                    startIcon={<ArrowBackIosIcon/>}
                    variant="text"
                    size="large"
                    sx={{ color: '#000000' }}
                >
                    RETURN TO YOUR LAST PAGE
                </Button>
                <Divider sx={{ width: 1 }}/>
                <Typography fontSize={18}>Our team has been notified, but we are glad to hear your report!</Typography>
                <Button
                    onClick={() => Sentry.showReportDialog({ eventId })}
                    variant="text"
                    size="medium"
                    sx={{ color: '#000000' }}
                >
                    Send us a more detailed bug report
                </Button>
            </Stack>
        </Box>
    );
}

const ErrorBoundary = ({ children }: { children: ReactNode }) => (
    <Sentry.ErrorBoundary
        // Note: fallback is called as a function, not a component, and cannot use hooks directly.
        fallback={(args) => <Fallback {...args}/>}
    >
        {children}
    </Sentry.ErrorBoundary>
)

export default ErrorBoundary;
