import { ApiError, ApiResponse, Mutation } from '@api';
import { extractMessage } from '@api/errors';
import { QueryArgFrom, ResultTypeFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { AppDispatch, useDispatch } from '@store';
import { useCallback } from 'react';
import { createAlert } from './reducer';
import { FeedbackProps } from './types';

export interface FeedbackPropFns<Args = unknown, Result = unknown> {
    /**
     * Create the props for the success alert based on the original arguments and the response value.
     * Must include a message.
     * Defaults to severity "success".
     *
     * If not present, no success alert will be shown.
     */
    successProps?: (args: Args, response: Result) => Partial<FeedbackProps> & { message: string };
    /**
     * Create the props for the error alert based on the original arguments and the API error.
     * Defaults to severity "error".
     * The error message is automatically extracted and included as the `message` prop.
     * Can override the `message`, or can provide a custom `fallbackMessage`.
     * Can also pass a `title` to be shown above the error message.
     *
     * If not present, no error alert will be shown.
     */
    errorProps?: (args: Args, error: ApiError) => Partial<FeedbackProps> & { fallbackMessage?: string };
    /**
     * Create the props for the pending alert based on the original arguments only.
     * Must include a message.
     * Defaults to severity "info" with pending icon.
     *
     * If not present, no pending alert will be shown.
     */
    pendingProps?: (args: Args) => Partial<FeedbackProps> & { message: string };
}

export interface Callbacks<Args, Result> extends FeedbackPropFns<Args, Result> {
    /**
     * Can call any arbitrary function upon completion of the API call.
     */
    onSuccess?: (args: Args, response: Result) => void;
    onError?: (args: Args, error: ApiError) => void;
    onPending?: (args: Args) => void;
}

export type CallbacksForHook<H extends UseMutation<any>> =
    H extends UseMutation<infer D> ? Callbacks<QueryArgFrom<D>, ResultTypeFrom<D>> : never;

/**
 * When wrapping a `useMutation` hook, the `Args` type can be derived from the hook type.
 */
interface HookWrapperProps<D extends Mutation> extends Callbacks<QueryArgFrom<D>, ResultTypeFrom<D>> {
    /**
     * The RTK query hook to wrap.
     * Note: could change to accept the endpoint instead.
     */
    useHook: UseMutation<D>;
}

type Execute<Args, Result> = (arg: Args) => Promise<ApiResponse<Result>>;

/**
 * Not sure of the best way to handle this functionality in components.
 * Don't want to have to memoize successProps and errorProps, if it can be avoided.
 * Does not need to be a hook if `dispatch` and `execute` are provided as props.
 */
interface PureFuncProps<Args, Result> extends Callbacks<Args, Result> {
    dispatch: AppDispatch;
    execute: Execute<Args, Result>;
}

export const withFeedback = <Args, Result>(
    { dispatch, execute, successProps, errorProps, pendingProps, onSuccess, onError, onPending }: PureFuncProps<Args, Result>
) =>
    /**
     * Returns the original Promise from the query.
     * This is always resolved, but can be unwrapped.
     */
    async (args: Args): Promise<ApiResponse<Result>> => {
        // Immediately show pending alert.
        if (pendingProps) {
            dispatch(createAlert({
                severity: 'info',
                icon: 'pending',
                ...pendingProps(args),
            }));
        }
        // Call pending callback.
        onPending?.(args);

        // Wait for API call to resolve.
        const result = await execute(args);
        if ('data' in result) {
            // Display a success alert with the provided message.
            if (successProps) {
                dispatch(createAlert({
                    severity: 'success',
                    ...successProps(args, result.data),
                }));
            }
            // Call success callback.
            onSuccess?.(args, result.data);
        } else if ('error' in result) {
            // Display an error alert.
            if (errorProps) {
                const { fallbackMessage, ...alertProps } = errorProps(args, result.error);
                dispatch(createAlert({
                    // Message from the API response.
                    message: extractMessage(result.error, fallbackMessage),
                    severity: 'error',
                    ...alertProps
                }));
            }
            // Call error callback.
            onError?.(args, result.error);
        }
        return result;
    }

/**
 * Instead of wrapping the whole hook, can just wrap an execute fn.
 * This allows for mapping args before calling.
 */
export const useExecuteWithFeedback = <Args, Result>(
    { execute, successProps, errorProps, pendingProps, onSuccess, onError, onPending }: Omit<PureFuncProps<Args, Result>, 'dispatch'>
): Execute<Args, Result> => {
    const dispatch = useDispatch();

    return useCallback(
        withFeedback({
            dispatch,
            execute,
            successProps,
            errorProps,
            pendingProps,
            onSuccess,
            onError,
            onPending
        }),
        [dispatch, execute, successProps, errorProps, pendingProps, onSuccess, onError, onPending]
    );
}

/**
 * Wrap the execution of any RTK query hook so that successes and errors
 * from the API will cause alerts to appear on screen.
 */
export const useMutationWithFeedback = <D extends Mutation>(
    { useHook, ...rest }: HookWrapperProps<D>
) => {
    const [execute, state] = useHook();

    // Returns the wrapped execute function.
    const wrapped = useExecuteWithFeedback({
        execute,
        ...rest
    });

    return [wrapped, state] as const;
}

/**
 * Hook factory function makes it easier to define successProps and errorProps
 * outside of the component in order to avoid memoization issues
 * when the component state and props don't matter.
 */
export const wrapMutationWithFeedback = <D extends Mutation>(
    props: HookWrapperProps<D>
) => (
    overrides: Partial<Omit<HookWrapperProps<D>, 'useHook'>> = {}
) =>
    useMutationWithFeedback({ ...props, ...overrides });
