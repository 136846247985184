import type { GroupedFacets } from '@content/FacetGroup/FacetGroup';

/**
 * Keep the group name the same based on the previous grouping.
 * Need to replace the `groups` and `groupMap`, other properties can stay the same
 * because the group index is not changed.
 *
 * Note: assumes that there will not be 2 new groups matching 1 old.
 */
export const correctGroupNames = (currentGroups: GroupedFacets, originalGroups: GroupedFacets): GroupedFacets => {

    const modifiedGroups = currentGroups.groups.map(group => {
        // Check if this group name was an original group name
        const previousIndex = originalGroups.groupMap[group.name];
        if (previousIndex !== undefined) {
            return group;
        }
        // Find the original name which contained the top facet.
        const facet = currentGroups.facets[group.facetsByTotalDescending[0]];
        const originalGroupId = originalGroups.facetMap[facet.key];
        // In case of unlikely error.
        if (originalGroupId === undefined) return group;
        const originalGroupName = originalGroups.groups[originalGroupId].name;
        return {
            ...group,
            name: originalGroupName,
        }
    });

    const groupMap = Object.fromEntries(modifiedGroups.map(
        (group, i) => ([group.name, i])
    ));

    return {
        ...currentGroups,
        groupMap,
        groups: modifiedGroups,
    }
}
