import { AETableResponse, DocConfig, MaudeTableResponse } from '@api';
import { isNotEmpty } from '@helpers/apiUtils';

export interface AETableItem {
    date: string;
    manufacturer: string;
    brandName: string;
    eventType: string;
    app?: [string, string]; // [appType, appId]
    reportKey: number;
}

/**
 * AE events are returned from the API in a format which uses numbers
 * to represent various properties, where the number corresponds to the
 * key of the actual value in a lookup list.
 */
export const createEventTableData = (data: AETableResponse | MaudeTableResponse): AETableItem[] => {
    // Note: assertion refines the `any` type.
    return (data as AETableResponse).events.map(event => {

        const [reportKey, dateIndex, brandIndex, typeIndex, patientIndex, appNums, manuIndex] = event;

        const item: AETableItem = {
            date: data.dates?.[dateIndex] || '',
            manufacturer: data.manufacturers?.[manuIndex] || '',
            brandName: data.brandNames?.[brandIndex] || '',
            eventType: data.eventTypes?.[typeIndex] || '',
            reportKey: reportKey
        }

        // Note: will be an empty array if not on a proCode page
        if ( appNums?.length ) {
            const [typeIndex, idIndex] = appNums;
            const appType = data.applicationTypes?.[typeIndex];
            const appId = data.applications?.[idIndex];
            if (isNotEmpty(appType) && isNotEmpty(appId) && appType !== "FIVETENK_EXEMPT" && appId !== "EXEMPT") {
                item.app = [DocConfig.convert(appType, 'urlSlug', 'docTypeSearch'), appId];
            }
        }

        return item;
    });
};
