import { POLLING_INTERVAL } from '@constants';
import { useInterval } from '@hooks/useInterval';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Check every 10 pages
const CHECK_AFTER_PAGES = 10;

/**
 * Load the head from a separate instance of the app.
 */
const getLatestVersion = async (): Promise<string | undefined> => {
    const response = await fetch("/", {
        method: "HEAD"
    });
    // replace null with undefined
    return response.headers.get("x-app-version") ?? undefined;
}

/**
 * The version which was used to build the current app is accessible
 * via a meta tag on the page.
 */
const getCurrentVersion = (): string | undefined => {
    return (document.querySelector('meta[name="generator"]') as HTMLMetaElement)?.dataset?.appVersion;
}

/**
 * Repeatedly checks if the current version is outdated
 * on an interval and during navigation events.
 *
 * IDEA: Once flagged as outdated, can hard reload on next navigation.
 */
export const useIsOutdated = (): boolean => {
    // Only need to run the document check one time on mount.
    const current = useMemo(getCurrentVersion, []);

    // Store isOutdated as a state which is returned from the hook.
    const [isOutdated, setIsOutdated] = useState(false);

    // Function to execute a check and return the new value.
    const checkIsOutdated = useCallback(
        async () => {
            // Stop checking once isOutdated = true.
            if (isOutdated) return true;
            try {
                const latest = await getLatestVersion();
                // Only true if both are valid but do not match each other.
                return !!current && !!latest && current !== latest;
            } catch (e) {
                // No message is shown if there is a problem accessing the versions.
                return false;
            }
        },
        [current, isOutdated]
    );

    // Read the latest on a polling interval.
    const handleInterval = useCallback(
        () => {
            // Save to local state to enable the snackbar.
            checkIsOutdated().then(setIsOutdated);
        },
        [checkIsOutdated, setIsOutdated]
    );
    useInterval(handleInterval, POLLING_INTERVAL);

    // Read the latest on every 10 changes of location, approximately.
    const {pathname, search} = useLocation();

    useEffect(() => {
        if (Math.random() < (1 / CHECK_AFTER_PAGES)) {
            // Initiate the reload automatically if during navigation.
            checkIsOutdated().then(isNowOutdated => {
                if (isNowOutdated) {
                    window.location.reload();
                }
            })
        }
    }, [pathname, search]);

    return isOutdated;
}
