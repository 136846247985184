import type { User } from '@features/iam';
import { createSerializedStorage } from '@helpers/localStorage';
import type { To } from 'react-router-dom';
import type { AuthPayload, AuthTokens } from './types';
import { pickTokens } from './utilities';

interface Stored {
    user: User;
    tokens: AuthTokens;
}

/**
 * tokenExpiration uses mysql-style date string.
 * Need to covert to Date object to compare.
 * Note: probably don't need to check that properties exist.
 */
const isValidToken = ({ bearerTokenRefresh, tokenRefreshExpiration }: AuthTokens): boolean => {
    return Boolean(
        bearerTokenRefresh &&
        tokenRefreshExpiration &&
        (new Date(tokenRefreshExpiration)).getTime() > Date.now()
    );
}

/**
 * Wrapper around saving the user and tokens to localStorage,
 * to ensure consistent usage.
 *
 * Internally catches all errors.
 * Returns undefined if no user is stored or if the refresh token is expired.
 * Will return the payload if the bearer token is expired,
 * as it can be refreshed any time that the API is called.
 */
const userStorage = createSerializedStorage<Stored>('userTokens', {
    validate: (stored) => isValidToken(stored.tokens)
});

export function saveUserToLocalStorage(payload: AuthPayload) {
    userStorage.set({
        tokens: pickTokens(payload),
        user: payload.user
    });
}

export function getUserFromLocalStorage(): Stored | undefined {
    return userStorage.get();
}

/**
 * Location that the user was logged out or redirected from,
 * and will return to once authenticated.
 *
 * This needs to be persisted in localStorage in order to work with SSO,
 * where the callback/redirect is a new session.
 */
export const storedLogoutPath = createSerializedStorage<To>('logoutPath');
