import { useCurrentUserId, useIsExpiredTrial } from '@features/auth';
import { useEffect } from 'react';
import GA4 from './GA4';

/**
 * Set up Google Analytics using the userId from redux state.
 */
export default function GAInitialize(): null {

    const userId = useCurrentUserId();

    const isExpiredTrial = useIsExpiredTrial();

    useEffect(() => {
        GA4.initialize('G-73S69TBWE8', {userId, isExpiredTrial});
    }, [userId, isExpiredTrial]);

    return null;
}
