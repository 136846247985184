import { alpha, outlinedInputClasses } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { HEADING_COLOR, LINK_BLUE, PRIMARY } from './colors';
import Font from './font/Font';
import LinkBehavior from './LinkBehavior';
import shadows from './shadows';

/**
 * Global settings for MUI component styles and props.
 */
export const options: ThemeOptions = {
    typography: {
        fontFamily: Font.NotoSans,
        fontWeightMedium: 400, // Was 500. Used by Button, Tooltip, ListSubheader, AlertTitle, etc.
        fontWeightBold: 500,
        fontSize: 14,
        // TODO: this is wrong and causes components using theme.typography.pxToRem() to be wrong,
        // But don't want to change without checking everything.
        htmlFontSize: 14,
        // TODO: use rem units?
        h1: {
            fontSize: 20
        },
        h2: {
            fontSize: 18
        },
        h3: {
            fontSize: 16
        },
        h4: {
            fontSize: 14
        },
        h5: {
            fontSize: 12
        },
        h6: {
            fontSize: 10
        },
        subtitle1: {},
        subtitle2: {},
        // body1 becomes the default for menu items, selects, etc.
        body1: {
            fontSize: 14,
            letterSpacing: '0.00938em'
        },
        body2: {
            fontSize: 13,
        },
        button: {
            fontSize: 13
            // TODO: need to change the font size for small buttons.
        },
        caption: {},
        overline: { // used by Heading in Datasheet
            textTransform: 'uppercase',
            fontFamily: Font.NunitoSans,
            fontSize: 11,
            color: HEADING_COLOR.main,
            fontWeight: 600,
            letterSpacing: 0.07854,
            lineHeight: 1.57
        }
    },
    palette: {
        primary: PRIMARY,
        secondary: {
            main: '#2F576B',
            contrastText: '#FFF'
        }
        // TODO: secondary color -- darker blue?
        // TODO: add additional custom colors: https://mui.com/customization/palette/#adding-new-colors
    },
    shadows,
    // TODO: can add defaultProps margin: 'dense', size: 'small', etc.
    components: {
        MuiAlert: {
            styleOverrides: {
                outlined: {
                    backgroundColor: '#FFF' // removes transparent
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application!
                disableTouchRipple: true,
                focusRipple: false,
                LinkComponent: LinkBehavior
            },
            styleOverrides: {
                root: {
                    // TODO: figure out the right combo to fix bootstrap alignment issues global.
                    // Seems like 'inherit' on buttons but 'normal' on chips.
                    lineHeight: 'inherit',
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    letterSpacing: 0.3, // prevents v4 interference
                    // textTransform: 'none'
                },
                containedPrimary: {
                    '&:hover': {
                        color: '#FFF' // prevents bootstrap interference
                    }
                }
            }
        },
        MuiToggleButton: {
            defaultProps: {
                disableRipple: true,
                disableFocusRipple: true
            },
            styleOverrides: {
                root: {
                    letterSpacing: 0.3, // prevents v4 interference
                    // textTransform: 'none'
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
                disableFocusRipple: true
            },
            styleOverrides: {
                // Have to copy a chunk of code from https://github.com/mui/material-ui/blob/master/packages/mui-material/src/IconButton/IconButton.js
                // due to https://github.com/mui/material-ui/issues/11108
                root: ({ theme, ownerState }) => ({
                    '&:hover': {
                        backgroundColor: alpha(
                            (ownerState.color !== 'inherit' && ownerState.color !== 'default')
                                ? theme.palette[ownerState.color].main
                                : theme.palette.action.active,
                            theme.palette.action.hoverOpacity
                        ),
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                            backgroundColor: 'transparent'
                        }
                    }
                })
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 24
                },
                fontSizeLarge: {
                    fontSize: 36,
                },
                fontSizeSmall: {
                    fontSize: 20
                }
            }
        },
        MuiFormControl: {
            // No automatic margin
            defaultProps: {
                margin: 'none'
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    // Removes default of left: 16, right: -11
                    marginLeft: 0,
                    marginRight: 0,
                },
                label: {}
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 12 // or maybe 11. Smaller size is more consistent with v4.
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
                // @ts-ignore -- stopped working after react-router v6 upgrade.
                component: LinkBehavior
            },
            styleOverrides: {
                root: {
                    color: LINK_BLUE.main
                },
                underlineNone: {
                    '&:hover': {
                        textDecoration: 'none'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    lineHeight: 'normal' // Fixes alignment issues caused by Bootstrap
                },
                labelSmall: {
                    fontSize: 13
                }
            }
        },
        MuiDivider: {
            // Make dividers light by default
            defaultProps: {
                light: true
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        // Darken the disabled color for radio buttons
                        '& svg': {
                            color: 'rgba(0, 0, 0, 0.38)'
                        }
                    },
                    '& svg:first-of-type': {
                        // TODO: make sure this is still correct for v5
                        transform: 'scale(1)' // MUI fix for Safari radio button
                    }
                },
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                // root applies to color = primary
                root: {
                    // Keep the color, but reduce the width of focus ring
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderWidth: 1
                    },
                },
                colorSecondary: {}
            }
        },
        // Note: Could make light blue rounded the default style
        MuiSelect: {
            styleOverrides: {
                // Note: height, line-height, letter-spacing slightly different
                select: {
                    fontSize: 14,
                    // Move background from ':focus' to ':focus-visible'
                    '&:focus': {
                        background: 'transparent'
                    },
                    '&:focus-visible': {
                        background: 'rgba(0, 0, 0, 0.05)'
                    }
                },
                // Lighter gray for arrow down icon
                icon: {
                    color: '#00000040',
                    fontSize: 24,
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
                dense: {
                    minHeight: 'unset',
                    paddingTop: 4,
                    paddingBottom: 4,
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    // Darken the disabled color
                    '&.Mui-disabled': {
                        opacity: 0.5
                    }
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    // Darken the disabled color
                    '&.Mui-disabled': {
                        opacity: 0.5
                    }
                }
            }
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold' // Note: default is 500
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                // Make tooltips pass-through mouse events by default
                popper: {
                    pointerEvents: 'none'
                }
            }
        },
        MuiUseMediaQuery: {
            defaultProps: {
                // Without this, all media queries are false on the first render.
                noSsr: true
            }
        }
    },
    isPdf: false
}
export const theme = createTheme(options);
