import { Facet } from '../Facet/types';

const levenshtein = require('js-levenshtein');

const NA = 'N/A';

export interface FacetGroup {
    name: string;
    total: number;
    facetsByAlpha?: number[];
    facetsByTotalDescending: number[];
}

export interface BasicGroupedFacets {
    groups: FacetGroup[];

    // sorted group indexes
    alphaOrder: number[];
    countOrder: number[];

    facets: { count?: number; key: string }[];
}

export interface GroupedFacets extends BasicGroupedFacets {
    // mapping from facet name to group index
    facetMap: { [facet: string]: number };

    // lookup group index by name
    groupMap: { [name: string]: number };
}

interface ProcessedNames {
    [processed: string]: {
        facets: number[];
        parts: string[];
        total?: number;
    };
}

const nonWordRE = /W+/g;
const minCheckLen = 5; // minimum length of words to engage the spell-check
const minCheckLen2 = minCheckLen - 1; // used to allow a letter to be missing in the right-hand comparison word
const lenToLvnDist = [0, 0, 0, 0, 0, 2, 2, 2]; // maximum error score for string lenths 5-7

export class FacetGrouper {
    private groups: {
        [key: string]: {
            items: ProcessedNames;
            total?: number;
            commonName?: string;
            absorbedGroup?: string;
        }
    };

    private exceptions: any;

    private letterMap: {
        [char: string] : {[key: string]: boolean}
    }

    private facets: Facet[];

    constructor() {
        this.groups = {};
        this.facets = null;
        this.exceptions = {};
        this.letterMap = {};
    }

    // provide a list of pairs of terms that should remain distinct (as the first word in group names)
    // add them via this method before calling getGroups() to avoid grouping them together as misspellings.
    public addExceptions(exceptList: [string, string][]) {
        exceptList.forEach(e => {
            const except = this.exceptions;
            if (!except[e[0]]) {
                except[e[0]] = {};
            }
            if (!except[e[1]]) {
                except[e[1]] = {};
            }
            except[e[0]][e[1]] = except[e[1]][e[0]] = true;
        });
    }

    private add(facetIndex: number, key: string, processed: string, parts: string[]) {
        if (!key) {
            key = NA;
        }

        if (!this.groups[key]) {
            this.groups[key] = { items: {} };
        }

        if (this.groups[key].items[processed]) {
            this.groups[key].items[processed].facets.push(facetIndex);
        }
        else {
            this.groups[key].items[processed] = {
                facets: [facetIndex],
                parts
            }
        }

        const firstChar = key.substr(0,1);
        if (firstChar) {
            if (!this.letterMap[firstChar]) {
                this.letterMap[firstChar] = {};
            }
            this.letterMap[firstChar][key] = true;
        }
    }

    private setGroupTotals(group, facets) {
        const items = group.items;
        let gTotal = 0;
        for (let itm in items) {
            const item = items[itm];

            let iTotal = 0;
            for (let i = 0; i < item.facets.length; i++) {
                iTotal += facets[item.facets[i]].count;
            }
            item.total = iTotal;
            gTotal += iTotal;
        }
        group.total = gTotal;
    }

    private processGroups(facets: Facet[]) {
        const groups = this.groups;

        for (const g in groups) {
            const facetCountsByWord = {};

            for (const itm in groups[g].items) {
                const item = groups[g].items[itm];

                // count occurances of each item's second word
                const parts = item.parts;
                const word = parts.length >= 2 ?  parts[1].replace(nonWordRE, '') : '';
                let numEvents = 0;
                for (let i = 0; i < item.facets.length; i++) {
                    numEvents += facets[item.facets[i]].count;
                }
                facetCountsByWord[word] = (facetCountsByWord[word] || 0) + numEvents;
            }

            let maxWord = null;
            let maxCount = 0;
            for (const w in facetCountsByWord) {
                const count = facetCountsByWord[w];
                if (count > maxCount) {
                    maxCount = count;
                    maxWord = w;
                }
            }

            groups[g].commonName = maxWord || null;
            this.setGroupTotals(groups[g], facets);
        }
    }

    // Find related group names among names with a minimum length.
    // Combine groups if the levenshtein distance between the pair <= 3.
    private combineSimilarlySpelledGroups(facets) {

        const {letterMap} = this;
        const absorbedGroups = {};

        for (const p in letterMap) {
            const keys = Object.keys(letterMap[p]);
            const len = keys.length;
            if (len <= 1) continue;

            const relatedNames = new Array(len * (len-1)/2);

            let n = 0;
            for (let i = 0; i < len; i++) {
                const key1 = keys[i].replace(nonWordRE, '');
                const klen1 = key1.length;
                if (klen1 < minCheckLen) continue; 

                for (let j = i + 1; j < len; j++) {
                    const key2 = keys[j].replace(nonWordRE, '');
                    const klen2 = key2.length;
                    if (klen2 < minCheckLen2) continue;

                    const lvn = levenshtein(key1, key2);
                    const distTest = lenToLvnDist[klen1] || 3;
                    if (lvn > distTest) continue;

                    if (this.exceptions[key1] && this.exceptions[key1][key2]) continue;

                    relatedNames[n++] = [keys[i], keys[j]];
                }
            }

            if (n == 0) continue;
            relatedNames.length = n;

            let obj = {};
            let objArr = [];
            for (const r of relatedNames) {
                if (!obj[r[0]]) {
                    if (Object.keys(obj).length) {    
                        objArr.push(obj);
                        obj = {};
                    }
                }

                obj[r[0]] = true;
                obj[r[1]] = true;
            }
            if (Object.keys(obj).length) {    
                objArr.push(obj);
            }

            // among similar spelled groups, find the group with most events,
            // and add the others into it.

            for (let i = 0; i < objArr.length; i++) {
                let totalMax = 0;
                let topGroup = null;
                for (let g in objArr[i]) {
                    while (this.groups[g].absorbedGroup) {
                        g = this.groups[g].absorbedGroup;
                    }
                    const total = this.groups[g].total;
                    if (total > totalMax) {
                        topGroup = g;
                        totalMax = total;
                    }
                }

                while (this.groups[topGroup].absorbedGroup) {
                    topGroup = this.groups[topGroup].absorbedGroup;
                }

                // combine items from the groups with lesser events into the group with the most.
                const topGroupItems = this.groups[topGroup].items;
                for (let g in objArr[i]) {
                    while (this.groups[g].absorbedGroup) {
                        g = this.groups[g].absorbedGroup;
                    }
                    if (g == topGroup) continue;

                    const curGroup = this.groups[g];
                    for (const i in curGroup.items) {
                        topGroupItems[i] = curGroup.items[i];
                    }

                    absorbedGroups[g] = true;
                    this.groups[g].absorbedGroup = topGroup;
                    this.setGroupTotals(this.groups[topGroup], facets);
                }
            }
        }
        for (const ag in absorbedGroups) {
            delete this.groups[ag];
        }
    }

    setFacets(facets: Facet[]) {
        this.facets = facets;

        for (let i = 0; i < facets.length; i++) {
            const facet = facets[i];
            const name = facet.key;

            if (!name) continue;  // possiblly will handle null facet keys at a later time.

            // replace nonword chars with space
            let processed = name.replace(/([^\w\s'-]+ | - |[/.,])/g,' ');

            // condense 2-or-3 single letters into acronyms
            processed = processed.replace(/((^|\s+)(\w{1})\s+)((\w{1})(\s+|$))((\w{1})(\s+|$))?/g, '$2$3$5$8 ');

            // condense adjacent spaces
            processed = processed.replace(/\s+/g, ' ').trim();

            const parts = processed.split(/\s+/);

            if (parts.length) {
                const key = parts[0]; // add this facet to a group, keyed to the first word
                this.add(i, key, processed, parts);
            }
        }

        this.processGroups(facets);
        this.combineSimilarlySpelledGroups(facets);
    }

    getGroups(): GroupedFacets {    
        if (!this.groups) return null;

        const gf = {
            groups: [],
            facets: null
        };
        
        const names = Object.keys(this.groups);
        const facetMap = {};
        const groupMap = {};

        for (let i = 0; i < names.length; i++) {
            let name = names[i];
            const group = this.groups[name];
            if (group.commonName) {
                name += ' ' + group.commonName;
            }

            const groupFacets = [];
            for (let itm in group.items) {
                const item = group.items[itm];
                for (let j = 0; j < item.facets.length; j++) {
                    groupFacets.push(item.facets[j]);
                    facetMap[this.facets[item.facets[j]].key] = i;
                }
            }

            const facetsByAlpha = [...groupFacets].sort((a, b) => {
                return this.facets[a].key < this.facets[b].key ? -1 : 1;
            });

            const facetsByTotalDescending = groupFacets.sort((a, b) => {
                return this.facets[a].count > this.facets[b].count ? -1 : 1;
            });

            gf.groups.push({
                name,
                total: group.total,
                facetsByAlpha,
                facetsByTotalDescending
            });

            groupMap[name] = i;
        }

        const groupList = names.map((g,i) => ({
            name: g,
            index: i,
            total: this.groups[g].total
        }));

        const alphaOrder = [...groupList].sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        }).map(e => e.index);

        const countOrder = groupList.sort((a, b) => {
            return a.total > b.total ? -1 : 1;
        }).map(e => e.index);

        return {
            ...gf,
            facets: this.facets,
            alphaOrder,
            countOrder,
            facetMap,
            groupMap
        };
    }
}
