import type { ReactNode } from 'react';
import type { HttpValidationError } from './api.generated';
import type { ApiError, BaseQueryError } from './rtkTypes';

export interface ErrorResponse {
    errors: string[];
    status: string;
}

export type MixedError = ApiError | string;

export interface ErrorProps {
    title?: ReactNode;
    error?: MixedError;
}

const isErrorResponse = (data: unknown): data is ErrorResponse =>
    typeof data === 'object' && 'errors' in data;

// Note: some apis use an array `errors` and others use a string `detail`.
export const hasDetail = (data: unknown): data is { detail: string } | HttpValidationError =>
    typeof data === 'object' && 'detail' in data;

export const isFetchError = (e: object): e is BaseQueryError => 'status' in e;

// Check if an error is an api error with a particular status code, or one of multiple codes.
export const isErrorCode = (e: ApiError | undefined, ...codes: (number | string)[]): boolean => {
    return Boolean(e && isFetchError(e) && codes.some(code => e.status === code));
}

// Check if an error is in the 4xx or 5xx range.
export const isErrorRange = (e: ApiError | undefined, range: 300 | 400 | 500): boolean => {
    const status = (e as BaseQueryError)?.status;
    if (status && typeof status === 'number') {
        return status >= range && status < range + 100;
    } else {
        return false;
    }
}

const hasMessage = (e: object): e is { message: string } => 'message' in e;

/**
 * Attempt to find a message in an error object of unknown type.
 * Probably a SerializedError or FetchBaseQueryError.
 * If the API includes errors in the response, want to use those.
 */
const maybeExtractMessage = (e: unknown): string | undefined => {
    if (!e) return;

    if (typeof e === 'string') {
        return e;
    }

    if (typeof e !== 'object') return;

    if (hasMessage(e)) {
        return e.message;
    }

    if (isFetchError(e)) {
        if (isErrorResponse(e.data)) {
            return e.data.errors.join('\n');
        }
        if (hasDetail(e.data)) {
            if (typeof e.data.detail === 'string') {
                return e.data.detail;
            } else if (Array.isArray(e.data.detail)) {
                return e.data.detail.map(err => err.msg).join('\n');
                // err => `Validation error ${err.type} in ${err.loc?.join('.')}: ${err.msg}.`
            } else {
                return 'API Validation Error';
            }
        }
        if ('error' in e) {
            return e.error;
        }
    }
}

/**
 * Can ensure that there will always be an error string by providing a fallback.
 */
export const extractMessage = (
    e: unknown,
    fallback = 'An unknown error occurred.'
): string => {
    // Ensure that extracting the error doesn't cause more errors.
    try {
        const maybe = maybeExtractMessage(e);
        // Make sure it was a string before returning to avoid JSX errors.
        if (maybe && typeof maybe === 'string') return maybe;
        return fallback;
    } catch (err) {
        return fallback;
    }
}
