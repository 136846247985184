/**
 * Hook for a boolean `isOpen` state returns memoized `open` and `close` functions.
 */
import { useCallback, useState } from 'react';

export const useIsOpen = (initialOpen: boolean = false) => {
    const [isOpen, setIsOpen] = useState(initialOpen);

    const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleToggle = useCallback(() => setIsOpen(prev => !prev), [setIsOpen]);

    return {
        isOpen,
        setIsOpen,
        handleOpen,
        handleClose,
        handleToggle
    }
}

export type IsOpenHandlers = ReturnType<typeof useIsOpen>
