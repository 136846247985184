import { WithDefaults } from '@helpers/types';
import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import React, { CSSProperties, ForwardedRef, forwardRef, ReactNode, RefAttributes } from 'react';
import './Loading.scss';

export type LoadingShape =
    'circle' // clockwise rotation of tiny dots in a donut shape
    | 'pulse' // pulsating circle
    | 'three-dots' // horizontal row of 3 dots
    | 'clover' // four overlapping circles in a clover / clover shape
    | 'grid' // 3x3 grid of squares animating diagonally

interface LoadingSpinnerProps {
    shape?: LoadingShape;
}

/**
 * Just the shape, no container div.
 * Default shape is 'grid'.
 */
export const LoadingSpinner = ({ shape }: LoadingSpinnerProps) => {
    switch (shape) {
        case "circle":
            return (
                <div className="sk-fading-circle">
                    <div className="sk-circle1 sk-circle"/>
                    <div className="sk-circle2 sk-circle"/>
                    <div className="sk-circle3 sk-circle"/>
                    <div className="sk-circle4 sk-circle"/>
                    <div className="sk-circle5 sk-circle"/>
                    <div className="sk-circle6 sk-circle"/>
                    <div className="sk-circle7 sk-circle"/>
                    <div className="sk-circle8 sk-circle"/>
                    <div className="sk-circle9 sk-circle"/>
                    <div className="sk-circle10 sk-circle"/>
                    <div className="sk-circle11 sk-circle"/>
                    <div className="sk-circle12 sk-circle"/>
                </div>
            );
        case "pulse":
            return (
                <div className="sk-bounce">
                    <div className="sk-bounce-dot"/>
                    <div className="sk-bounce-dot"/>
                </div>
            );
        case "three-dots":
            return (
                <div className="spinner">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div>
            );
        case "clover":
            return (
                <div className="clover"/>
            );
        case "grid":
        default:
            return (
                <div className="sk-grid">
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                    <div className="sk-grid-cube"/>
                </div>
            );
    }
}

export type LoadingContainerProps = WithDefaults<BoxProps<'div'>, {
    height?: string | number;
    padding?: string | number;
    className?: string;
    style?: CSSProperties;
    overlay?: boolean;
    fill?: boolean;
    children?: ReactNode;
}> & RefAttributes<HTMLDivElement>;

/**
 * Creates a div to be used around the spinner.
 */
export const LoadingContainer = forwardRef(
    ({ height, padding, style, className, children, fill, overlay, ...props }: LoadingContainerProps,
     ref: ForwardedRef<HTMLDivElement>
    ) => (
    <Box
        {...props}
        ref={ref}
        className={clsx("loading-container", fill && "fill", overlay && "overlay", className)}
        style={{ ...style, height, padding }}
    >
        {children}
    </Box>
));

export type LoadingProps = LoadingSpinnerProps & LoadingContainerProps;
/**
 * Wraps the shape in a 'loading-container' div.
 * Allows child content inside the container and before the spinner.
 * Note: Can control the order of content with flexDirection.
 */
const Loading = forwardRef(
    ({ shape, children, ...props }: LoadingProps,
     ref: ForwardedRef<HTMLDivElement>
     ) => (
    <LoadingContainer ref={ref} {...props}>
        {children}
        <LoadingSpinner shape={shape}/>
    </LoadingContainer>
));

/**
 * Loading grid in a fixed-height container.
 */
export const LoadingPage = ({ height = '100%' }: { height?: string | number }) => {
    return (
        <div style={{ height }}>
            <div style={{ height: '600px' }}>
                <Loading height={'100%'} shape="grid"/>
            </div>
        </div>
    );
}

export default Loading;
