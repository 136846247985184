import type { AESearchResponse, EventsData, TotalResults } from '@api';
import type { Facet } from '@content/Facet/types';
import { FacetGrouper, GroupedFacets } from '@content/FacetGroup/FacetGroup';

export interface AEResults {
    data: EventsData;
    manuGroups: GroupedFacets;
    totalResults: TotalResults;
}

const manuGroupExceptions: [string, string][] = [
    ['BOSTON', 'BECTON']
];

const createManuGroups = (data: AESearchResponse): GroupedFacets => {
    const grouper = new FacetGrouper();
    grouper.addExceptions(manuGroupExceptions);

    const manuFacets = (data?.facets?.manufacturer || []);

    // Note: can either assert that count is always present,
    // or can fill in with 0 if it isn't
    grouper.setFacets(manuFacets as Facet[]);

    return grouper.getGroups();
};

export const mapResults = (data: AESearchResponse): AEResults => ({
    data,
    manuGroups: createManuGroups(data),
    totalResults: data.totalResults
})
