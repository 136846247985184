import { POLLING_INTERVAL } from '@constants';
import { expireTrial, useTrialEndTime } from '@features/auth';
import { useInterval } from '@hooks/useInterval';
import { useDispatch } from '@store';
import React, { useEffect, useState } from 'react';

/**
 * Renderless component checks if the trial is expired on a polling interval
 * and notifies Redux to update permissions.
 */
const Checker = ({endTime}: {endTime: number}): null => {

    const [ticks, setTicks] = useState(0);

    const dispatch = useDispatch();

    // Use an effect triggered by ticks in order to avoid stale closure of Date.now.
    useEffect(() => {
        if ( endTime <= Date.now() ) {
            dispatch(expireTrial());
        }
    }, [ticks]);

    useInterval(() => setTicks(t => t + 1), POLLING_INTERVAL);

    return null;
}

export default function TrialExpiredChecker() {
    const endTime = useTrialEndTime();

    // Don't need to mount the checker unless there is an active trial.
    return endTime ? <Checker endTime={endTime}/> : null;
}
