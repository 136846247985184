import { combined } from './fieldsCombined';
import { ema } from './fieldsEMA';
import { fda } from './fieldsFDA';
import { spl } from './fieldsSPL';

export const fieldUtil = {
    us: spl,
    fda: fda,
    eu: ema,
    combined: combined
}
