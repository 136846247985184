import React, { PropsWithChildren, useMemo, useState } from 'react';
import { ExportConfig, ExportContext, IExportContext } from './ExportContext';

interface ProviderProps {
    initialConfig?: ExportConfig;
}

/**
 * Allows components to set an export config and invoke a handler.
 */
export default function ExportConfigProvider({ initialConfig, children }: PropsWithChildren<ProviderProps>) {
    // Store the export configuration settings, which can be updated.
    const [exportConfig, setExportConfig] = useState<ExportConfig | undefined>(initialConfig);

    // Callbacks provided via context
    const contextValue: IExportContext = useMemo(() => ({
            setExportConfig,
            callHandler: (format) => exportConfig?.handler?.(format),
            // Can export if there is a config which is not disabled.
            canExport: exportConfig?.disabled === false,
            buttonProps: exportConfig?.buttonProps ?? {}
        }),
        [setExportConfig, exportConfig]
    );

    return (
        <ExportContext.Provider value={contextValue}>
            {children}
        </ExportContext.Provider>
    )
}
