import { initializeAuthState } from '@features/auth/state/reducer';
import type { User } from '@features/iam';
import { RootState } from '@store';
import { PreloadedState } from 'redux';

/**
 * Temporary fix forcing users to logout and re login in order to get the `linkedProducts` on the user object.
 */

const isOutdatedUser = (user: User): boolean => {
    // Safeguard to prevent infinite loops: if there is a `prevAuthDatetime` then it must be new.
    if (user.prevAuthDatetime) return false;
    // Note: look for undefined rather than an empty array.
    return user.assignedRoles.every(role => role.linkedProducts === undefined);
}

export default function initializeState(): Partial<PreloadedState<RootState>> {
    const auth = initializeAuthState();
    if (auth.user && isOutdatedUser(auth.user.original)) {
        return {
            auth: {
                loggedIn: false
            },
            alert: {
                open: true,
                props: {
                    severity: 'info',
                    title: 'Update Available',
                    message: 'For this software upgrade, please log in.'
                }
            }
        }
    }
    return { auth };
}
