import type { BaseQuery } from '@api';
import { extractMessage, hasDetail } from '@api/errors';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { QueryThunk, RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import type { EndpointDefinition, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import type { AppMiddleware } from '@store';
import { isObject, mapValues } from 'lodash';
import type { AnyAction } from 'redux';

/**
 * Check if an action is an API error.
 * Note: RTK rejects duplicate queries, but not withValue.
 */
export const isRejectedQuery = (action: AnyAction): action is RejectedAction<QueryThunk, EndpointDefinition<any, BaseQuery, any, any>> =>
    action.type.startsWith('api/') && isRejectedWithValue(action)

/**
 * Determine which codes should be reported to Sentry.
 * Note: might make more sense to look at ranges.
 */
const NOT_REPORTED_CODES = ['INVALID_ARGUMENTS', 401, 402, 403, 404] // TODO: can build out over time.
const isIgnoredCode = (status: any) => NOT_REPORTED_CODES.includes(status);

/**
 * Sentry displays objects, but not deeply nested objects.
 */
const prepareObject = (object: Record<string, unknown>): Record<string, unknown> =>
    mapValues(object,
        property => !isObject(property) ? property : mapValues(property,
            nested => !isObject(nested) ? nested : JSON.stringify(nested))
    )

/**
 * Send all API errors to Sentry.
 */
const errorMiddleware: AppMiddleware = () => (next) => (action) => {
    if (isRejectedQuery(action)) {
        if (!isIgnoredCode(action.payload?.status)) {
            const error = new Error(extractMessage(action.payload));
            Sentry.withScope(function (scope) {
                scope.setTag('source', 'API');
                // Pull out the most important info.
                const args = action.meta?.arg?.originalArgs;
                scope.setContext('request', prepareObject({
                    endpointName: action.meta?.arg?.endpointName,
                    url: (action.meta?.baseQueryMeta as FetchBaseQueryMeta)?.request?.url,
                    args
                }));
                // Pass the entire action object.
                scope.setContext('action', prepareObject(action));
                // Try to get details from 422 errors.
                const data = action.payload?.data;
                if (hasDetail(data) && Array.isArray(data.detail)) {
                    // Sentry expects an object, so key the messages by the location.
                    const errors = Object.fromEntries(data.detail.map(err => {
                        return [err.loc.join('.'), err.msg];
                    }))
                    scope.setContext('validation-errors', errors);
                }
                // Send.
                Sentry.captureException(error);
            });
        }
    }
    return next(action);
}

export default errorMiddleware;
