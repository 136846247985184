import { setCountry } from '@features/faceted-search/selectionStateReducer';
import { createSlice, Draft, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import type { MatrixGroupingField } from '../rows/types';
import type { Column, MatrixState, MatrixView } from './types';

const INITIAL_COLUMN_COUNT = 5;

const emptyColumn = (): Column => ({
    heading: '',
    brandSearch: '',
    companySearch: '',
    key: nanoid(4)
});

const columnsToState = (columns: Column[]): MatrixState['columns'] => ({
    byKey: keyBy(columns, 'key'),
    order: columns.map(col => col.key)
});

// Enforce a standard number of columns.
export const padColumns = (columns: Column[]): Column[] => {
    let copy = [...columns];
    while (copy.length < INITIAL_COLUMN_COUNT) {
        copy.push(emptyColumn());
    }
    return copy;
}

const initialView: MatrixView = {
    fields: ['deviceProblem'],
    type: 'adverseEvent'
}

const createInitialState = (initialColumns: Column[] = []): MatrixState => ({
    columns: columnsToState(padColumns(initialColumns)),
    rollupParentTerms: {},
    currentView: initialView
});

const fieldMustExist = (state: Draft<MatrixState>, field: MatrixGroupingField): void => {
    if (!state.rollupParentTerms[field]) {
        state.rollupParentTerms[field] = {};
    }
}

const addColumnAtIndex = (state: Draft<MatrixState>, index?: number): void => {
    const column = emptyColumn();
    state.columns.byKey[column.key] = column;
    const i = index ?? state.columns.order.length;
    state.columns.order.splice(i, 0, column.key);
}

const multiSearchSlice = createSlice({
    name: 'multiSearch',
    initialState: createInitialState,
    reducers: {
        beginEdit: (state, action: PayloadAction<string>) => {
            state.editing = action.payload;
        },
        endEdit: (state, action: PayloadAction<Column>) => {
            state.columns.byKey[action.payload.key] = action.payload;
            delete state.editing;
        },
        addColumn: (state, action: PayloadAction<number | undefined>) => {
            addColumnAtIndex(state, action.payload);
        },
        removeColumn: (state, action: PayloadAction<number>) => {
            const [key] = state.columns.order.splice(action.payload, 1);
            if (!key) return;
            delete state.columns.byKey[key];
            if (state.editing === key) {
                delete state.editing;
            }
            // Never let the dictionary be empty.
            if (state.columns.order.length === 0) {
                addColumnAtIndex(state);
            }
        },
        reorderColumns: (state, action: PayloadAction<string[]>) => {
            state.columns.order = action.payload;
        },
        // Reset everything else when replacing columns.
        loadColumns: (state, action: PayloadAction<Column[]>) => {
            return createInitialState(action.payload);
        },
        toggleTermGroup: (state, action: PayloadAction<{ parentTerm: string; field: MatrixGroupingField }>) => {
            const { parentTerm: term, field } = action.payload;
            fieldMustExist(state, field);
            if (state.rollupParentTerms[field][term]) {
                delete state.rollupParentTerms[field][term];
            } else {
                state.rollupParentTerms[field][term] = true;
            }
        },
        groupAllTerms: (state, action: PayloadAction<{ parentTerms: string[]; field: MatrixGroupingField }>) => {
            const { parentTerms, field } = action.payload;
            fieldMustExist(state, field);
            parentTerms.forEach(term => {
                state.rollupParentTerms[field][term] = true;
            });
        },
        ungroupAllTerms: (state, action: PayloadAction<MatrixGroupingField>) => {
            state.rollupParentTerms[action.payload] = {};
        },
        setCurrentView: (state, action: PayloadAction<MatrixView>) => {
            state.currentView = action.payload;
        },
        resetState: () => createInitialState()
    },
    extraReducers: builder => builder
        // Adapt current view as a side effect when changing country.
        .addCase(setCountry, (state, action) => {
            // TODO: this logic is very specific to the current matrix.  Can it be generalized?  Handled elsewhere?
            if (state.currentView.type === 'adverseEvent') {
                // Note: assumes that this action is always a change, not setting the same country.
                if (action.payload === 'us') {
                    state.currentView = initialView;
                } else {
                    delete state.currentView.fields;
                }
            }
        })
})

export const {
    beginEdit,
    endEdit,
    addColumn,
    removeColumn,
    reorderColumns,
    loadColumns,
    toggleTermGroup,
    groupAllTerms,
    ungroupAllTerms,
    setCurrentView,
    resetState
} = multiSearchSlice.actions;

export default multiSearchSlice.reducer;
