import { sentryWarn } from '@features/errors';
import { useCallback } from 'react';
// TODO: move this file to @features somewhere?
import { useCurrentModule } from '../../Module/CurrentModuleContext';
import { useSaveAnalyticsMutation } from './api';

/**
 * Automatically accesses the module from the CurrentModuleContext.
 */
export const useSendBasilEvent = () => {
    const module = useCurrentModule();

    const [save] = useSaveAnalyticsMutation();

    return useCallback((...events: string[]) => {
        // Warn if no module, but send anyway.
        save({ module: module ?? 'COMMON', events, version: 'v2' });
        if (!module) {
            sentryWarn(
                `No module context found when sending analytics events: ${events.join(' ')}`
            );
        }
    }, [module, save]);
}
