import { IS_FREEMIUM } from '@constants';
import * as Yup from 'yup';

export const emailValidator = Yup.string()
    .required('The email was empty, try again.')
    .email('Not a valid email address.');

export const loginValidator = Yup.object({
    emailAddress: emailValidator,
    password: Yup.string()
        .required('The password was empty, try again.'),
    device: Yup.string().optional()
});

export const resetValidator = Yup.object({
    emailAddress: emailValidator
});

const freemiumFields = {
    title: Yup.string().optional(),
    company: Yup.string().required('Company is required.'),
    companyType: Yup.string().required('Company Type is required.'),
    jobRoles: Yup.array().of(Yup.string()).optional(),
    recaptcha: Yup.string().nullable().required('Please fill the reCAPTCHA checkbox.')
}

export const signupValidator = Yup.object({
    emailAddress: emailValidator,
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    ...(IS_FREEMIUM ? freemiumFields : {})
});

export const contactValidator = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    emailAddress: Yup.string().required().email(),
    title: Yup.string().optional(),
    company: Yup.string().required()
});
