/**
 * Create a get/set pair with JSON parsing for a given key.
 */

interface Options<T> {
    /**
     * Use sessionStorage instead of localStorage.
     */
    session?: boolean;
    /**
     * Make sure that .get() only returns valid values.
     * Can throw an error or return false, and .get() will return undefined.
     */
    validate?: (value: T) => boolean;
}

export const createSerializedStorage = <T>(key: string, { session, validate }: Options<T> = {}) => {
    const storage = session ? sessionStorage : localStorage;
    return {
        set: (value: T): void => {
            storage.setItem(key, JSON.stringify(value));
        },
        get: (): T | undefined => {
            try {
                const str = storage.getItem(key);
                if (!str) return;
                const value = JSON.parse(str) as T;
                if (validate && !validate(value)) return;
                return value;
            } catch (e) {
                return;
            }
        },
        remove: (): void => {
            storage.removeItem(key);
        }
    }
}
