import { sentryWarn } from '@features/errors';

/**
 * Options shown in menus
 * Note: must have a string value to work with MUI
 */
export enum FoundIn {
    interventions = 'interventions',
    title = 'title',
    all = 'all-sections' // Needs to be different from faers cases all
}

/*
Possible values for section
https://github.com/basil-sys/monaco-search-service/blob/0c9105bf70e0b96f1dfe90e82655f660b31a87ac/src/models/facet.py#L9
    "RESULTS": "RESULTS",
    "STUDYDESIGN": "STUDY DESIGN",
    "ELIGIBILITY": "ELIGIBILITY",
    "OUTCOMESMEASURES": "OUTCOMES MEASURES",
    "ARMSINTERVENTIONS": "ARMS INTERVENTIONS",
    "BASIL.OVERVIEW.INTERVENTIONS": "INTERVENTIONS",
    "BASIL.OVERVIEW.CONDITIONS": "CONDITIONS",
    "BASIL.OVERVIEW.STUDYDESCRIPTION": "STUDY DESCRIPTION",
    "BASIL.OVERVIEW.STUDYSUMMARY": "STUDY SUMMARY",
    "BASIL.OVERVIEW.OFFICIALTITLE": "OFFICIAL TITLE",
 */
/**
 * Map from the select value to the API filters.
 */
export const foundInToFilters = (foundIn: FoundIn | string): string[] => {
    switch (foundIn) {
        case FoundIn.interventions:
            return ['INTERVENTIONS', 'ARMS INTERVENTIONS'];
        case FoundIn.title:
            return ['OFFICIAL TITLE'];
        case FoundIn.all:
        default:
            return [];
    }
}

/**
 * Inverse map from the API filters to the select value.
 */
export const filtersToFoundIn = (values: string[]): FoundIn => {
    if (values.length === 0) return FoundIn.all;
    if (values[0] === 'OFFICIAL TITLE') return FoundIn.title;
    if (values.some(v => v === 'INTERVENTIONS')) return FoundIn.interventions;
    sentryWarn('Unable to map section filter to value', { data: values });
    return FoundIn.all;
}
