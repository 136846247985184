import { API_BASE_URL, IS_DEV_MODE, IS_FREEMIUM } from '@constants';
import type { AuthPayload, AuthState } from '@features/auth';
import { tokenReceived, userLoggedOut } from '@features/auth';
import { sentryWarn } from '@features/errors';
import type { RefreshTokenApiResponse } from '@features/iam';
import { getFingerprint } from '@helpers/fingerprint';
import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { CORE_MODULE_TYPES } from '../Module/moduleConfig';
import { isErrorCode } from './errors';
import type { BaseQuery, BaseQueryReturn } from './rtkTypes';

/**
 * All endpoints will include an Authorization header after the user has logged in
 * and received a JWT, which is stored in Redux.
 */
const baseQuery: BaseQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        headers.set('Content-Type', 'application/json');
        // access token stored in redux
        const userToken = (getState() as { auth: AuthState }).auth.user?.tokens.bearerToken;
        if (userToken) {
            headers.set('Authorization', `Bearer ${userToken}`);
        }
        return headers;
    }
});

/**
 * Modify API arguments such that the URL uses the freemium /basic/ version
 * for all services except for /iam/, ignoring /common/ endpoints.
 */
const replaceUrl = (args: string | FetchArgs): string | FetchArgs => {
    if (!IS_FREEMIUM) return args;
    const modify = (url: string) =>
        (url.includes('/iam') || url.includes('/common')) ? url : url
            // convert service/v1/endpoint => service/v1/basic/endpoint
            .replace(/\/v1(\/basic)?/, '/v1/basic')
            // convert service/v2/module/endpoint => service/v2/basic/endpoint
            .replace(/\/v2\/(.+?)\//, '/v2/basic/')
    return typeof args === 'string' ? modify(args) : {
        ...args,
        url: modify(args.url)
    }
}

/**
 * See which API calls will need to be updated.
 */
const devEndpointCheck = (args: string | FetchArgs): void => {
    const url = typeof args === 'string' ? args : args.url;
    if (['/common/', '/iam/', '/stats', '/preferences'].some(str => url.includes(str))) return;
    const config = Object.values(CORE_MODULE_TYPES).find(o =>
        window.location.pathname.startsWith(o.rootUrl)
    );
    if (!config) {
        // Note: admin, etc.
        sentryWarn('outside of module');
        return;
    }
    const re = new RegExp(`/v\\d(/basic)?${config.apiRoot}`);
    if (!re.test(url)) {
        sentryWarn(`endpoint url ${url} does not match the current module ${config.name}`, { data: args });
    }
}

/**
 * Send the current tokens to the /refresh-token endpoint.
 */
const getTokenRefresh = async (api: BaseQueryApi, extraOptions: {}): Promise<BaseQueryReturn<RefreshTokenApiResponse>> => {
    const authState = (api.getState() as { auth: AuthState }).auth;
    if (authState.user) {
        const { bearerTokenRefresh, bearerToken } = authState.user.tokens;
        const device = await getFingerprint();
        // Try to get a new bearer token.
        // Note: will reject if the token is not yet expired.
        return baseQuery({
            url: '/iam/v1/users/auth/refresh-token',
            method: 'POST',
            body: {
                device,
                bearerToken,
                bearerTokenRefresh
            }
        }, api, extraOptions);
    } else {
        // Don't send to API if there are no tokens.
        return {
            error: {
                status: 'INVALID_ARGUMENTS',
                error: 'Not Authenticated'
            }
        };
    }
}

/**
 * Wrap the query to try one additional time if rejected due to expired token.
 * And automatically logout if not able to get a new token.
 * Based on docs example: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
 */
const mutex = new Mutex();
const baseQueryWithReauth: BaseQuery = async (initialArgs, api, extraOptions) => {
    if (IS_DEV_MODE) {
        devEndpointCheck(initialArgs);
    }
    // Apply URL replacement, if needed.
    const args = IS_FREEMIUM ? replaceUrl(initialArgs) : initialArgs;
    // Don't try this query if already waiting for a refresh token.
    await mutex.waitForUnlock();
    // Try the first time.
    let result = await baseQuery(args, api, extraOptions);
    // If unauthorized...
    if (isErrorCode(result.error, 401)) {
        if (!mutex.isLocked()) {
            // Lock the mutex while fetching the new token.
            const release = await mutex.acquire();
            try {
                const refreshResult = await getTokenRefresh(api, extraOptions);
                if (refreshResult.data) {
                    // Store the new token.
                    api.dispatch(tokenReceived(refreshResult.data as AuthPayload));
                    // Retry the initial query.
                    result = await baseQuery(args, api, extraOptions);
                }
                // Logout unauthorized users.
                else if ((api.getState() as { auth: AuthState }).auth.loggedIn) {
                    const { pathname, search } = window.location;
                    api.dispatch(userLoggedOut({ from: { pathname, search } }));
                    // TODO: session ended alert
                    // dispatch(createAlert()) - or handle in middleware
                }
            } finally {
                // Must release the mutex when done, even on error.
                release();
            }
        } else {
            // Wait until the mutex is available without locking it.
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
}

export default baseQueryWithReauth;
